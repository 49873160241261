<template>
  <div>
    <h1 class="mb-8 font-bold text-3xl">
      <InertiaLink :href="$routes.users()" class="text-indigo-400 hover:text-indigo-600">
        Пользователи
      </InertiaLink>
      <span class="text-indigo-400 font-medium">/</span> Create
    </h1>
    <div class="bg-white rounded shadow overflow-hidden max-w-3xl">
      <UserForm v-model="form" @submit="createUser">
        <div class="px-8 py-4 bg-gray-100 border-t border-gray-200 flex justify-end items-center">
          <LoadingButton :loading="form.processing" class="btn-indigo" type="submit">
            Create User
          </LoadingButton>
        </div>
      </UserForm>
    </div>
  </div>
</template>

<script>
import Layout from "@/Layouts/Full.vue";
import LoadingButton from "@/Shared/LoadingButton.vue";
import UserForm from "@/Shared/UserForm.vue";

export default {
  metaInfo: { title: "Create User" },
  layout: Layout,
  components: {
    LoadingButton,
    UserForm
  },
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  remember: "form",
  data() {
    return {
      form: this.$inertia.form({ user: this.user })
    };
  },
  methods: {
    createUser() {
      this.form.post(this.$routes.users());
    }
  }
};
</script>
