<template>
  <div class="max-w-3xl">
    <div class="mb-8 flex flex-col justify-start">
      <h2 class="font-bold text-2xl">
        Отметить документы полученными.
      </h2>
    </div>
    <StyledForm
      :class="{ error: !!Object.keys(formErrors).length }"
      :errors="formErrors.base"
      :loading="form.processing"
      @submit="submit"
    >
      <template v-slot:formBody>
        <p>
          Вы можете отметить документы полученными не дожидаясь пока из загрузит клиент.
          <br /><br />
          После этого загрузка их клиентом будет невозможна.
        </p>
        <TextareaInput class="mt-6" placeholder="Комментарий" v-model="form.request.comment" />
      </template>
    </StyledForm>
  </div>
</template>

<script type="text/javascript">
import TextareaInput from "@/Shared/TextareaInput.vue";
import StyledForm from "@/Shared/StyledForm.vue";

export default {
  components: { TextareaInput, StyledForm },
  props: ["ticketId"],
  emits: ["close"],
  data() {
    return {
      form: this.$inertia.form({
        request: {
          comment: ""
        }
      })
    };
  },
  computed: {
    formErrors() {
      return this.form?.errors || {};
    }
  },
  methods: {
    submit() {
      this.form.post(this.$routes.receive_documents_ticket(this.ticketId), {
        preserveState: true,
        preserveScroll: true,
        onSuccess: () => this.$emit("close")
      });
    }
  },
  mounted() {
    this.$el.scrollIntoView({ behavior: "smooth" });
  }
};
</script>
