<template>
  <Upload
    v-model:file="file"
    v-model:loading="loading"
    accept="image/jpeg,image/gif,image/png,image/heic,application/pdf"
    @upload="upload"
  />
</template>

<script>
import Upload from "@/Shared/Upload.vue";
import heic2any from "heic2any";

export default {
  components: { Upload },
  data() {
    return {
      file: null,
      loading: false
    };
  },
  methods: {
    async upload() {
      try {
        if (this.file.type.match("image/heic")) {
          try {
            this.file = await this.convertHeicToJpg(this.file);
          } catch (error) {
            this.file =
              error.name === "TYPE_ERROR"
                ? this.changeTypeImage(this.file, error.imageType)
                : this.file;
          }
        }
        const formData = new FormData();
        formData.append("file", this.file);
        await this.$inertia.post(
          this.$routes.ticket_attachments(this.$page.props.ticket.id),
          formData,
          {
            preserveScroll: true,
            onSuccess: this.onSuccess
          }
        );
      } catch (error) {
        this.loading = false;
        console.error(error);
      }
    },
    onSuccess() {
      console.log("Uploaded!");
      this.loading = false;
    },
    async convertHeicToJpg(file) {
      try {
        const convertedImageData = await heic2any({
          blob: file,
          toType: "image/jpeg",
          quality: 0.85
        });

        return new File([convertedImageData], "photo" + ".jpg", {
          type: "image/jpeg",
          lastModified: new Date().getTime()
        });
      } catch (error) {
        if (error.message?.indexOf("ERR_USER Image is already browser readable") + 1) {
          error.name = "TYPE_ERROR";
          error.imageType = error.message?.split(": ")?.[1];
        } else {
          error.message = "При конвертации изображения произошла ошибка.";
        }
        throw error;
      }
    },
    changeTypeImage(file, type, dateModified = new Date().getTime(), ext) {
      if (!file || !type) return;
      const imageExt = ext || type?.split("/")?.[1] || null;

      return new File([file], "photo" + imageExt ? "." + imageExt : "", {
        type: type,
        lastModified: dateModified
      });
    }
  }
};
</script>
