<template>
  <div class="max-w-3xl">
    <div class="mb-8 flex flex-col justify-start">
      <h2 class="font-bold text-2xl">
        Запрос на подготовку устройства к обслуживанию.
      </h2>
    </div>

    <StyledForm
      :class="{ error: !!Object.keys(formErrors).length }"
      :errors="formErrors.base"
      :loading="form.processing"
      @submit="submit"
    >
      <template v-slot:formBody>
        <CheckboxInput
          v-model="form.request.confirmed"
          :errors="formErrors.confirmed"
          @input="() => form.clearErrors('confirmed')"
        >
          Обращение проверил, причин для отказа нет.
        </CheckboxInput>
      </template>
    </StyledForm>
  </div>
</template>

<script type="text/javascript">
import CheckboxInput from "@/Shared/CheckboxInput.vue";
import StyledForm from "@/Shared/StyledForm.vue";

export default {
  components: { CheckboxInput, StyledForm },
  props: ["ticketId"],
  emits: ["close"],
  data() {
    return {
      form: this.$inertia.form({
        request: {
          confirmed: false,
        }
      }),
    };
  },
  computed: {
    formErrors() {
      return this.form?.errors || { };
    },
  },
  methods: {
    submit() {
      this.form.post(this.$routes.request_device_reset_ticket(this.ticketId), {
        preserveState: true,
        preserveScroll: true,
        onSuccess: () => this.$emit("close"),
      });
    }
  },
  mounted() {
    this.$el.scrollIntoView({ behavior: "smooth" });
  },
};
</script>
