<template>
  <div>
    <h1 class="mb-8 font-bold text-3xl">
      Отмены контрактов
    </h1>
    <div class="mb-6 flex justify-between items-center">
      <SearchFilter v-model="form.search" class="w-full max-w-md mr-4" @reset="reset">
        <SelectInput label="Статус" v-model="form.state">
          <option :value="undefined">Любой</option>
          <option value="assigned">Привязан</option>
          <option value="unassigned">Не привязан</option>
        </SelectInput>

        <SelectInput class="mt-4" label="Бренд" v-model="form.brand">
          <option :value="undefined">Любой</option>
          <option v-for="brand in brands" :key="brand.id" :value="brand.id">{{
            brand.name
          }}</option>
        </SelectInput>
      </SearchFilter>
    </div>

    <div class="flex flex-col">
      <div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
        <div
          class="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200"
        >
          <table class="min-w-full">
            <TableHeader
              :columns="[
                {
                  field: 'id',
                  label: '#',
                  sortable: true
                },
                {
                  field: 'revoked_at',
                  label: 'Дата',
                  sortable: true
                },
                {
                  field: 'number',
                  label: 'Карта'
                },
                {
                  field: 'price',
                  label: 'Стоимость',
                  sortable: true
                },
                {
                  field: 'device_id',
                  label: 'SN or IMEI'
                },
                {
                  field: 'document',
                  label: 'Документ',
                  sortable: true
                }
              ]"
            />
            <tbody class="bg-white">
              <tr
                v-for="(card, idx) in revocations.data"
                :key="card.id"
                class="hover:bg-gray-50 focus-within:bg-gray-50 border-gray-200"
                :class="{ 'border-b': idx != revocations.data.length - 1 }"
              >
                <td
                  class="flex items-center px-6 py-4 whitespace-nowrap text-sm leading-5 text-gray-500 text-right"
                >
                  <InertiaLink
                    class="font-medium text-indigo-600 hover:text-indigo-900 focus:outline-none focus:underline"
                    :href="$routes.revocation(card.id)"
                    aria-label="Show"
                  >
                    {{ card.id }}
                  </InertiaLink>
                  <InertiaLink
                    v-if="card.contract_id"
                    class="font-medium text-indigo-600 hover:text-indigo-900 focus:outline-none focus:underline pl-2"
                    :href="$routes.contract(card.contract_id)"
                    aria-label="Show"
                  >
                    <svg
                      width="16"
                      height="16"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path
                        d="M9.26 13a2 2 0 0 1 .01-2.01A3 3 0 0 0 9 5H5a3 3 0 0 0 0 6h.08a6.06 6.06 0 0 0 0 2H5A5 5 0 0 1 5 3h4a5 5 0 0 1 .26 10zm1.48-6a2 2 0 0 1-.01 2.01A3 3 0 0 0 11 15h4a3 3 0 0 0 0-6h-.08a6.06 6.06 0 0 0 0-2H15a5 5 0 0 1 0 10h-4a5 5 0 0 1-.26-10z"
                      ></path>
                    </svg>
                  </InertiaLink>
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                  {{ new Date(card.revoked_at).toLocaleString("ru") }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm leading-5 text-gray-700">
                  {{ card.number }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm leading-5 text-gray-500 text-right">
                  {{ (card.price || 0).toLocaleString("ru") }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                  {{ card.device_sn || card.device_imei }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                  {{ card.document }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <pagination :meta="revocations.meta" />
  </div>
</template>

<script>
import Icon from "@/Shared/Icon.vue";
import Layout from "@/Layouts/Full.vue";
import mapValues from "lodash/mapValues";
import Pagination from "@/Shared/NewPagination.vue";
import pickBy from "lodash/pickBy";
import SearchFilter from "@/Shared/SearchFilter.vue";
import SelectInput from "@/Shared/SelectInput.vue";
import throttle from "lodash/throttle";
import TableHeader from "@/Shared/TableHeader.vue";

export default {
  metaInfo: { title: "Контракты" },
  layout: Layout,
  components: {
    Icon,
    Pagination,
    SearchFilter,
    SelectInput,
    TableHeader
  },
  props: {
    brands: {
      type: Array,
      required: true
    },
    revocations: {
      type: Object,
      required: true
    },
    filters: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      form: {
        search: this.filters.search,
        state: this.filters.state,
        brand: this.filters.brand,
        sort_by: this.filters.sort_by
      }
    };
  },
  watch: {
    form: {
      handler: throttle(function() {
        let query = pickBy(this.form);
        this.$inertia.replace(
          this.$routes.revocations(Object.keys(query).length ? query : { remember: "forget" }),
          {
            preserveState: true,
            preserveScroll: true,
            only: ["revocations"]
          }
        );
      }, 150),
      deep: true
    }
  },
  methods: {
    reset() {
      this.form = mapValues(this.form, () => null);
    }
  }
};
</script>
