<template>
  <div class="copy-to-clipboard inline-flex items-center">
    <slot />
    <i class="w-4 text-indigo-500 hover:text-indigo-700 cursor-pointer" @click="copyOwnerPhone">
      <svg
        class="w-4 h-4"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          v-if="copied"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"
        />
        <path
          v-else
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2"
        />
      </svg>
    </i>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      copied: false
    };
  },
  methods: {
    async copyOwnerPhone() {
      try {
        await navigator.clipboard.writeText(this.value);
        this.copied = true;
        setTimeout(() => (this.copied = false), 800);
      } catch (error) {
        console.error(error);
        alert("Oops, unable to copy");
      }
    }
  }
};
</script>

<style scoped>
svg {
  margin: 0 4px;
  display: none;
}
.copy-to-clipboard:hover svg {
  display: block;
}
</style>
