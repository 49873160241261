// This file is automatically compiled by Vite, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import "~/styles/application.css";

import { createApp, h } from "vue";
import { createInertiaApp, Head, Link } from "@inertiajs/vue3";
import TrendChart from "vue-trend-chart";
// import { InertiaProgress } from "@inertiajs/progress";
// InertiaProgress.init();

import * as Routes from "@/utils/routes.js";

const pages = import.meta.globEagerDefault("../Pages/**/*.vue");

createInertiaApp({
  resolve: (name) => {
    const component = pages[`../Pages/${name}.vue`];
    if (!component)
      throw new Error(`Unknown page ${name}. Is it located under Pages with a .vue extension?`);

    return component;
  },

  title: (title) => (title ? `${title} - AdjustCRM` : "AdjustCRM"),

  setup({ el, App, props, plugin }) {
    const vueApp = createApp({
      render: () => h(App, props),
    });
    vueApp.config.globalProperties.$routes = Routes;
    vueApp
      .use(plugin)
      .use(TrendChart)
      .component("InertiaHead", Head)
      .component("InertiaLink", Link)
      .mount(el);
  },
});
