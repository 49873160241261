<template>
  <div>
    <div class="mb-8 flex justify-start max-w-3xl">
      <h1 class="font-bold text-3xl">
        <InertiaLink class="text-indigo-500 hover:text-indigo-600" :href="$routes.tickets()">
          Обращения
        </InertiaLink>
        <span class="text-indigo-400 font-medium">/</span>
        {{ ticket.id }}
      </h1>
    </div>

    <div class="flex">
      <div style="width: 100%">
        <div class="mb-15 max-w-4xl bg-white shadow flex-grow overflow-hidden sm:rounded-lg">
          <div class="px-4 py-5 border-b border-gray-200 sm:px-6 flex justify-between">
            <div>
              <h3 class="text-2xl leading-6 font-medium text-gray-900 flex">
                # {{ ticket.hashid || ticket.id }}
                <InertiaLink :href="$routes.edit_ticket(ticket.id)">
                  <Icon class="ml-2 w-5 h-5 group-hover:fill-indigo-600 fill-gray-800 focus:fill-indigo-600"
                    name="edit" />
                </InertiaLink>
              </h3>

              <p class="mt-2 max-w-2xl leading-5 text-gray-500">
                <span class="font-medium capitalize text-orange-700">
                  {{ ticketForm }}
                </span>
                от {{ new Date(ticket.created_at).toLocaleString("ru") }}
              </p>
              <p v-if="ticket.contract" class="mt-1.5 max-w-2xl leading-5 text-gray-500">
                Договор:

                <InertiaLink v-if="ticket.contract.id" :href="$routes.contract(ticket.contract.id)"
                  class="text-indigo-600 mb-2 hover:underline">
                  {{ ticket.contract.program.toUpperCase() }} до
                  {{
                    new Date(ticket.contract.valid_before).toLocaleDateString("ru")
                  }}<span v-if="ticket.contract.extended_before">, расширение до
                    {{ new Date(ticket.contract.extended_before).toLocaleDateString("ru") }}</span>
                </InertiaLink>
                <span v-else class="text-indigo-600 mb-2 hover:underline">
                  нет договора
                </span>
              </p>
            </div>

            <span
              class="self-start bg-red-200 rounded-full px-3 py-1 text-red-800 text-lg leading-4 uppercase font-semibold tracking-wide">
              {{ ticket.aasm_state }}
            </span>
          </div>

          <div class="px-4 py-5 sm:px-6 leading-5">
            <div class="grid gap-4 grid-cols-2">
              <div>
                <p class="font-semibold text-gray-900">
                  <span v-if="ticket.sn">{{ ticket.sn }}</span>
                  <span v-else-if="info.serial_number" class="text-yellow-800">
                    {{ info.serial_number }}
                  </span>
                  <span v-if="(ticket.sn || info.serial_number) && (ticket.imei || info.imei)">
                    /
                  </span>
                  <span v-if="ticket.imei">{{ ticket.imei }}</span>
                  <span v-else-if="info.imei" class="text-yellow-700">
                    {{ info.imei }}
                  </span>
                </p>

                <p class="mt-1 text-gray-500">
                  {{ smartDescription }}
                </p>

                <p v-if="ticket.product_price" class="mt-1 text-gray-500">
                  {{ ticket.product_price }} руб.
                </p>

                <DeviceInfo v-if="ticket.brand_id === 1" class="mt-2" v-model:info="info" :ticket-id="ticket.id" />

                <p class="mt-4">
                  <span class="text-gray-900">{{ ticket.damage_type }}</span>
                  <br />
                  <span class="text-gray-500 text-sm">{{ ticket.issue }}</span>
                </p>
              </div>

              <div>
                <p class="font-semibold text-gray-900">
                  {{ ticket.fio }}
                </p>

                <p class="mt-1 text-gray-500">
                  {{ ticket.email }}
                </p>

                <div class="mt-1 text-gray-500">
                  <p>
                    <CopyToClipboard v-if="ticket.phone1" :value="ticket.phone1.replace(/[^0-9]/g, '')">
                      {{ ticket.phone1 }}
                    </CopyToClipboard>
                  </p>
                  <p>
                    <CopyToClipboard v-if="ticket.phone2" :value="ticket.phone2.replace(/[^0-9]/g, '')">
                      {{ ticket.phone2 }}
                    </CopyToClipboard>
                  </p>
                </div>

                <p class="mt-4">
                  <span class="text-gray-900">{{ ticket.city }}</span>
                  <br />
                  <span class="text-gray-500 text-sm">{{ ticket.address }}</span>
                </p>
              </div>
            </div>

            <div class="mt-8">
              <p class="font-semibold text-gray-900">
                {{ ticket.card_number }}
              </p>

              <p class="mt-1 text-gray-500">
                {{ ticket.receipt_number }}
              </p>

              <p class="mt-1 flex text-gray-500">
                {{ ticket.card_sold_date }}
                <span v-if="ticket.card_sold_date && ticket.salesman">,&nbsp;</span>
                {{ ticket.salesman }}
              </p>
            </div>
          </div>

          <Tracer />

          <PhoneCalls v-if="ticket.phone_calls.length" class="px-4 py-8 sm:px-6 bg-white border-t border-gray-200"
            :phone-calls="ticket.phone_calls" />

          <div v-if="ticket.attachments.length"
            class="flex items-center justify-between px-4 py-8 sm:px-6 bg-white border-t border-gray-200">
            <LightGallery :images="ticket.attachments" :opened="openedGallery" @rotation="saveRotationAngle"
              @closeGallery="closeGallery" @selectImage="selectImageGallery" v-slot="slotProps">
              <InertiaLink class="font-medium text-indigo-500 cursor-pointer select-none" preserve-state
                @click="slotProps.openGallery" :data="{ gallery: 0 }">
                Файлы
                <span
                  class="bg-gray-200 px-3 py-1 rounded-full text-indigo-800 text-xs uppercase font-semibold tracking-wide">
                  {{ ticket.attachments.length }}
                </span>
              </InertiaLink>
            </LightGallery>
            <Attachments />
          </div>

          <div class="px-4 py-4 sm:px-6 bg-gray-100 border-t border-gray-200 flex items-start">
            <div class="flex flex-col">
              <button v-if="ticket.actions.includes('assign_contract')"
                class="text-indigo-600 hover:underline mb-2 text-left" tabindex="-1" type="button"
                @click="event = 'AssignСontract'">
                Найти договор
              </button>
              <button v-if="ticket.actions.includes('request_documents')"
                class="text-indigo-600 hover:underline mb-2 text-left" tabindex="-1" type="button"
                @click="event = 'RequestDocuments'">
                Запросить дополнительные документы
              </button>
              <button v-if="ticket.actions.includes('upload_documents')"
                class="text-indigo-600 hover:underline mb-2 text-left" tabindex="-1" type="button"
                @click="event = 'ReceiveDocuments'">
                Отметить дополнительные документы полученными
              </button>
              <button v-if="ticket.actions.includes('request_device_reset')"
                class="text-indigo-600 hover:underline mb-2 text-left" tabindex="-1" type="button"
                @click="event = 'RequestDeviceReset'">
                Одобрить (запрос подготовки устройства)
              </button>
              <button v-if="ticket.actions.includes('decline')" class="text-indigo-600 hover:underline mb-2 text-left"
                tabindex="-1" type="button" @click="event = 'Decline'">
                Отклонить
              </button>
              <button v-if="ticket.actions.includes('forward_to_service')"
                class="text-indigo-600 hover:underline mb-2 text-left" tabindex="-1" type="button"
                @click="event = 'ForwardToService'">
                Направить в сервис
              </button>
              <button v-if="ticket.actions.includes('request_pickup')"
                class="text-indigo-600 hover:underline mb-2 text-left" tabindex="-1" type="button"
                @click="event = 'RequstPickup'">
                Вызвать курьера для доставки устройства в офис
              </button>
              <button v-if="ticket.actions.includes('cancel_pickup')"
                class="text-indigo-600 hover:underline mb-2 text-left" tabindex="-1" type="button"
                @click="event = 'CancelPickup'">
                Отменить курьера для доставки устройства в офис
              </button>
              <button v-if="ticket.actions.includes('receive_device')"
                class="text-indigo-600 hover:underline mb-2 text-left" tabindex="-1" type="button"
                @click="event = 'ReceiveDevice'">
                Принять устройство
              </button>
              <button v-if="ticket.actions.includes('route_device_to_service')"
                class="text-indigo-600 hover:underline mb-2 text-left" tabindex="-1" type="button"
                @click="event = 'RouteDeviceToService'">
                Направить устройство в сервис
              </button>
              <button v-if="ticket.actions.includes('generate_invoice')"
                class="text-indigo-600 hover:underline mb-2 text-left" tabindex="-1" type="button"
                @click="event = 'GenerateInvoice'">
                Выставить счет
              </button>
            </div>
          </div>
        </div>

        <component class="mt-12" :is="event" :ticketId="ticket.id" :ticket="ticket" @close="closeEventForm" />
      </div>

      <ul class="overflow-hidden ml-6 max-w-[15rem] hidden xl:block">
        <li class="mb-2 text-gray-600 pt-3" v-for="e in ticket.events" :key="e.created_at">
          <div class="text-gray-900 text-[11px] mb-1">
            {{ new Date(e.created_at).toLocaleString("ru") }}
          </div>
          <div class="leading-5">
            <span v-if="!e.user" class="font-medium"> Клиент </span>
            <span v-else-if="e.user.id === $page.props.auth.user.id" class="font-medium">
              Я
            </span>
            <span v-else class="font-medium"> {{ e.user.first_name }} {{ e.user.last_name }} </span>
            {{ e.description }}

            <InertiaLink v-if="e.description === 'привязал договор'"
              :href="e.data.contract_id ? $routes.contract(e.data.contract_id) : ''"
              class="text-indigo-600 hover:underline">
              #{{ e.data.contract_id }}
            </InertiaLink>
            <InertiaLink v-else-if="e.description === 'выставил счет'"
              :href="e.data.invoice_id ? $routes.invoices(e.data.invoice_id) : ''"
              class="text-indigo-600 hover:underline">
              #{{ e.data.invoice_id }}
            </InertiaLink>
            <!-- <span v-else-if="e.description === 'одобрил обращение'"></span> -->

            <!-- Тут показываем карточку события, либо json -->
            <pre v-else class="mt-1 text-xs whitespace-normal">{{ e.data }}</pre>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Layout from "@/Layouts/Full.vue";
import LoadingButton from "@/Shared/LoadingButton.vue";
import TrashedMessage from "@/Shared/TrashedMessage.vue";
import Card from "@/Shared/Card.vue";
import AssignСontract from "@/Actions/AssignСontract.vue";
import CancelPickup from "@/Actions/CancelPickup.vue";
import Decline from "@/Actions/Decline.vue";
import ForwardToService from "@/Actions/ForwardToService.vue";
import ReceiveDevice from "@/Actions/ReceiveDevice.vue";
import ReceiveDocuments from "@/Actions/ReceiveDocuments.vue";
import RequestDeviceReset from "@/Actions/RequestDeviceReset.vue";
import RequestDocuments from "@/Actions/RequestDocuments.vue";
import RequstPickup from "@/Actions/RequestPickup.vue";
import RouteDeviceToService from "@/Actions/RouteDeviceToService.vue";
import GenerateInvoice from "@/Actions/GenerateInvoice.vue";
import Attachments from "@/Shared/Attachments.vue";
import PhoneCalls from "@/Shared/PhoneCalls.vue";
import CopyToClipboard from "@/Shared/CopyToClipboard.vue";
import Tracer from "@/Shared/Tracer.vue";
import LightGallery from "@/Shared/LightGallery.vue";
import Icon from "@/Shared/Icon.vue";
import DeviceInfo from "@/Shared/TicketDeviceInfo.vue";

import axios from "axios";

export default {
  metaInfo() {
    return {
      title: `${this.ticket.ref}`,
    };
  },
  components: {
    LoadingButton,
    TrashedMessage,
    Card,
    Decline,
    AssignСontract,
    RequestDeviceReset,
    RequestDocuments,
    ReceiveDocuments,
    ForwardToService,
    RequstPickup,
    CancelPickup,
    ReceiveDevice,
    RouteDeviceToService,
    GenerateInvoice,
    Attachments,
    PhoneCalls,
    Icon,
    CopyToClipboard,
    Tracer,
    LightGallery,
    DeviceInfo,
  },
  layout: Layout,
  props: {
    ticket: {
      type: Object,
      required: true,
    },
    brands: {
      type: Array,
      required: true,
    },
    cards: {
      type: Array,
      default: null,
    },
    can: {
      type: Object,
      required: true,
    },
    device_info: {
      type: Object,
      required: true,
    },
    can: {
      type: Object,
      required: true,
    },
    currentEvent: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      openedGallery: false,
      event: null,
      sending: false,
      form: {
        ...this.ticket,
      },
      info: {},
    };
  },
  computed: {
    ticketForm() {
      let result = "";
      switch (this.ticket.form) {
        case "premium":
          result = "Премиум";
          break;
        case "leasing":
          result = "Лизинг";
          break;
        case "extension":
          result = "НПГ";
          break;
        case "poscredit":
          result = "РиК";
        break;
        default:
          result = "Стандарт";
      }
      return result;
    },
    smartDescription() {
      return (
        this.ticket.contract?.device_description ||
        this.info.model_description ||
        this.ticket.brand.name
      );
    },
  },
  mounted() {
    const params = this.getUrlQuery();
    if (params.hasOwnProperty("gallery")) {
      this.openedGallery = Number(params.gallery);
    }
  },
  methods: {
    assignContract(contractId) {
      let data = new FormData();
      data.append("contract_id", contractId);
      this.$inertia.post(this.$routes.assign_card_contract(this.ticket.id), data).then(() => {
        this.sending = false;
        this.form = { ...this.ticket };
      });
    },
    submit() {
      this.sending = true;

      let data = new FormData();
      data.append("ticket[fio]", this.form.fio || "");
      data.append("ticket[card_number]", this.form.card_number || "");
      data.append("ticket[issue]", this.form.issue || "");
      data.append("_method", "put");

      this.$inertia.post(this.$routes.ticket(this.ticket.id), data).then(() => {
        this.sending = false;
      });
    },
    findContracts() {
      this.$inertia.visit(this.$routes.edit_ticket(this.ticket.id, { with_contracts: true }), {
        preserveScroll: true,
      });
    },
    async saveRotationAngle(rotation) {
      await axios.post(`/attachments/${rotation.image_id}/rotate`, {
        angle: rotation.angle,
      });

      const attachment = this.ticket.attachments.find(
        (attachment) => attachment.id === rotation.image_id
      );

      if (attachment) {
        attachment.rotation = rotation.angle;
      }
    },
    closeEventForm() {
      this.event = null;
      this.$el.scrollIntoView({ behavior: "smooth" });
    },
    selectImageGallery(imageIdx) {
      const params = this.getUrlQuery();
      if (params.hasOwnProperty("gallery")) {
        params.gallery = imageIdx;
        this.$inertia.get(this.$routes.ticket(this.ticket.id), params, { preserveState: true });
      }
    },
    closeGallery() {
      const params = this.getUrlQuery();
      if (params.hasOwnProperty("gallery")) {
        this.openedGallery = false;
        delete params.gallery;
        this.$inertia.get(this.$routes.ticket(this.ticket.id), params, { preserveState: true });
      }
    },
    getUrlQuery() {
      const urlSearchParams = new URLSearchParams(window.location.search);
      return Object.fromEntries(urlSearchParams.entries());
    },
  },
  watch: {
    device_info: {
      handler(newValue) {
        this.info = newValue;
      },
      immediate: true,
    },
  },
};
</script>
