<template>
  <div v-if="images.length" class="gallery">
    <slot v-bind:openGallery="openGallery">
      <button @click="openGallery">Открыть галерею ({{ images.length }} фото)</button>
    </slot>
    <div
      v-if="galleryOpened"
      class="modal-gallery"
      :class="`mode-${mode > 0 && mode < 5 ? mode : 1}`"
      :style="`background: rgba(0,0,0,${backgroundOpacity})`"
    >
      <div class="thumbs">
        <div
          class="img"
          :class="{ active: currentImage.id === image.id }"
          v-for="image in images"
          :key="image.id"
          @click="() => selectImage(image, true)"
        >
          <div :class="{ 'admin-mark': image.user_id }" />
          <img :ref="`thumb-img-${image.id}`" />
        </div>
      </div>

      <div class="current-image">
        <img
          :src="currentImage.original_url"
          ref="currentImage"
          :style="
            `transform: translate(${translate.x}px, ${translate.y}px) rotate(${currentImage.rotation}deg) scale(${scale})`
          "
          v-show="currentImageIsLoaded"
          @mousedown="translateImage"
          @dblclick="() => scaleCurrentImage(scaleIndex)"
          @contextmenu.prevent="() => scaleCurrentImage(-scaleIndex)"
          @load="currentImageIsLoaded = true"
          @error="setPlug"
        />
      </div>

      <div class="control">
        <div class="control-item" @click="closeGallery">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </div>
        <div class="control-item" @click="() => rotateImage(-rotateIndex)">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M4.28,14.09a8,8,0,0,0,15.48-.15l0,.17A8,8,0,0,0,6.29,6.39l4.09,1.69L6.29,6.39l-.53-.22L7.67,1.55"
            />
          </svg>
        </div>
        <div class="control-item" @click="() => rotateImage(rotateIndex)">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M16.61,1.55l1.91,4.62L18,6.39,13.9,8.08,18,6.39A8,8,0,0,0,4.56,14.11l0-.17A8,8,0,0,0,20,14.09"
            />
          </svg>
        </div>
        <div class="control-item" @click="() => scaleCurrentImage(scaleIndex)">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0zM10 7v3m0 0v3m0-3h3m-3 0H7"
            />
          </svg>
        </div>
        <div class="control-item" @click="() => scaleCurrentImage(-scaleIndex)">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0zM13 10H7"
            />
          </svg>
        </div>
        <div class="control-item" @click="prevImg">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M11 19l-7-7 7-7m8 14l-7-7 7-7"
            />
          </svg>
        </div>
        <div class="control-item" @click="nextImg">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M13 5l7 7-7 7M5 5l7 7-7 7"
            />
          </svg>
        </div>
        <div class="control-item">
          <a :href="currentImage.original_url" target="_blank">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
              />
            </svg>
          </a>
        </div>
        <div class="control-item">
          <svg
            @click="backgroundOpacity = backgroundOpacity === 1 ? 0.3 : 1"
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6"
            fill="currentColor"
            viewBox="0 0 512 512"
          >
            <path
              d="m454.230469 173.328125v-57.257813c0-32.46875-26.410157-58.882812-58.882813-58.882812h-57.257812l-40.53125-40.53125c-22.210938-22.207031-60.886719-22.207031-83.09375 0l-40.535156 40.53125h-57.277344c-32.472656 0-58.882813 26.414062-58.882813 58.882812v57.257813l-40.53125 40.535156c-11.117187 11.113281-17.238281 25.875-17.238281 41.554688 0 15.679687 6.121094 30.445312 17.238281 41.535156l40.53125 40.535156v57.257813c0 32.46875 26.410157 58.878906 58.882813 58.878906h57.257812l40.53125 40.535156c11.117188 11.136719 25.878906 17.257813 41.558594 17.257813s30.441406-6.121094 41.535156-17.238281l40.535156-40.53125h57.257813c32.46875 0 58.878906-26.410157 58.878906-58.878907v-57.261719l40.535157-40.53125c11.136718-11.113281 17.257812-25.878906 17.257812-41.558593 0-15.679688-6.121094-30.441407-17.238281-41.535157zm17.917969 101.011719-45.226563 45.226562c-3.007813 3.007813-4.691406 7.085938-4.691406 11.308594v63.894531c0 14.824219-12.054688 26.878907-26.882813 26.878907h-63.890625c-4.246093 0-8.320312 1.683593-11.308593 4.691406l-45.226563 45.226562c-10.429687 10.433594-27.390625 10.433594-37.824219 0l-45.226562-45.226562c-3.007813-3.007813-7.082032-4.691406-11.304688-4.691406h-63.914062c-14.828125 0-26.882813-12.054688-26.882813-26.878907v-63.894531c0-4.246094-1.683593-8.320312-4.691406-11.308594l-45.226563-45.226562c-10.433593-10.429688-10.433593-27.410156 0-37.824219l45.226563-45.226563c3.007813-3.007812 4.691406-7.082031 4.691406-11.304687v-63.914063c0-14.828124 12.054688-26.882812 26.882813-26.882812h63.890625c4.246093 0 8.320312-1.683594 11.308593-4.691406l45.226563-45.226563c10.429687-10.433593 27.390625-10.433593 37.824219 0l45.226562 45.226563c3.007813 3.007812 7.082032 4.691406 11.304688 4.691406h63.894531c14.828125 0 26.878906 12.054688 26.878906 26.882812v63.890626c0 4.246093 1.6875 8.320312 4.695313 11.308593l45.226562 45.226563c10.453125 10.433594 10.453125 27.414062.019532 37.84375zm0 0"
            />
            <path
              v-if="backgroundOpacity === 1"
              d="m256 127.417969c-70.59375 0-128 57.410156-128 128 0 70.59375 57.40625 128 128 128s128-57.40625 128-128c0-70.589844-57.40625-128-128-128zm0 224c-52.929688 0-96-43.070313-96-96 0-52.925781 43.070312-96 96-96s96 43.074219 96 96c0 52.929687-43.070312 96-96 96zm0 0"
            />
            <path
              v-else
              d="m304.46875 166.949219c6.125-2.390625 10.15625-8.257813 10.199219-14.828125.042969-6.546875-3.949219-12.457032-10.027344-14.933594-15.253906-6.140625-31.59375-9.257812-48.640625-9.257812-74.175781 0-128 53.824218-128 128 0 74.175781 53.824219 128 128 128 17.046875 0 33.386719-3.113282 48.640625-9.257813 6.078125-2.453125 10.070313-8.382813 10.027344-14.933594-.042969-6.570312-4.054688-12.4375-10.199219-14.828125-33.75-13.160156-69.800781-43.476562-69.800781-88.980468 0-45.503907 36.050781-75.816407 69.800781-88.980469zm-101.800781 88.980469c0 37.417968 18.21875 71.660156 49.449219 95.9375-53.609376-1.835938-92.117188-41.621094-92.117188-95.9375 0-54.3125 38.507812-94.101563 92.117188-95.933594-31.230469 24.277344-49.449219 58.515625-49.449219 95.933594zm0 0"
            />
          </svg>
        </div>
        <div class="control-item" @click="mode === 2 ? (mode = 1) : (mode += 1)">
          {{ mode }}
        </div>
      </div>
    </div>
    <canvas ref="canvas" style="display: none" />
  </div>
</template>

<script>
export default {
  props: {
    opened: [Number, Boolean],
    images: {
      type: Array,
      default: () => []
    }
  },
  emits: ["closeGallery", "selectImage", "rotation"],
  data() {
    return {
      scaleIndex: 0.25,
      scale: 1,
      translateIndex: 10,
      translate: {
        x: 0,
        y: 0
      },
      rotateIndex: 90,
      currentImageIsLoaded: false,
      galleryOpened: false,
      currentImage: null,
      mode: 1,
      backgroundOpacity: 0.3
    };
  },
  computed: {
    currentImageIsBlocked() {
      return (
        this.currentImage.block ||
        this.isArchive(this.currentImage) ||
        this.isPDF(this.currentImage)
      );
    }
  },
  methods: {
    async openGallery(index) {
      if (Number.isInteger(index) && index > 0 && index < this.images.length) {
        this.selectImage(this.images[index]);
      } else {
        this.selectImage(this.images[0]);
      }
      this.galleryOpened = true;
      document.body.style.overflow = "hidden";

      document.onkeydown = e => {
        const key = e.key || e.code || e.keyCode;

        if (key === "Escape" || key === "Esc" || key === 27) {
          this.closeGallery();
        } else if (key === "ArrowLeft") {
          if (e.shiftKey) {
            this.moveCurrentImage(-this.translateIndex, 0);
          } else {
            this.prevImg();
          }
        } else if (key === "ArrowRight") {
          if (e.shiftKey) {
            this.moveCurrentImage(this.translateIndex, 0);
          } else {
            this.nextImg();
          }
        } else if (key === "ArrowUp") {
          if (e.shiftKey) {
            this.moveCurrentImage(0, -this.translateIndex);
          } else {
            this.scaleCurrentImage(this.scaleIndex);
          }
        } else if (key === "ArrowDown") {
          if (e.shiftKey) {
            this.moveCurrentImage(0, this.translateIndex);
          } else {
            this.scaleCurrentImage(-this.scaleIndex);
          }
        }
      };

      this.$nextTick(() => {
        this.renderThumbs();
      });
    },
    closeGallery() {
      document.body.style.overflow = "";
      this.galleryOpened = false;
      this.currentImage = null;
      document.onkeydown = null;
      this.$emit("closeGallery");
    },
    renderThumbs() {
      this.images.forEach(image => {
        this.rotateThumb(image);
      });
    },
    async rotateThumb(image) {
      const img = this.$refs[`thumb-img-${image.id}`][0];
      try {
        const imgSrc = image.thumb_url
          ? await this.rotatedImgSrc(image.thumb_url, image.rotation)
          : "/is-archive.jpg";
        img.src = imgSrc;
      } catch (error) {
        img.src = "/image-not-found.jpg";
      }
    },
    selectImage(image, withDownload = false) {
      if (withDownload && (this.isArchive(image) || this.isPDF(image))) {
        this.downloadIfNotImage(image.original_url);
        return;
      }

      this.currentImageIsLoaded = false;
      this.scale = 1;
      this.translate = { x: 0, y: 0 };
      this.currentImage = image;
      this.$emit(
        "selectImage",
        this.images.findIndex(img => img.id === image.id)
      );
    },
    setPlug() {
      this.currentImage.rotation = 0;
      this.$set(this.currentImage, "block", true);
      this.$refs.currentImage.src = "/image-not-found.jpg";
    },
    async rotateImage(angleOffset) {
      if (this.currentImageIsBlocked) return;

      let resultAngle =
        this.currentImage.rotation + angleOffset >= 0
          ? this.currentImage.rotation + angleOffset
          : 360 + angleOffset;
      const newAngle = resultAngle % 360;

      this.currentImage.rotation = newAngle;
      this.rotateThumb(this.currentImage);
      this.$emit("rotation", { image_id: this.currentImage.id, angle: newAngle });
    },
    rotatedImgSrc(src, angle = 0) {
      return new Promise((resolve, reject) => {
        const canvas = this.$refs.canvas;
        const ctx = canvas.getContext("2d");

        ctx.save();

        ctx.setTransform(1, 0, 0, 1, 0, 0);
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        const img = new Image();
        img.crossOrigin = "Anonymous";
        img.onload = () => {
          resolve(drowRotatedImage());
        };
        img.onerror = () => {
          reject("Error in loading image");
        };
        img.src = src;

        const drowRotatedImage = () => {
          if (angle === 90 || angle === 270) {
            canvas.width = img.height;
            canvas.height = img.width;
          } else {
            canvas.width = img.width;
            canvas.height = img.height;
          }

          ctx.translate(canvas.width / 2, canvas.height / 2);
          ctx.rotate((angle * Math.PI) / 180);
          ctx.drawImage(img, -img.width / 2, -img.height / 2);

          ctx.restore();
          try {
            return canvas.toDataURL();
          } catch (error) {
            return src;
          }
        };
      });
    },
    scaleCurrentImage(scaleOffset) {
      if (this.currentImageIsBlocked) return;

      let newScale = 1;
      if (!scaleOffset) {
      } else if (this.scale + scaleOffset <= 0) {
        newScale = 0.25;
      } else if (this.scale + scaleOffset >= 5) {
        newScale = 5;
      } else {
        newScale = this.scale + scaleOffset;
      }

      this.scale = newScale;
    },
    translateImage(e) {
      if (this.currentImageIsBlocked) return;

      e = e || window.event;
      e.preventDefault();
      const imagePositionX = this.translate.x || 0;
      const imagePositionY = this.translate.y || 0;

      const mousePositionX = e.clientX;
      const mousePositionY = e.clientY;

      const dragImage = e => {
        e = e || window.event;
        e.preventDefault();

        const newImagePositionX = imagePositionX + (e.clientX - mousePositionX);
        const newImagePositionY = imagePositionY + (e.clientY - mousePositionY);

        this.translate.x = newImagePositionX;
        this.translate.y = newImagePositionY;
      };

      const translateEnd = () => {
        document.onmouseup = null;
        document.onmousemove = null;
      };
      document.onmouseup = translateEnd;
      document.onmousemove = dragImage;
    },
    moveCurrentImage(x, y) {
      if (this.currentImageIsBlocked) return;

      this.translate.x = this.translate.x + x;
      this.translate.y = this.translate.y + y;
    },
    prevImg() {
      const curImgIdx = this.images.findIndex(image => image.id === this.currentImage.id);

      const prevImgIdx = curImgIdx - 1 < 0 ? this.images.length - 1 : curImgIdx - 1;

      const image = this.images[prevImgIdx];
      this.selectImage(image);
    },
    nextImg() {
      const curImgIdx = this.images.findIndex(image => image.id === this.currentImage.id);

      const nextImgIdx = curImgIdx + 1 > this.images.length - 1 ? 0 : curImgIdx + 1;

      const image = this.images[nextImgIdx];
      this.selectImage(image);
    },
    isArchive(image) {
      return !image.thumb_url || !!image.original_url.match(/\.rar\?|\.zip\?/);
    },
    isPDF(image) {
      return !!image.original_url.match(/\.pdf\?/);
    },
    downloadIfNotImage(url) {
      const a = document.createElement("a");
      const fileName = url.substring(url.indexOf("/original-") + 1, url.indexOf("?") + 1);

      a.href = url;
      a.setAttribute("download", fileName || "file");
      a.setAttribute("target", "_blank");
      a.setAttribute("rel", "noopener noreferrer");

      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  },
  watch: {
    opened: {
      handler(newVal) {
        if (newVal || newVal === 0) {
          this.openGallery(newVal);
        }
      },
      immediate: true
    }
  }
};
</script>

<style scoped>
.gallery .modal-gallery {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3000;
  overflow: hidden;
  background: black;
  justify-content: space-between;
}
.gallery .modal-gallery.mode-1 {
  display: flex;
}
.gallery .modal-gallery.mode-2 {
  display: flex;
  flex-direction: row-reverse;
}
.gallery .modal-gallery.mode-3 {
  display: flex;
  flex-direction: column;
}
.gallery .modal-gallery.mode-4 {
  display: flex;
  flex-direction: column-reverse;
}
.gallery .modal-gallery.mode-1 .thumbs,
.gallery .modal-gallery.mode-2 .thumbs {
  width: 11rem;
  height: 100%;
  padding: 1rem;
}
.gallery .modal-gallery.mode-1 .thumbs .img,
.gallery .modal-gallery.mode-2 .thumbs .img {
  width: 100%;
  max-height: 16rem;
}
.gallery .modal-gallery.mode-1 .thumbs .img:not(:first-child),
.gallery .modal-gallery.mode-2 .thumbs .img:not(:first-child) {
  margin-top: 1.5rem;
}
.gallery .modal-gallery.mode-1 .thumbs .img img,
.gallery .modal-gallery.mode-2 .thumbs .img img {
  width: 100%;
}
.gallery .modal-gallery.mode-3 .thumbs,
.gallery .modal-gallery.mode-4 .thumbs {
  display: flex;
  width: 100%;
  height: 11rem;
  padding-bottom: 1rem;
}
.gallery .modal-gallery.mode-3 .thumbs .img,
.gallery .modal-gallery.mode-4 .thumbs .img {
  height: 100%;
  max-width: 16rem;
}
.gallery .modal-gallery.mode-3 .thumbs .img:not(:first-child),
.gallery .modal-gallery.mode-4 .thumbs .img:not(:first-child) {
  margin-left: 0.5rem;
}
.gallery .modal-gallery.mode-3 .thumbs .img img,
.gallery .modal-gallery.mode-4 .thumbs .img img {
  height: 100%;
}
.gallery .modal-gallery.mode-3 .current-image,
.gallery .modal-gallery.mode-4 .current-image {
  margin: 1rem 0;
}
.gallery .modal-gallery.mode-1 .control,
.gallery .modal-gallery.mode-2 .control {
  height: 100%;
  padding: 1rem;
}
.gallery .modal-gallery.mode-1 .control .control-item:not(:first-child),
.gallery .modal-gallery.mode-2 .control .control-item:not(:first-child) {
  margin-top: 0.5rem;
}
.gallery .modal-gallery.mode-3 .control,
.gallery .modal-gallery.mode-4 .control {
  display: flex;
  width: 100%;
}
.gallery .modal-gallery.mode-3 .control .control-item:not(:first-child),
.gallery .modal-gallery.mode-4 .control .control-item:not(:first-child) {
  margin-left: 0.5rem;
}
.gallery .modal-gallery .thumbs,
.gallery .modal-gallery .control {
  z-index: 1;
  background: inherit;
}
.gallery .modal-gallery .thumbs {
  overflow: scroll;
  flex-shrink: 0;
}
.gallery .modal-gallery .thumbs .img {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;
  overflow: hidden;
}
.gallery .modal-gallery .thumbs .img .admin-mark {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 0;
  border: 10px solid transparent;
  border-right: 10px solid rgba(245, 45, 45, 0.9);
  border-bottom: 10px solid rgba(245, 45, 45, 0.9);
}
.gallery .modal-gallery .thumbs .img.active {
  box-shadow: 0 0 3px 10px rgba(55, 145, 245, 0.5);
}
.gallery .current-image {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.gallery .modal-gallery .current-image img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  cursor: move;
}
.gallery .modal-gallery .control {
  flex-shrink: 0;
}
.gallery .modal-gallery .control .control-item {
  color: black;
  background: white;
  border-radius: 100px;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.25rem;
  cursor: pointer;
}
</style>
