<template>
  <div>
    <div class="mb-8 flex justify-start max-w-3xl">
      <h1 class="font-bold text-3xl">
        <InertiaLink class="text-indigo-500 hover:text-indigo-600" :href="$routes.revocations()">
          Отмены контрактов
        </InertiaLink>
        <span class="text-indigo-400 font-medium">/</span>
        {{ revocation.id }}
      </h1>
    </div>
    <div class="bg-white shadow overflow-hidden sm:rounded-lg">
      <div class="px-4 py-5 border-b border-gray-200 sm:px-6 flex justify-between">
        <div>
          <h3 class="text-lg leading-6 font-medium text-gray-900"># {{ revocation.id }}</h3>

          <p class="mt-1 max-w-2xl text-sm leading-5 text-gray-500">
            {{ revocation.description }}<br />
            {{
              new Date(revocation.revoked_at).toLocaleString("ru", { timeZone: "Europe/Moscow" })
            }}
          </p>
        </div>
      </div>
      <div>
        <dl class="list">
          <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dd class="text-sm leading-5 font-medium text-gray-500">
              Карта
            </dd>
            <dt class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
              {{ revocation.number }}
            </dt>
          </div>
          <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dd class="text-sm leading-5 font-medium text-gray-500">
              Серийный номер / IMEI
            </dd>
            <dt class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
              {{ revocation.device_sn }} / {{ revocation.device_imei }}
            </dt>
          </div>
          <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dd class="text-sm leading-5 font-medium text-gray-500">
              Устройство
            </dd>
            <dt class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
              {{ revocation.device_description }}
            </dt>
          </div>
          <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dd class="text-sm leading-5 font-medium text-gray-500">
              Партномер
            </dd>
            <dt class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
              {{ revocation.device_pn }}
            </dt>
          </div>
          <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dd class="text-sm leading-5 font-medium text-gray-500">
              Стоимость (п/у)
            </dd>
            <dt class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
              {{
                Intl.NumberFormat("ru-RU", {
                  style: "currency",
                  currency: "RUB",
                }).format(revocation.price)
              }}
              /
              {{
                Intl.NumberFormat("ru-RU", {
                  style: "currency",
                  currency: "RUB",
                }).format(revocation.device_price)
              }}
            </dt>
          </div>
          <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dd class="text-sm leading-5 font-medium text-gray-500">
              Документ
            </dd>
            <dt class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
              {{ revocation.document }}
            </dt>
          </div>
          <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dd class="text-sm leading-5 font-medium text-gray-500">
              Продавец
            </dd>
            <dt class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
              {{ revocation.salesman }} / {{ revocation.store }}
            </dt>
          </div>
          <div
            v-if="revocation.eligible_contracts.length && !revocation.contract"
            class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
          >
            <dd class="text-sm leading-5 font-medium text-gray-500">
              Подходящие контракты
            </dd>
            <dt class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
              <ul class="border border-gray-200 rounded-md">
                <li
                  class="pl-3 pr-4 py-3 flex items-center justify-between text-sm"
                  v-for="c in revocation.eligible_contracts"
                  :key="c.id"
                >
                  <div class="w-0 flex-1 flex items-center">
                    <svg
                      class="flex-shrink-0 h-5 w-5 text-gray-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    <InertiaLink :href="$routes.contract(c.id)">
                      <span class="ml-2 flex-1 w-0 truncate">
                        {{ c.document }} - {{ c.price }}
                      </span>
                    </InertiaLink>
                  </div>
                  <div class="ml-4 flex-shrink-0">
                    <a
                      href="#"
                      class="font-medium text-indigo-600 hover:text-indigo-500 transition duration-150 ease-in-out"
                      @click="revokeContract(c.id)"
                    >
                      Связать
                    </a>
                  </div>
                </li>
              </ul>
            </dt>
          </div>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import Layout from "@/Layouts/Full.vue";

export default {
  metaInfo: { title: "Аннулирования" },
  layout: Layout,
  props: {
    revocation: {
      type: Object,
      required: true,
    },
  },
  emits: ["close"],
  methods: {
    revokeContract(contractId) {
      this.$inertia
        .post(this.$routes.revoke_contract(contractId), { revocation_id: this.revocation.id })
        .then(() => {
          this.$emit("close");
          this.sending = false;
          // this.form = { ...this.ticket };
        });
    },
  },
};
</script>

<style scoped>
.list div:nth-of-type(2n + 1) {
  @apply bg-gray-50;
}
</style>
