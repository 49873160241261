<template>
  <div class="flex items-center">
    <div class="flex w-full bg-white shadow rounded">
      <Dropdown
        v-if="!onlySearch"
        class="px-4 md:px-6 rounded-l border-r hover:bg-gray-100 focus:border-white focus:shadow-outline focus:z-10"
        placement="bottom-start"
        aria-label="Filter"
        :auto-close="false"
      >
        <div class="flex items-baseline">
          <span class="text-gray-800 hidden md:inline">Фильтр</span>
          <svg
            class="w-2 h-2 fill-gray-700 md:ml-2"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 961.243 599.998"
          >
            <path
              d="M239.998 239.999L0 0h961.243L721.246 240c-131.999 132-240.28 240-240.624 239.999-.345-.001-108.625-108.001-240.624-240z"
            />
          </svg>
        </div>
        <template #dropdown>
          <div
            class="mt-2 px-4 py-6 w-screen shadow-xl bg-white rounded"
            :style="{ maxWidth: `${maxWidth}px` }"
          >
            <slot />
          </div>
        </template>
      </Dropdown>
      <input
        class="w-full px-6 py-3 rounded-r focus:shadow-outline"
        :class="{ 'rounded-l': onlySearch }"
        aria-label="Search"
        autocomplete="off"
        type="text"
        name="search"
        placeholder="Поиск..."
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
      />
    </div>
  </div>
</template>

<script>
import Dropdown from "@/Shared/Dropdown.vue";

export default {
  components: {
    Dropdown
  },
  props: {
    modelValue: {
      type: String,
      default: null
    },
    onlySearch: {
      type: Boolean,
      default: false
    },
    maxWidth: {
      type: Number,
      default: 360
    }
  },
  emits: ["update:modelValue"]
};
</script>
