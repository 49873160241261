<template>
  <div>
    <div class="mb-8 flex justify-start max-w-3xl">
      <h1 class="font-bold text-3xl">
        <InertiaLink
          class="text-indigo-500 hover:text-indigo-600"
          :href="$routes.contracts()"
        >
          Контракты
        </InertiaLink>
        <span class="text-indigo-400 font-medium">/</span>
        {{ contract.id }}
      </h1>
    </div>
    <div class="bg-white shadow overflow-hidden sm:rounded-lg">
      <div
        class="px-4 py-5 border-b border-gray-200 sm:px-6 flex justify-between"
      >
        <div>
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            # {{ contract.id }}
          </h3>

          <p class="mt-1 max-w-2xl text-sm leading-5 text-gray-500">
            {{ contract.description }}<br />
            c {{ new Date(contract.sold_at).toLocaleString("ru", {timeZone: "Europe/Moscow"}) }} по
            {{ new Date(contract.valid_before).toLocaleString("ru", {timeZone: "Europe/Moscow"}) }}
          </p>
        </div>

        <InertiaLink
          v-if="contract.revocation_id"
          :href="$routes.revocation(contract.revocation_id)"
        >
          <span class="bg-red-200 text-red-800 text-xs px-3 py-1 rounded-full uppercase font-semibold tracking-wide">
            Аннулирован
          </span>
        </InertiaLink>
      </div>
      <div>
        <dl class="list">
          <div
            class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
          >
            <dd class="text-sm leading-5 font-medium text-gray-500">
              Карта
            </dd>
            <dt
              class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"
            >
              {{ contract.number }}
            </dt>
          </div>
          <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dd class="text-sm leading-5 font-medium text-gray-500">
              Серийный номер / IMEI
            </dd>
            <dt
              class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"
            >
              {{ contract.device_sn }} / {{ contract.device_imei }}
            </dt>
          </div>
          <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dd class="text-sm leading-5 font-medium text-gray-500">
              Устройство
            </dd>
            <dt
              class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"
            >
              {{ contract.device_description }}
            </dt>
          </div>
          <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dd class="text-sm leading-5 font-medium text-gray-500">
              Партномер
            </dd>
            <dt
              class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"
            >
              {{ contract.device_pn }}
            </dt>
          </div>
          <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dd class="text-sm leading-5 font-medium text-gray-500">
              Стоимость
            </dd>
            <dt
              class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"
            >
              {{
                Intl.NumberFormat("ru-RU", {
                  style: "currency",
                  currency: "RUB"
                }).format(contract.price)
              }}
              /
              {{
                Intl.NumberFormat("ru-RU", {
                  style: "currency",
                  currency: "RUB"
                }).format(contract.device_price)
              }}
            </dt>
          </div>
          <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dd class="text-sm leading-5 font-medium text-gray-500">
              Документ
            </dd>
            <dt
              class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"
            >
              {{ contract.document }}
            </dt>
          </div>
          <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dd class="text-sm leading-5 font-medium text-gray-500">
              Продавец
            </dd>
            <dt
              class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"
            >
              {{ contract.salesman }} / {{ contract.store }}
            </dt>
          </div>
          <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dd class="text-sm leading-5 font-medium text-gray-500">
              Канал продаж
            </dd>
            <dt
              class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"
            >
              {{ contract.sales_channel }}
            </dt>
          </div>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import Layout from "@/Layouts/Full.vue";

export default {
  metaInfo: { title: "Контракт" },
  layout: Layout,
  props: {
    contract: {
      type: Object,
      required: true
    }
  }
};
</script>

<style scoped>
.list div:nth-of-type(2n + 1) {
  @apply bg-gray-50;
}
</style>

