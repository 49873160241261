<template>
  <svg
    v-if="name === 'apple'"
    xmlns="http://www.w3.org/2000/svg"
    width="100"
    height="100"
    viewBox="0 0 100 100"
  >
    <g fill-rule="nonzero">
      <path
        d="M46.173 19.967C49.927-1.838 19.797-.233 14.538.21c-.429.035-.648.4-.483.8 2.004 4.825 14.168 31.66 32.118 18.957zm13.18 1.636c1.269-.891 1.35-1.614.047-2.453l-2.657-1.71c-.94-.607-1.685-.606-2.532.129-5.094 4.42-7.336 9.18-8.211 15.24 1.597.682 3.55.79 5.265.328 1.298-4.283 3.64-8.412 8.088-11.534z"
      />
      <path
        d="M88.588 67.75c9.65-27.532-13.697-45.537-35.453-32.322-1.84 1.118-4.601 1.118-6.441 0-21.757-13.215-45.105 4.79-35.454 32.321 5.302 15.123 17.06 39.95 37.295 29.995.772-.38 1.986-.38 2.758 0 20.235 9.955 31.991-14.872 37.295-29.995z"
      />
    </g>
  </svg>
  <svg v-else-if="name === 'book'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
    <path
      d="M6 4H5a1 1 0 1 1 0-2h11V1a1 1 0 0 0-1-1H4a2 2 0 0 0-2 2v16c0 1.1.9 2 2 2h12a2 2 0 0 0 2-2V5a1 1 0 0 0-1-1h-7v8l-2-2-2 2V4z"
    />
  </svg>
  <svg v-else-if="name === 'cheveron-down'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
  </svg>
  <svg v-else-if="name === 'cheveron-right'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
    <polygon
      points="12.95 10.707 13.657 10 8 4.343 6.586 5.757 10.828 10 6.586 14.243 8 15.657 12.95 10.707"
    />
  </svg>
  <svg v-else-if="name === 'dashboard'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
    <path
      d="M10 20a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm-5.6-4.29a9.95 9.95 0 0 1 11.2 0 8 8 0 1 0-11.2 0zm6.12-7.64l3.02-3.02 1.41 1.41-3.02 3.02a2 2 0 1 1-1.41-1.41z"
    />
  </svg>
  <svg v-else-if="name === 'location'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
    <path
      d="M10 20S3 10.87 3 7a7 7 0 1 1 14 0c0 3.87-7 13-7 13zm0-11a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"
    />
  </svg>
  <svg
    v-else-if="name === 'office'"
    xmlns="http://www.w3.org/2000/svg"
    width="100"
    height="100"
    viewBox="0 0 100 100"
  >
    <path
      fill-rule="evenodd"
      d="M7 0h86v100H57.108V88.418H42.892V100H7V0zm9 64h11v15H16V64zm57 0h11v15H73V64zm-19 0h11v15H54V64zm-19 0h11v15H35V64zM16 37h11v15H16V37zm57 0h11v15H73V37zm-19 0h11v15H54V37zm-19 0h11v15H35V37zM16 11h11v15H16V11zm57 0h11v15H73V11zm-19 0h11v15H54V11zm-19 0h11v15H35V11z"
    />
  </svg>
  <svg v-else-if="name === 'printer'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
    <path d="M4 16H0V6h20v10h-4v4H4v-4zm2-4v6h8v-6H6zM4 0h12v5H4V0zM2 8v2h2V8H2zm4 0v2h2V8H6z" />
  </svg>
  <svg v-else-if="name === 'shopping-cart'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
    <path
      d="M4 2h16l-3 9H4a1 1 0 1 0 0 2h13v2H4a3 3 0 0 1 0-6h.33L3 5 2 2H0V0h3a1 1 0 0 1 1 1v1zm1 18a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm10 0a2 2 0 1 1 0-4 2 2 0 0 1 0 4z"
    />
  </svg>
  <svg v-else-if="name === 'store-front'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
    <path
      d="M18 9.87V20H2V9.87a4.25 4.25 0 0 0 3-.38V14h10V9.5a4.26 4.26 0 0 0 3 .37zM3 0h4l-.67 6.03A3.43 3.43 0 0 1 3 9C1.34 9 .42 7.73.95 6.15L3 0zm5 0h4l.7 6.3c.17 1.5-.91 2.7-2.42 2.7h-.56A2.38 2.38 0 0 1 7.3 6.3L8 0zm5 0h4l2.05 6.15C19.58 7.73 18.65 9 17 9a3.42 3.42 0 0 1-3.33-2.97L13 0z"
    />
  </svg>
  <svg v-else-if="name === 'trash'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
    <path d="M6 2l2-2h4l2 2h4v2H2V2h4zM3 6h14l-1 14H4L3 6zm5 2v10h1V8H8zm3 0v10h1V8h-1z" />
  </svg>
  <svg v-else-if="name === 'users'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
    <path
      d="M7 8a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm0 1c2.15 0 4.2.4 6.1 1.09L12 16h-1.25L10 20H4l-.75-4H2L.9 10.09A17.93 17.93 0 0 1 7 9zm8.31.17c1.32.18 2.59.48 3.8.92L18 16h-1.25L16 20h-3.96l.37-2h1.25l1.65-8.83zM13 0a4 4 0 1 1-1.33 7.76 5.96 5.96 0 0 0 0-7.52C12.1.1 12.53 0 13 0z"
    />
  </svg>

  <svg
    v-else-if="name === 'cards'"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    fill-rule="evenodd"
  >
    <g id="icon-shape">
      <path
        d="M0,3.99406028 C0,2.8927712 0.898212381,2 1.99079514,2 L18.0092049,2 C19.1086907,2 20,2.89451376 20,3.99406028 L20,16.0059397 C20,17.1072288 19.1017876,18 18.0092049,18 L1.99079514,18 C0.891309342,18 0,17.1054862 0,16.0059397 L0,3.99406028 Z M2,4 L18,4 L18,16 L2,16 L2,4 Z M2,6 L18,6 L18,10 L2,10 L2,6 Z M4,12 L8,12 L8,14 L4,14 L4,12 Z"
        id="Combined-Shape"
      ></path>
    </g>
  </svg>
  <svg v-else-if="name === 'folder'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
    <g stroke="none" stroke-width="1">
      <g id="icon-shape">
        <path
          d="M0,3.99406028 C0,2.8927712 0.895857811,2 1.9973917,2 L9,2 L11,4 L18.0026083,4 C19.1057373,4 20,4.89706013 20,6.00585866 L20,15.9941413 C20,17.1019465 19.1017876,18 18.0092049,18 L1.99079514,18 C0.891309342,18 0,17.1054862 0,16.0059397 L0,3.99406028 Z"
          id="Rectangle-1"
        ></path>
      </g>
    </g>
  </svg>
  <svg
    v-else-if="name === 'folder-outlined'"
    xmlns="http://www.w3.org/2000/svg"
    xml:space="preserve"
    viewBox="0 0 20 20"
  >
    <path
      d="M17.2 18.2H2.8A2.9 2.9 0 0 1 0 15.4V4.6c0-1.5 1.3-2.8 2.8-2.8h6.3c.3 0 .5.1.7.3l1.5 1.5h5.9c1.5 0 2.8 1.3 2.8 2.8v9c0 1.5-1.3 2.8-2.8 2.8zM2.8 3.8c-.4 0-.8.4-.8.8v10.8c0 .4.4.8.8.8h14.4c.4 0 .8-.4.8-.8v-9c0-.4-.4-.8-.8-.8h-6.3a1 1 0 0 1-.7-.3L8.7 3.8H2.8z"
    />
  </svg>
  <svg
    v-else-if="name === 'folder-plus'"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 42.21 38.68"
  >
    <path
      d="M1.1,1.1,0,2.16V29.54L1.1,30.6c1.06,1.1,1.06,1.1,10.48,1.1H21l.22-3a11.68,11.68,0,0,1,6.48-10,12.68,12.68,0,0,1,8.36-.53,5.15,5.15,0,0,0,2.33.49,34.26,34.26,0,0,0,.36-6.61c0-6.2,0-6.42-1.1-7.44s-1.1-1.1-10-1.1H18.67L17,1.76,15.23,0H8.72C2.33,0,2.11,0,1.1,1.1Z"
    />
    <path
      d="M28.14,20.61a9.54,9.54,0,0,0-.71,16.55c8.72,5.37,18.63-4.53,13.25-13.25A8.28,8.28,0,0,0,37,20.61,8.69,8.69,0,0,0,28.14,20.61Zm5.24,5.94c.13,1.5.26,1.59,1.76,1.72,1.1.09,1.63.35,1.63.79s-.53.7-1.63.79c-1.54.13-1.63.22-1.76,1.76-.09,1.1-.36,1.63-.8,1.63s-.7-.53-.79-1.63c-.13-1.54-.22-1.63-1.76-1.76-2.25-.17-2.25-1.41,0-1.58,1.54-.13,1.63-.22,1.63-1.59C31.7,24.08,33.15,24,33.38,26.55Z"
    />
  </svg>
  <svg v-else-if="name === 'badge'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.26701 3.45496C6.91008 3.40364 7.52057 3.15077 8.01158 2.73234C9.15738 1.75589 10.8426 1.75589 11.9884 2.73234C12.4794 3.15077 13.0899 3.40364 13.733 3.45496C15.2336 3.57471 16.4253 4.76636 16.545 6.26701C16.5964 6.91008 16.8492 7.52057 17.2677 8.01158C18.2441 9.15738 18.2441 10.8426 17.2677 11.9884C16.8492 12.4794 16.5964 13.0899 16.545 13.733C16.4253 15.2336 15.2336 16.4253 13.733 16.545C13.0899 16.5964 12.4794 16.8492 11.9884 17.2677C10.8426 18.2441 9.15738 18.2441 8.01158 17.2677C7.52057 16.8492 6.91008 16.5964 6.26701 16.545C4.76636 16.4253 3.57471 15.2336 3.45496 13.733C3.40364 13.0899 3.15077 12.4794 2.73234 11.9884C1.75589 10.8426 1.75589 9.15738 2.73234 8.01158C3.15077 7.52057 3.40364 6.91008 3.45496 6.26701C3.57471 4.76636 4.76636 3.57471 6.26701 3.45496ZM13.7071 8.70711C14.0976 8.31658 14.0976 7.68342 13.7071 7.29289C13.3166 6.90237 12.6834 6.90237 12.2929 7.29289L9 10.5858L7.70711 9.29289C7.31658 8.90237 6.68342 8.90237 6.29289 9.29289C5.90237 9.68342 5.90237 10.3166 6.29289 10.7071L8.29289 12.7071C8.68342 13.0976 9.31658 13.0976 9.70711 12.7071L13.7071 8.70711Z"
    />
  </svg>

  <!-- <g stroke-width="1" fill-rule="evenodd">
      <g id="icon-shape">
        <path d="M10,12 C13.3137085,12 16,9.3137085 16,6 C16,2.6862915 13.3137085,0 10,0 C6.6862915,0 4,2.6862915 4,6 C4,9.3137085 6.6862915,12 10,12 Z M10,9 C11.6568542,9 13,7.65685425 13,6 C13,4.34314575 11.6568542,3 10,3 C8.34314575,3 7,4.34314575 7,6 C7,7.65685425 8.34314575,9 10,9 Z M14,11.7453107 L14,20 L10,16 L6,20 L6,11.7453107 C7.13383348,12.5361852 8.51275186,13 10,13 C11.4872481,13 12.8661665,12.5361852 14,11.7453107 L14,11.7453107 Z"></path>
      </g>
    </g> 
  </svg>-->
  <svg v-else-if="name === 'clock'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM11 6C11 5.44772 10.5523 5 10 5C9.44772 5 9 5.44772 9 6V10C9 10.2652 9.10536 10.5196 9.29289 10.7071L12.1213 13.5355C12.5118 13.9261 13.145 13.9261 13.5355 13.5355C13.9261 13.145 13.9261 12.5118 13.5355 12.1213L11 9.58579V6Z"
    />
  </svg>

  <svg v-else-if="name === 'backspace'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
    <g stroke-width="1" fill-rule="evenodd">
      <g id="icon-shape">
        <path
          d="M14.4142136,10 L16.5355339,7.87867966 L15.1213203,6.46446609 L13,8.58578644 L10.8786797,6.46446609 L9.46446609,7.87867966 L11.5857864,10 L9.46446609,12.1213203 L10.8786797,13.5355339 L13,11.4142136 L15.1213203,13.5355339 L16.5355339,12.1213203 L14.4142136,10 Z M0,10 L7,3 L20,3 L20,17 L7,17 L0,10 Z"
        ></path>
      </g>
    </g>
  </svg>
  <svg v-else-if="name === 'edit'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
    <g stroke-width="1" fill-rule="evenodd">
      <g id="icon-shape">
        <path
          d="M12.2928932,3.70710678 L0,16 L0,20 L4,20 L16.2928932,7.70710678 L12.2928932,3.70710678 Z M13.7071068,2.29289322 L16,0 L20,4 L17.7071068,6.29289322 L13.7071068,2.29289322 Z"
          id="Combined-Shape"
        ></path>
      </g>
    </g>
  </svg>
  <svg v-else-if="name === 'announcement'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
    <g stroke-width="1" fill-rule="evenodd">
      <g id="icon-shape">
        <path
          d="M3,6 L3,5.99810135 C3,4.89458045 3.89706013,4 5.00585866,4 L13,4 L17,0 L19,0 L19,16 L17,16 L13,12 L5.00585866,12 C3.89805351,12 3,11.1132936 3,10.0018986 L3,10 L1,10 L1,6 L3,6 Z M11,15 L13,15 L13,13 L5,13 L5,15 L6.33333333,15 L8,20 L11,20 L11,15 Z"
          id="Combined-Shape"
        ></path>
      </g>
    </g>
  </svg>
  <svg v-else-if="name === 'device'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
    <g stroke-width="1" fill-rule="evenodd">
      <g id="icon-shape">
        <path
          d="M17,6 L17,1.99079514 C17,0.898212381 16.1072288,0 15.0059397,0 L2.99406028,0 C1.89451376,0 1,0.891309342 1,1.99079514 L1,18.0092049 C1,19.1017876 1.8927712,20 2.99406028,20 L11.5029699,20 L8,20 L8,16 L3,16 L3,2 L15,2 L15,5 L17,5 L17,6 C18.1047419,6 19,6.89706013 19,8.00585866 L19,17.9941413 C19,19.1019465 18.1125667,20 17.000385,20 L10.999615,20 C9.89525812,20 9,19.1029399 9,17.9941413 L9,8.00585866 C9,6.89805351 9.88743329,6 10.999615,6 L17,6 Z M11,8 L17,8 L17,16 L11,16 L11,8 Z M14,19 C14.5522847,19 15,18.5522847 15,18 C15,17.4477153 14.5522847,17 14,17 C13.4477153,17 13,17.4477153 13,18 C13,18.5522847 13.4477153,19 14,19 Z"
          id="Combined-Shape"
        ></path>
      </g>
    </g>
  </svg>
  <svg v-else-if="name === 'invoice'" xmlns="http://www.w3.org/2000/svg" viewBox="2 2 20 20">
    <g stroke-width="1" fill-rule="evenodd">
      <g id="icon-shape">
        <path
          d="M3,22L4.5,20.5L6,22L7.5,20.5L9,22L10.5,20.5L12,22L13.5,20.5L15,22L16.5,20.5L18,22L19.5,20.5L21,22V2L19.5,3.5L18,2L16.5,3.5L15,2L13.5,3.5L12,2L10.5,3.5L9,2L7.5,3.5L6,2L4.5,3.5L3,2M18,9H6V7H18M18,13H6V11H18M18,17H6V15H18V17Z"
          id="Combined-Shape"
        ></path>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true
    }
  }
};
</script>
