<template>
  <div class="max-w-3xl">
    <div class="mb-8 flex flex-col justify-start">
      <h2 class="font-bold text-2xl">
        Запрос дополнительной информации.
      </h2>
    </div>
    <StyledForm
      :class="{ error: !!Object.keys(formErrors).length }"
      :errors="formErrors.base"
      :loading="form.processing"
      @submit="submit"
    >
      <template v-slot:formBody>
        <ul>
          <li v-for="(doc, idx) in validDocuments" :key="idx">
            <CheckboxInput :native-value="doc" v-model="form.request.selected_documents">
              {{ doc.name }}
            </CheckboxInput>
          </li>
        </ul>

        <TextareaInput
          class="mt-6"
          v-model="form.request.message"
          placeholder="Рекомендуется указать причину, по которой имеющиеся фотографии не подходят."
        />

        <ul class="text-xs text-blue-300 mt-4">
          <li v-for="(comment, idx) in commentVariants" :key="idx">
            <button
              class="text-indigo-600 hover:underline mb-2 text-left text-xs"
              tabindex="-1"
              type="button"
              @click="() => addToMessage(comment)"
            >
              {{ comment }}
            </button>
          </li>
        </ul>
      </template>
    </StyledForm>
  </div>
</template>

<script type="text/javascript">
import TextareaInput from "@/Shared/TextareaInput.vue";
import CheckboxInput from "@/Shared/CheckboxInput.vue";
import StyledForm from "@/Shared/StyledForm.vue";

export default {
  components: { TextareaInput, CheckboxInput, StyledForm },
  props: ["ticketId"],
  emits: ["close"],
  data() {
    return {
      form: this.$inertia.form({
        request: {
          selected_documents: [],
          message: ""
        }
      }),
      documents: [
        {
          value: 1,
          name: "Подписанное заявление и приложения к нему с вашими подписями.",
          commentVariants: [
            "Отсутствует заявление, декларация и согласие на обработку данных",
            "В декларации согласия с условиями обслуживания необходимо написать \
            «Ознакомлен(-а) и согласен(-на)» и поставить подпись с расшифровкой",
            "Отсутствует один или несколько параметров подписи на страницах заявления \
            (номер карты клиента, дата заявления, подпись заявителя).",
            "В декларации и пункте 2.4 заявления необходимо оставить только серию паспорта.",
            "Отсутствует подпись заявителя с расшифровкой в пункте 10 на третьей странице заявления."
          ]
        },
        {
          value: 2,
          name: "Ваш паспорт с закрытым серийным номером.",
          commentVariants: []
        },
        {
          value: 3,
          name: "Кассовый чек (или чеки).",
          commentVariants: []
        },
        {
          value: 4,
          name: "Устройство с лицевой стороны.",
          commentVariants: []
        },
        {
          value: 5,
          name: "Устройство с обратной стороны.",
          commentVariants: []
        },
        {
          value: 6,
          name: "Видимый идентификационный номером устройства.",
          commentVariants: []
        },
        {
          value: 7,
          name: "Фотография оригинальной упаковки с видимым штрих-кодом.",
          commentVariants: []
        },
        {
          value: 101,
          name: "Постановление о возбуждении уголовного дела",
          commentVariants: []
        }
      ]
    };
  },
  computed: {
    validDocuments() {
      if (this.$attrs.ticket.damage_id === 3) {
        return this.documents;
      } else {
        return this.documents.filter(r => r.value < 100 || r.value > 200);
      }
    },
    commentVariants() {
      const collectiveVariants = [
        "Загруженные файлы не соответствуют требованиям к качеству (разрешение, резкость). \
        Пожалуйста, подготовьте и загрузите документы надлежащего качества"
      ];

      const variants =
        this.form?.request?.selected_documents.reduce(
          (acc, doc) => acc.concat(doc.commentVariants),
          []
        ) || [];

      return this.form?.request?.selected_documents?.length
        ? variants.concat(collectiveVariants)
        : variants;
    },
    formErrors() {
      return this.form?.errors || {};
    }
  },
  methods: {
    addToMessage(comment) {
      this.form.request.message = this.form.request.message.trim();
      if (this.form.request.message) {
        this.form.request.message += "\n";
      }
      this.form.request.message += comment;
    },
    submit() {
      this.form
        .transform(data => ({
          ...data,
          request: {
            message: data.request.message,
            documents: data.request.selected_documents.map(doc => doc.name)
          }
        }))
        .post(this.$routes.request_documents_ticket(this.ticketId), {
          preserveState: true,
          preserveScroll: true,
          onSuccess: () => this.$emit("close")
        });
    }
  },
  mounted() {
    this.$el.scrollIntoView({ behavior: "smooth" });
  }
};
</script>
