<template>
  <div>
    <div class="mb-8 flex justify-between max-w-4xl">
      <h1 class="font-bold text-3xl">
        <InertiaLink class="text-indigo-500 hover:text-indigo-600" :href="$routes.draft_appeals()">
          Черновики
        </InertiaLink>
        <span class="text-indigo-400 font-medium">/</span>
        {{ appeal.id }}
      </h1>
    </div>

    <div class="flex">
      <div style="width: 100%">
        <div class="mb-15 max-w-4xl bg-white shadow flex-grow overflow-hidden sm:rounded-lg">
          <div class="px-4 py-5 border-b border-gray-200 sm:px-6 flex justify-between items-center">
            <div>
              <p class="max-w-2xl leading-5 text-gray-500">
                <span class="font-medium capitalize text-orange-700">
                  {{ appealForm }}
                </span>
                от {{ new Date(appeal.created_at).toLocaleString("ru") }}
              </p>
              <p class="max-w-2xl leading-5 text-gray-500">
                Последнее обновление {{ new Date(appeal.created_at).toLocaleString("ru") }}
              </p>
            </div>

            <span
              class="bg-red-200 rounded-md px-4 py-2 text-red-800 text-lg leading-4 uppercase font-semibold tracking-wide"
            >
              Шаг {{ appeal.step }} из {{ appeal.last_step }}
            </span>
          </div>

          <div class="px-4 py-5 sm:px-6 leading-5">
            <div class="grid gap-4 grid-cols-2">
              <div>
                <p class="font-semibold text-gray-900">
                  <span v-if="appeal.sn">{{ appeal.sn }}</span>
                  <span v-if="appeal.sn && appeal.imei">
                    /
                  </span>
                  <span v-if="appeal.imei">{{ appeal.imei }}</span>
                </p>

                <p class="mt-1 text-gray-500">
                  {{ smartDescription }}
                </p>

                <p class="mt-4">
                  <span class="text-gray-900">{{ appeal.damage_type }}</span>
                  <br />
                  <span class="text-gray-500 text-sm">{{ appeal.issue }}</span>
                </p>
              </div>

              <div>
                <p class="font-semibold text-gray-900">
                  {{ appeal.fio }}
                </p>

                <p class="mt-1 text-gray-500">
                  {{ appeal.email }}
                </p>

                <div class="mt-1 text-gray-500">
                  <p>
                    <CopyToClipboard
                      v-if="appeal.phone1"
                      :value="appeal.phone1.replace(/[^0-9]/g, '')"
                    >
                      {{ appeal.phone1 }}
                    </CopyToClipboard>
                  </p>
                  <p>
                    <CopyToClipboard
                      v-if="appeal.phone2"
                      :value="appeal.phone2.replace(/[^0-9]/g, '')"
                    >
                      {{ appeal.phone2 }}
                    </CopyToClipboard>
                  </p>
                </div>

                <p class="mt-4">
                  <span class="text-gray-900">{{ appeal.city }}</span>
                  <br />
                  <span class="text-gray-500 text-sm">{{ appeal.address }}</span>
                </p>
              </div>
            </div>

            <div class="mt-8">
              <p class="font-semibold text-gray-900">
                {{ appeal.card_number }}
              </p>

              <p class="mt-1 text-gray-500">
                {{ appeal.receipt_number }}
              </p>

              <p class="mt-1 flex text-gray-500">
                {{ appeal.card_sold_date }}
                <span v-if="appeal.card_sold_date && appeal.salesman">,&nbsp;</span>
                {{ appeal.salesman }}
              </p>
            </div>
          </div>

          <div class="px-4 py-4 sm:px-6 bg-gray-100 border-t border-gray-200 flex items-start">
            <div class="flex flex-col">
              <a
                v-if="isIlya && appeal.client_form_link"
                class="text-indigo-600 hover:underline mb-2 text-left"
                tabindex="-1"
                :href="appeal.client_form_link"
                target="_blank"
                rel="noopener noreferrer"
              >
                Открыть форму клиента
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Layout from "@/Layouts/Full.vue";
import CopyToClipboard from "@/Shared/CopyToClipboard.vue";

export default {
  metaInfo() {
    return {
      title: `${this.appeal.ref}`
    };
  },
  components: { CopyToClipboard },
  layout: Layout,
  props: {
    appeal: {
      type: Object,
      required: true
    }
  },
  computed: {
    appealForm() {
      let result = "";
      switch (this.appeal.form) {
        case "premium":
          result = "Премиум";
          break;
        case "leasing":
          result = "Лизинг";
          break;
        case "poscredit":
          result = "РиК";
          break;
        default:
          result = "Стандарт";
      }
      return result;
    },
    smartDescription() {
      return this.appeal.contract?.device_description || this.appeal?.brand?.name;
    },
    isIlya() {
      return this.$inertia.page.props.auth.user.id === 1;
    }
  }
};
</script>
