<template>
  <div>
    <div>
      <a class="font-medium text-indigo-500" @click="expanded = !expanded">
        Записи разговоров
        <span
          class="bg-gray-200 text-indigo-800 text-xs px-3 py-1 rounded-full uppercase font-semibold tracking-wide"
        >
          {{ phoneCalls.length }}
        </span>
      </a>
    </div>

    <table v-if="expanded" class="mt-4 text-sm">
      <tr v-for="call in phoneCalls" :key="call.id">
        <td class="px-4 py-1.5">
          {{ new Date(call.started_at).toLocaleString("ru", { timeZone: "Europe/Moscow" }) }}
        </td>
        <td class="px-4 py-1.5 pr-7">
          {{ formatDirection(call.direction) }}
        </td>
        <td>
          <audio :src="call.recording_url" type="audio/mpeg" controls class="h-5"></audio>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    phoneCalls: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      expanded: false
    };
  },
  methods: {
    formatDirection(direction) {
      return direction === "in" ? "входящий" : "исходящий";
    }
  }
};
</script>

<style scoped></style>
