<template>
  <div>
    <h1 class="mb-8 font-bold text-3xl">
      Обращения
    </h1>
    <b-table
      :data="data"
      :loading="loading"
      paginated
      backend-pagination
      :total="total"
      :per-page="perPage"
      @page-change="onPageChange"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
      backend-sorting
      :default-sort-direction="defaultSortOrder"
      :default-sort="[sortField, sortOrder]"
      @sort="onSort"
      :columns="columns">

    </b-table>
  </div>
</template>

<script>
import Layout from '@/Layouts/Full.vue'
import axios from 'axios'

export default {
  metaInfo: { title: 'Reports' },
  layout: Layout,
  data() {
    return {
      data: [],
      total: 0,
      loading: false,
      sortField: 'vote_count',
      sortOrder: 'desc',
      defaultSortOrder: 'desc',
      page: 1,
      perPage: 20,
      columns: [
        {
            field: 'id',
            label: 'ID',
            width: '40',
            numeric: true
        },
        {
            field: 'fio',
            label: 'First Name',
        },
        {
            field: 'phone1',
            label: 'Last Name',
        },
        {
            field: 'card_number',
            label: 'Date',
            centered: true
        }
      ]
    }
  },
  methods: {
    async loadAsyncData() {
      const params = [
          `sort_by=${this.sortField}.${this.sortOrder}`,
          `page=${this.page}`
      ].join('&')
      this.loading = true
      const { data } = await axios.get(`/tickets.json?${params}`)
      console.log(data)
      this.data = data
      this.loading = false
    },
    onPageChange(page) {
      this.page = page
      this.loadAsyncData()
    },
  },
  mounted() {
    this.loadAsyncData()
  }
}
</script>
