<template>
    <thead>
      <tr class="border-b border-gray-200">
        <th v-if="firstIsSlot" class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider align-bottom">
          <slot />
        </th>
        <th
          v-for="column in columns"
          :key="column.field"
          class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider align-bottom"
        >
          <a v-if="column.sortable" href='#' @click.prevent="sortByField(column.field)" class="flex items-center" :class="column.class">
            {{ column.label }}
            <span v-if="$parent.form.sort_by && $parent.form.sort_by.split(' ')[0] === column.field" class="pl-1">
                <svg width="12" fill="black" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <g stroke="none" stroke-width="1" fill="black" fill-rule="evenodd">
                  <path v-if="$parent.form.sort_by.split(' ')[1] === 'asc' " d="M13,10 L13,2 L7,2 L7,10 L2,10 L10,18 L18,10 L13,10 Z"></path>
                  <polygon v-else points="7 10 7 18 13 18 13 10 18 10 10 2 2 10 7 10"></polygon>
                </g>
              </svg>
            </span>
          </a>
          <template v-else>{{ column.label }}</template>
        </th>
      </tr>
    </thead>
</template>


<script>
export default {
  props: {
    columns: {
      type: Array,
      default: () => ([])
    },
    firstIsSlot: Boolean,
  },
  methods: {
    sortByField(field) {
        if (this.$parent.form.sort_by && this.$parent.form.sort_by.split(' ')[0] === field) {
            if (this.$parent.form.sort_by.split(' ')[1] === 'asc') {
                this.$parent.form.sort_by = `${field} desc`
            } else {
                 this.$parent.form.sort_by = `${field} asc`
            }

        } else {
            this.$parent.form.sort_by = `${field} desc`
        }
    },
  }
}
</script>
