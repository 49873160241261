<template>
  <div class="chart" :class="colorClass">
    <svg style="width:0; height:0; position:absolute;" aria-hidden="true" focusable="false">
      <defs>
        <linearGradient id="curve-aquamarine" x1="1" x2="1" y1="0" y2="1">
          <stop offset="0%" stop-color="rgb(62, 170, 175)" />
          <stop offset="100%" stop-color="rgb(62, 170, 175)" stop-opacity="0.1" />
        </linearGradient>
      </defs>
    </svg>

    <TrendChart
      v-if="datasets.length"
      :id="chartId"
      :datasets="datasets"
      :interactive="interactive"
      :min="0"
      @mouse-move="selectPoint"
    />
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      required: true
    },
    point: Object,
    chartId: String,
    smooth: Boolean,
    fill: Boolean,
    interactive: Boolean,
    showPoints: Boolean,
    colorClass: {
      type: String,
      default: "curve-aquamarine"
    }
  },
  emits: ["update:point"],
  data() {
    return {
      datasets: [
        {
          data: this.data,
          smooth: this.smooth,
          fill: this.fill,
          showPoints: this.showPoints
        }
      ]
    };
  },
  methods: {
    selectPoint(params) {
      this.$emit("update:point", params?.data?.[0] || {});
    }
  }
};
</script>

<style>
.chart {
  height: 3rem;
}
.chart svg {
  border-radius: 0 0 24px 24px;
}
.chart .stroke {
  stroke-width: 2;
}
.chart .curve .fill {
  fill-opacity: 0.5;
}
.chart .point {
  stroke-width: 0;
  fill: transparent;
  stroke: transparent;
  transition: all 0.2s;
}
.chart .point.is-active {
  stroke-width: 6;
}
.chart .active-line {
  stroke: transparent;
}

.curve-aquamarine {
  --curve-color: rgb(62, 170, 175);
}
.chart.curve-aquamarine .stroke {
  stroke: var(--curve-color);
}
.chart.curve-aquamarine .curve .fill {
  fill: url(#curve-aquamarine);
}
.chart.curve-aquamarine .point.is-active {
  fill: var(--curve-color);
  stroke: var(--curve-color);
}
</style>
