<template>
  <div>
    <div class="mb-8 flex justify-start max-w-3xl">
      <h1 class="font-bold text-3xl">
        <InertiaLink class="text-indigo-500 hover:text-indigo-600" :href="$routes.tickets()">
          Обращения
        </InertiaLink>
        <span class="text-indigo-400 font-medium">/</span>
        {{ formData.id }}
      </h1>
    </div>

    <div class="flex flex-row">
      <div class="bg-white rounded shadow overflow-hidden max-w-3xl">
        <Card v-if="ticket.card" :card="ticket.card" class="p-8 -mr-6 -mb-8" />
        <TicketForm v-model:formData="formData" :brands="brands" @submit="submit">
          <div
            v-if="can.edit_ticket"
            class="px-8 py-4 bg-gray-100 border-t border-gray-200 flex items-start"
          >
            <LoadingButton :loading="formData.processing" class="btn-indigo ml-auto" type="submit">
              Сохранить
            </LoadingButton>
          </div>
        </TicketForm>
      </div>

      <div class="overflow-hidden w-auto ml-8 p-8">
        <ul>
          <li v-for="e in ticket.events" :key="e.created_at" class="mb-4 text-gray-700">
            <div class="text-gray-500 text-xs mb-1">
              {{ new Date(e.created_at).toLocaleString("ru") }}
            </div>
            <span v-if="e.user.id === $page.props.auth.user.id" class="font-medium text-gray-800"
              >Я</span
            >
            <span v-else class="font-medium text-gray-800"
              >{{ e.user.first_name }} {{ e.user.last_name }}</span
            >
            {{ e.description }}
            <pre class="text-xs">{{ e.data }}</pre>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Layout from "@/Layouts/Full.vue";
import LoadingButton from "@/Shared/LoadingButton.vue";
import TicketForm from "./Form.vue";
import Card from "@/Shared/Card.vue";

export default {
  metaInfo() {
    return {
      title: `${this.ticket.ref}`
    };
  },
  layout: Layout,
  components: {
    LoadingButton,
    TicketForm,
    Card
  },
  props: {
    ticket: {
      type: Object,
      required: true
    },
    brands: {
      type: Array,
      required: true
    },
    cards: {
      type: Array,
      default: null
    },
    can: {
      type: Object,
      required: true
    }
  },
  remember: "formData",
  data() {
    return {
      event: null,
      formData: this.$inertia.form({
        ticket: this.ticket
      })
    };
  },
  methods: {
    submit() {
      this.formData.put(this.$routes.ticket(this.ticket.id));
    }
  }
};
</script>
