<template>
  <div class="max-w-3xl">
    <div class="mb-8 flex flex-col justify-start">
      <h2 class="font-bold text-2xl">
        Вызов курьера для доставки устройства в офис.
      </h2>
    </div>

    <StyledForm
      :class="{ error: !!Object.keys(formErrors).length }"
      :errors="formErrors.base"
      :loading="form.processing"
      @submit="submit"
    >
      <template v-slot:formBody>
        <SelectInput
          v-model="form.request.carrier"
          class="pr-6 pb-4 w-full lg:w-1/2"
          label="Транспортная компания"
        >
          <option value="cse_ru">
            cse.ru
          </option>
        </SelectInput>

        <TextInput
          class="lg:w-1/2"
          :errors="formErrors.tracking_number"
          label="Номер отправления"
          v-model="form.request.tracking_number"
        />
      </template>
    </StyledForm>
  </div>
</template>

<script type="text/javascript">
import TextInput from "@/Shared/TextInput.vue";
import SelectInput from "@/Shared/SelectInput.vue";
import StyledForm from "@/Shared/StyledForm.vue";

export default {
  components: {
    TextInput,
    SelectInput,
    StyledForm,
  },
  props: ["ticketId"],
  emits: ["close"],
  data() {
    return {
      form: this.$inertia.form({
        request: {
          carrier: 'cse_ru',
          tracking_number: '',
        }
      })
    };
  },
  computed: {
    validDocuments() {
      if (this.$attrs.ticket.damage_id === 3) {
        return this.documents
      } else {
        return this.documents.filter(r => r.value < 100 || r.value > 200)
      }
    },
    formErrors() {
      return this.form?.errors || { };
    }
  },
  methods: {
    submit() {
      this.form.post(this.$routes.request_pickup_ticket(this.ticketId), {
        preserveState: true,
        preserveScroll: true,
        onSuccess: () => this.$emit("close"),
      });
    }
  },
  mounted() {
    this.$el.scrollIntoView({ behavior: "smooth" });
  },
};
</script>
