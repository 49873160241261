<template>
  <form @submit.prevent="$emit('submit')" autocomplete="off">
    <div class="p-8 -mr-6 -mb-8 flex flex-wrap">
      <TextInput
        label="Имя"
        class="pr-6 pb-8 w-full lg:w-1/2"
        v-model="form.user.first_name"
        :errors="form.errors.first_name"
        autocomplete="none1"
        @input="() => delete form.errors.first_name"
      />
      <TextInput
        label="Фамилия"
        class="pr-6 pb-8 w-full lg:w-1/2"
        v-model="form.user.last_name"
        :errors="form.errors.last_name"
        autocomplete="none2"
        @input="() => delete form.errors.last_name"
      />
      <TextInput
        label="Email"
        class="pr-6 pb-8 w-full lg:w-1/2"
        v-model="form.user.email"
        :errors="form.errors.email"
        autocomplete="none3"
        @input="() => delete form.errors.email"
      />
      <TextInput
        label="Password"
        class="pr-6 pb-8 w-full lg:w-1/2"
        v-model="form.user.password"
        type="password"
        autocomplete="new-password"
        :errors="form.errors.password"
        @input="() => delete form.errors.password"
      />
      <SelectInput
        v-if="$page.props.auth.user.admin"
        label="Admin"
        class="pr-6 pb-8 w-full lg:w-1/2"
        v-model="form.user.admin"
        :errors="form.errors.admin"
      >
        <option :value="true">Да</option>
        <option :value="false">Нет</option>
      </SelectInput>
    </div>
    <slot />
  </form>
</template>

<script>
import SelectInput from "@/Shared/SelectInput.vue";
import TextInput from "@/Shared/TextInput.vue";

export default {
  components: {
    SelectInput,
    TextInput
  },
  props: {
    modelValue: {
      type: Object,
      required: true
    }
  },
  emits: ["update:modelValue", "submit"],
  computed: {
    form: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit("update:modelValue", val);
      }
    }
  }
};
</script>
