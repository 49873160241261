<template>
  <div
    class="px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6"
  >
    <div class="flex-1 flex justify-between sm:hidden">
      <InertiaLink
        :href="links[0].url || ''"
        class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150"
      >
        <svg class="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
          <path
            fill-rule="evenodd"
            d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
            clip-rule="evenodd"
          />
        </svg>
      </InertiaLink>
      <InertiaLink
        :href="links[links.length - 1].url || ''"
        class="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150"
      >
        <svg class="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
          <path
            fill-rule="evenodd"
            d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
            clip-rule="evenodd"
          />
        </svg>
      </InertiaLink>
    </div>
    <div class="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
      <div>
        <p class="text-sm leading-5 text-gray-700">
          Showing
          <template v-if="meta.count > 0 && meta.count <= perPage"> 
            <span class="font-medium">{{ meta.count }}</span>
          </template>
          <template v-else-if="meta.count > 0"> 
            <span class="font-medium">{{ (meta.page - 1) * perPage + 1 }}</span>
            to
            <span v-if="meta.next" class="font-medium">
              {{ (meta.page - 1) * perPage + perPage }}
            </span>
            <span v-else class="font-medium">{{ meta.count }}</span>
          </template>
          <template v-else>
            <span class="font-medium">0</span>
          </template>
          of
          <span class="font-medium">{{ meta.count }}</span>
          results
        </p>
      </div>
      <div v-if="meta.count > perPage">
        <span class="relative z-0 inline-flex shadow-sm">
          <InertiaLink
            :href="links[0].url || ''"
            class="item relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-500 hover:text-gray-400 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-500 transition ease-in-out duration-150"
          >
            <svg class="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
              <path
                fill-rule="evenodd"
                d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                clip-rule="evenodd"
              />
            </svg>
          </InertiaLink>

          <template v-for="link in links.slice(1, -1)">
            <span
              v-if="link.label === 'gap'"
              :key="link.label"
              class="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-700"
            >
              ...
            </span>
            <!--  <button v-else="link.url === null"
            type="button" class="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150">
            {{ link.label }}
          </button> -->
            <InertiaLink
              v-else
              :key="link.label"
              class="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm leading-5 font-medium focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150"
              :class="{
                'bg-indigo-600': link.active,
                'text-gray-100': link.active,
                'text-gray-700': !link.active,
                'hover:text-gray-500': !link.active,
                'hover:text-gray-300': link.active
              }"
              :href="link.url"
              preserve-state
              preserve-scroll
            >
              {{ link.label }}
            </InertiaLink>
          </template>

          <InertiaLink
            :href="links[links.length - 1].url || ''"
            class="item -ml-px relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-500 hover:text-gray-400 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-500 transition ease-in-out duration-150"
          >
            <svg class="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
              <path
                fill-rule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clip-rule="evenodd"
              />
            </svg>
          </InertiaLink>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    meta: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      perPage: 10,
    };
  },
  computed: {
    links() {
      return [
        {
          label: "Previous",
          url: this.url(this.meta.prev)
        },
        ...this.meta.sequels["0"].map(page => {
          return {
            label: page,
            url: this.url(page),
            active: this.active(page)
          };
        }),
        {
          label: "Next",
          url: this.url(this.meta.next)
        }
      ];
    }
  },

  methods: {
    url(pageNumber) {
      return pageNumber
        ? this.meta.scaffold_url.replace(/__pagy_page__/, pageNumber)
        : null;
    },
    active(pageNumber) {
      return this.meta.page == pageNumber;
    }
  }
};
</script>

<style>
a.item[href=""] {
  opacity: 0.5;
}
</style>
