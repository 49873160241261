<template>
  <div>
    <h1 class="mb-8 font-bold text-3xl">
      Устройства
    </h1>
    <div class="mb-6 flex justify-between items-center">
      <SearchFilter v-model="form.search" class="w-full max-w-md mr-4" @reset="reset">
        <SelectInput label="Тип" v-model="form.form">
          <option :value="null">Любой</option>
          <option value="basic">Стандарт</option>
          <option value="premium">Премиум</option>
          <option value="leasing">Лизинг</option>
          <option value="poscredit">РиК</option>
        </SelectInput>
      </SearchFilter>
    </div>

    <div class="flex flex-col">
      <div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
        <div
          class="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200"
        >
          <table class="min-w-full">
            <TableHeader
              :columns="[
                {
                  field: 'id',
                  label: '#',
                  sortable: true
                },
                {
                  field: 'sn',
                  label: 'SN'
                },
                {
                  field: 'imei',
                  label: 'IMEI'
                },
                {
                  field: 'name',
                  label: 'Название'
                },
                {
                  field: 'aasm_state',
                  label: 'Статус',
                  sortable: true
                },
                {
                  field: 'created_at',
                  label: 'Дата',
                  sortable: true
                }
              ]"
            />
            <tbody class="bg-white">
              <tr
                v-for="(device, idx) in devices.data"
                :key="device.id"
                class="hover:bg-gray-50 focus-within:bg-gray-50 border-gray-200"
                :class="{ 'border-b': idx != devices.data.length - 1 }"
              >
                <td
                  class="flex items-center px-6 py-4 whitespace-nowrap text-sm leading-5 text-gray-500 text-right"
                >
                  <InertiaLink
                    class="font-medium text-indigo-600 hover:text-indigo-900 focus:outline-none focus:underline"
                    :href="$routes.device(device.id)"
                    aria-label="Show"
                  >
                    {{ device.id }}
                  </InertiaLink>
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm leading-5 text-gray-700">
                  {{ device.sn }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                  {{ device.imei }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                  {{ device.name }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm leading-5 text-gray-500 text-center">
                  <span
                    class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium leading-4 bg-gray-100 text-gray-800"
                  >
                    {{ device.aasm_state }}
                  </span>
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                  {{
                    new Date(device.created_at).toLocaleString("ru", { timeZone: "Europe/Moscow" })
                  }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <pagination :meta="devices.meta" />
  </div>
</template>

<script>
import Icon from "@/Shared/Icon.vue";
import Layout from "@/Layouts/Full.vue";
import mapValues from "lodash/mapValues";
import Pagination from "@/Shared/NewPagination.vue";
import pickBy from "lodash/pickBy";
import SearchFilter from "@/Shared/SearchFilter.vue";
import throttle from "lodash/throttle";
import TableHeader from "@/Shared/TableHeader.vue";

export default {
  metaInfo: { title: "Устройства" },
  layout: Layout,
  components: {
    Icon,
    Pagination,
    SearchFilter,
    TableHeader
  },
  props: {
    devices: {
      type: Object,
      required: true
    },
    filters: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      columns: [
        {
          field: "id",
          label: "ID",
          width: "40",
          numeric: true
        },
        {
          field: "fio",
          label: "First Name"
        },
        {
          field: "phone1",
          label: "Last Name"
        },
        {
          field: "card_number",
          label: "Date",
          centered: true
        }
      ],
      form: {
        search: this.filters.search,
        form: this.filters.form,
        sort_by: this.filters.sort_by
      }
    };
  },
  watch: {
    form: {
      handler: throttle(function() {
        let query = pickBy(this.form);
        this.$inertia.replace(
          this.$routes.devices(Object.keys(query).length ? query : { remember: "forget" }),
          {
            preserveState: true,
            preserveScroll: true,
            only: ["devices"]
          }
        );
      }, 150),
      deep: true
    }
  },
  methods: {
    reset() {
      this.form = mapValues(this.form, () => null);
    },
    inertiaVisit(pageNumber) {
      console.log(`Go to page ${pageNumber}`);
      const newUrl = this.devices.meta.scaffold_url.replace(/__pagy_page__/, pageNumber);
      this.$inertia.visit(newUrl);
    }
  }
};
</script>
