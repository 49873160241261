<template>
  <div>
    <div class="mb-8 flex justify-start max-w-3xl">
      <h1 class="font-bold text-3xl">
        <InertiaLink class="text-indigo-500 hover:text-indigo-600" :href="$routes.devices()">
          Устройства
        </InertiaLink>
        <span class="text-indigo-400 font-medium">/</span>
        {{ device.id }}
      </h1>
    </div>

    <div class="flex">
      <div style="width: 100%">
        <div class="mb-15 max-w-4xl bg-white shadow flex-grow overflow-hidden sm:rounded-lg">
          <div class="px-4 py-5 border-b border-gray-200 sm:px-6 flex justify-between">
            <div>
              <h2 class="text-2xl leading-6 font-medium text-gray-900 flex mb-4">
                # {{ device.id }} – {{ brand.name }} {{ device.name }}
              </h2>

              <p v-if="device.sn" class="mt-1 max-w-2xl text-sm leading-5 text-gray-500">
                <span v-if="device.sn">SN: {{ device.sn }}</span>
                <span v-if="device.sn && device.imei">   </span>
                <span v-if="device.imei">IMEI: {{ device.imei }}</span>
              </p>              
            </div>

            <span class="self-start bg-red-200 rounded-full px-3 py-1 text-red-800 text-lg leading-4 uppercase font-semibold tracking-wide">
              {{ device.aasm_state }}
            </span>
          </div>

          <div class="px-4 py-5 sm:px-6">
            <p v-if="ticket && ticket.created_at" class="mt-2 max-w-2xl text-gray-500">
              Из обращения:
              <InertiaLink
                :href="$routes.ticket(ticket.id)"
                class="text-indigo-600 mb-2 hover:underline"
              >
                #{{ ticket.id }} от {{ new Date(ticket.created_at).toLocaleString("ru") }}
              </InertiaLink>
            </p>
            <p v-if="contract && contract.created_at" class="mt-2 max-w-2xl text-gray-500">
              По договору:
              <InertiaLink
                :href="$routes.contract(contract.id)"
                class="text-indigo-600 mb-2 hover:underline"
              >
                #{{ contract.id }} от {{ new Date(contract.created_at).toLocaleString("ru") }}
              </InertiaLink>
            </p>

            <template v-if="device.description">
              <h3 class="mt-3 text-lg leading-6 text-gray-700">
                Описание устройства:
              </h3>
              <p class="mt-2 max-w-2xl text-gray-500">
                {{ device.description }}
              </p>
            </template>

            <template v-if="device.description">
              <h3 class="mt-3 text-lg leading-6 text-gray-700">
                Описание не исправности:
              </h3>
              <p class="mt-2 max-w-2xl text-gray-500">
                {{ device.symptom }}
              </p>
            </template>
          </div>

          <div class="px-4 py-4 sm:px-6 bg-gray-100 border-t border-gray-200 flex items-start">
            <div class="flex flex-col">
              <button
                class="text-indigo-600 text-left leading-5 flex items-center hover:underline mb-2"
                :class="{'text-indigo-400 cursor-wait hover:no-underline': loading}"
                tabindex="-1"
                @click="downloadAct"
                :disabled="loading"
              >
                Скачать Акт о ремонте
                <i v-if="loading" class="btn-spinner ml-3 inline-block border-indigo-300 border-b-transparent" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Layout from '@/Layouts/Full.vue'

export default {
  metaInfo: { title: "Устройство" },
  layout: Layout,
  props: {
    device: {
      type: Object,
      required: true,
    },
    brand: Object,
    ticket: Object,
    contract: Object,
    device_data: Object,
    currentEvent: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      loading: false,
    }
  },
  methods: {
    async downloadAct() {
      try {
        this.loading = true;
        const res = await fetch(`/devices/${this.device.id}/download_act`);

        if (!res.ok) throw await res.json();

        const blob = await res.blob();
        const pdfFile = new File([blob], 'Акт о ремонте.pdf', { type: 'application/pdf' });
        
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(pdfFile);
        link.download = pdfFile.name;
        link.click();
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
  }
}
</script>