<template>
  <div class="p-6 bg-indigo-800 min-h-screen flex justify-center items-center">
    <div class="w-full max-w-md">
      <Logo class="block mx-auto w-full max-w-xs fill-white" height="50" />
      <form class="mt-8 bg-white rounded-lg shadow-xl overflow-hidden" @submit.prevent="login">
        <div class="px-10 py-12">
          <Notify :duration="null" />

          <h1 class="text-center font-bold text-3xl">
            Welcome Back!
          </h1>
          <div class="mx-auto mt-6 w-24 border-b-2" />

          <TextInput
            v-model="form.user.email"
            class="mt-10"
            label="Email"
            type="email"
            autofocus
            autocapitalize="off"
            autocomplete="email"
          />

          <TextInput
            v-model="form.user.password"
            class="mt-6"
            label="Password"
            type="password"
            autocomplete="current-password"
          />

          <CheckboxInput class="mt-6" v-model="form.user.remember_me">
            Remember me
          </CheckboxInput>
        </div>
        <div
          class="px-10 py-4 bg-gray-100 border-t border-gray-200 flex justify-between items-center"
        >
          <a class="hover:underline" tabindex="-1" href="#reset-password" />
          <LoadingButton :loading="form.processing" class="btn-indigo" type="submit">
            Login
          </LoadingButton>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Layout from "@/Layouts/Site.vue";
import Notify from "@/Shared/Notify.vue";
import LoadingButton from "@/Shared/LoadingButton.vue";
import Logo from "@/Shared/Logo.vue";
import TextInput from "@/Shared/TextInput.vue";
import CheckboxInput from "@/Shared/CheckboxInput.vue";

export default {
  metaInfo: { title: "Login" },
  layout: Layout,
  components: {
    Notify,
    LoadingButton,
    Logo,
    TextInput,
    CheckboxInput
  },

  data() {
    return {
      form: this.$inertia.form({
        user: {
          email: "",
          password: "",
          remember_me: null
        }
      })
    };
  },
  methods: {
    login() {
      this.form.post(this.$routes.user_session(), {
        preserveScroll: true,
        preserveState: true,
        onSuccess: () => this.form.reset("password")
      });
    }
  }
};
</script>
