<template>
  <div>
    <label v-if="label" class="form-label"> {{ label }}: </label>

    <div class="form-input p-0" :class="{ error: errors.length }">
      <input ref="file" type="file" :accept="accept" class="hidden" @change="change" />
      <div v-if="!file" class="flex p-2">
        <button
          class="px-4 py-1 bg-gray-700 hover:bg-gray-700 rounded-sm text-xs font-medium text-white"
          type="button"
          @click="browse"
        >
          Browse
        </button>
      </div>
      <div v-else class="flex items-center justify-between p-2">
        <div class="flex-1 pr-1">
          {{ file.name }} <span class="text-gray-600 text-xs">({{ filesize(file.size) }})</span>
        </div>
        <button
          class="px-4 py-1 bg-gray-700 hover:bg-gray-700 rounded-sm text-xs font-medium text-white"
          type="button"
          @click="remove"
        >
          Remove
        </button>
      </div>
    </div>

    <div v-if="errors.length" class="form-error">
      {{ errors[0] }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    file: File,
    label: {
      type: String,
      default: null
    },
    accept: {
      type: String,
      default: null
    },
    errors: {
      type: Array,
      default: () => []
    }
  },
  emits: ["update:file"],
  watch: {
    file(value) {
      if (!value) {
        this.$refs.file.value = "";
      }
    }
  },
  methods: {
    filesize(size) {
      let i = Math.floor(Math.log(size) / Math.log(1024));
      return (size / Math.pow(1024, i)).toFixed(2) * 1 + " " + ["B", "kB", "MB", "GB", "TB"][i];
    },
    browse() {
      this.$refs.file.click();
    },
    change(e) {
      this.$emit("update:file", e.target.files[0]);
    },
    remove() {
      this.$emit("update:file", null);
    }
  }
};
</script>
