<template>
  <div>
    <InertiaLink
      class="flex items-center group py-3"
      :href="$routes.cards({ search: card.number })"
      aria-label="Card"
    >
      <Icon name="cards" class="w-4 h-4 mr-2 fill-indigo-600 group-hover:fill-indigo-800" />
      <div class="text-indigo-600 group-hover:fill-indigo-800">
        {{ card.number }}
      </div>
    </InertiaLink>
  </div>
</template>

<script>
import Icon from "@/Shared/Icon.vue";

export default {
  components: {
    Icon
  },
  props: {
    card: {
      type: Object,
      required: true
    }
  }
};
</script>
