<template>
  <div :class="$attrs.class">
    <label class="inline-flex mb-2">
      <input
        v-bind="{ ...$attrs, class: null }"
        type="checkbox"
        class="form-checkbox rounded"
        :class="{ error: errors.length }"
        :value="nativeValue"
        v-model="value"
        @input="input"
      />
      <span class="ml-2" :class="{ error: errors.length }">
        <slot />
      </span>
    </label>
    <div v-if="errors.length" class="form-error">
      {{ errors[0] }}
    </div>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    nativeValue: {
      type: [String, Number, Boolean, Object],
      default: true
    },
    modelValue: [String, Number, Boolean, Object],
    errors: {
      type: Array,
      default: () => []
    }
  },
  emits: ["update:modelValue", "input"],
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit("update:modelValue", val);
      }
    }
  },
  methods: {
    input(event) {
      this.$emit("input", event);
    }
  }
};
</script>
