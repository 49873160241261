<template>
  <button :disabled="disabled || loading" class="flex items-center" @click="$emit('click')">
    <slot>Сохранить</slot>
    <div v-if="loading" class="btn-loading">
      <div class="btn-spinner" />
    </div>
  </button>
</template>

<script>
export default {
  props: {
    loading: Boolean,
    disabled: Boolean
  },
  emits: ["click"]
};
</script>
