<template>
  <div>
    <div class="mb-8 flex justify-start max-w-3xl">
      <h1 class="font-bold text-3xl">
        <InertiaLink
          class="text-indigo-500 hover:text-indigo-600"
          :href="$routes.aftersales()"
        >
          Допродажи
        </InertiaLink>
        <span class="text-indigo-400 font-medium">/</span>
        {{ aftersale.id }}
      </h1>
    </div>
    <div class="bg-white shadow overflow-hidden sm:rounded-lg">
      <div
        class="px-4 py-5 border-b border-gray-200 sm:px-6 flex justify-between"
      >
        <div>
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            # {{ aftersale.id }}
          </h3>

          <p class="mt-1 max-w-2xl text-sm leading-5 text-gray-500">
            Допродажа сервисной программы «{{ aftersale.program }}» на {{ aftersale.device.toLowerCase() }} {{ aftersale.brand }}<br />
            от {{ new Date(aftersale.updated_at).toLocaleString("ru", {timeZone: "Europe/Moscow"}) }}
          </p>
        </div>
      </div>
      <div>
        <dl class="list">
          <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dd class="text-sm leading-5 font-medium text-gray-500">
              Карта
            </dd>
            <dt class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
              {{ aftersale.card_number }}
            </dt>
          </div>
          <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dd class="text-sm leading-5 font-medium text-gray-500">
              Статус
            </dd>
            <dt class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
              {{ aftersale.aasm_state }}
            </dt>
          </div>
          <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dd class="text-sm leading-5 font-medium text-gray-500">
              Программа
            </dd>
            <dt class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
              {{ aftersale.program }}
            </dt>
          </div>
          <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dd class="text-sm leading-5 font-medium text-gray-500">
              Тип устройства
            </dd>
            <dt class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
              {{ aftersale.device }}
            </dt>
          </div>
          <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dd class="text-sm leading-5 font-medium text-gray-500">
              Производитель
            </dd>
            <dt class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
              {{ aftersale.brand }}
            </dt>
          </div>
          <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dd class="text-sm leading-5 font-medium text-gray-500">
              Серийный номер / IMEI
            </dd>
            <dt class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
              {{ aftersale.sn_imei }}
            </dt>
          </div>
          <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dd class="text-sm leading-5 font-medium text-gray-500">
              Документ о приобретении
            </dd>
            <dt class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
              {{ aftersale.receipt }}
            </dt>
          </div>
          <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dd class="text-sm leading-5 font-medium text-gray-500">
              ФИО продавца
            </dd>
            <dt class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
              {{ aftersale.full_name }}
            </dt>
          </div>
          <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dd class="text-sm leading-5 font-medium text-gray-500">
              Email магазина
            </dd>
            <dt class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
              {{ aftersale.email }}
            </dt>
          </div>
          <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dd class="text-sm leading-5 font-medium text-gray-500">
              Номер телефона магазина
            </dd>
            <dt class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
              {{ aftersale.phone }}
            </dt>
          </div>
          <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dd class="text-sm leading-5 font-medium text-gray-500">
              Описание
            </dd>
            <dt class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
              {{ aftersale.comment }}
            </dt>
          </div>
        </dl>
        <div
          v-if="aftersale.attachments.length"
          class="flex items-center justify-between px-4 py-8 sm:px-6 bg-white border-t border-gray-200"
        >
          <LightGallery
            :images="aftersale.attachments"
            :opened="openedGallery"
            @rotation="saveRotationAngle"
            @closeGallery="closeGallery"
            @selectImage="selectImageGallery"
            v-slot="slotProps"
          >
            <InertiaLink
              class="font-medium text-indigo-500 cursor-pointer select-none"
              preserve-state
              @click="slotProps.openGallery"
              :data="{ gallery: 0 }"
            >
              Файлы
              <span class="bg-gray-200 px-3 py-1 rounded-full text-indigo-800 text-xs uppercase font-semibold tracking-wide">
                {{ aftersale.attachments.length }}
              </span>
            </InertiaLink>
          </LightGallery>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Layout from "@/Layouts/Full.vue";
import LightGallery from '@/Shared/LightGallery.vue';

import axios from 'axios';

export default {
  metaInfo: { title: "Допродажа" },
  layout: Layout,
  components: { LightGallery },
  props: {
    aftersale: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      openedGallery: false,
    };
  },
  methods: {
    async saveRotationAngle(rotation) {
      try {
        await axios.post(`/attachments/${rotation.image_id}/rotate`, {
          angle: rotation.angle
        });

        const attachment = this.aftersale.attachments
          .find(attachment => attachment.id === rotation.image_id);

        if (attachment) {
          attachment.rotation = rotation.angle;
        }
      } catch (error) {
        
      }
    },
    selectImageGallery(imageIdx) {
      const params = this.getUrlQuery();
      if (params.hasOwnProperty('gallery')) {
        params.gallery = imageIdx;
        this.$inertia.get(
          this.$routes.aftersale(this.aftersale.id),
          params,
          { preserveState: true },
        );
      }
    },
    closeGallery() {
      const params = this.getUrlQuery();
      if (params.hasOwnProperty('gallery')) {
        this.openedGallery = false;
        delete params.gallery;
        this.$inertia.get(
          this.$routes.aftersale(this.aftersale.id),
          params,
          { preserveState: true },
        );
      }
    },
    getUrlQuery() {
      const urlSearchParams = new URLSearchParams(window.location.search);
      return Object.fromEntries(urlSearchParams.entries());
    },
  },
  mounted() {
    const params = this.getUrlQuery();
    if (params.hasOwnProperty('gallery')) {
      this.openedGallery = Number(params.gallery);
    }
  }
};
</script>

<style scoped>
.list div:nth-of-type(2n + 1) {
  @apply bg-gray-50;
}
</style>

