<template>
  <div>
    <h1 class="mb-8 font-bold text-3xl">
      Выставленные счета
    </h1>

    <div class="mb-6 flex justify-between items-center">
      <SearchFilter v-model="form.search" class="w-full max-w-md mr-4" @reset="reset" only-search>
      </SearchFilter>
    </div>

    <div class="flex flex-col">
      <div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
        <div
          class="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200"
        >
          <table class="min-w-full">
            <TableHeader
              :columns="[
                {
                  field: 'id',
                  label: 'Счет',
                  sortable: true
                },
                {
                  field: 'created_at',
                  label: 'Дата',
                  sortable: true
                },

                {
                  field: 'payer',
                  label: 'Плательщик',
                  sortable: true
                },
                {
                  field: 'ticket',
                  label: 'Обращение',
                  sortable: true
                },
                {
                  field: 'total',
                  label: 'Сумма',
                  sortable: true,
                  class: 'justify-end'
                },
                {
                  field: 'due_date',
                  label: 'Оплатить до',
                  sortable: true,
                  class: 'justify-end'
                },
                {
                  field: 'state',
                  label: 'Статус',
                  sortable: true,
                  class: 'justify-end'
                },
                {
                  field: 'created_at',
                  label: 'Выставил',
                  sortable: true,
                  class: 'justify-end'
                }
              ]"
            />
            <tbody class="bg-white">
              <tr
                v-for="(invoice, idx) in invoices.data"
                :key="invoice.id"
                class="hover:bg-gray-50 focus-within:bg-gray-50 border-gray-200"
                :class="{ 'border-b': idx != invoices.data.length - 1 }"
              >
                <td class="px-6 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                  Д-{{ invoice.id }}
                  <span v-if="invoiceDownloading === invoice.id" class="animate-spin inline-block">
                    &#8635;
                  </span>
                  <span
                    v-else
                    :class="[
                      {
                        'text-indigo-600 hover:text-indigo-900 cursor-pointer font-black': !invoiceDownloading
                      },
                      { 'text-gray-300': invoiceDownloading && invoiceDownloading !== invoice.id }
                    ]"
                    @click="ev => downloadInvoice(invoice.id, ev)"
                  >
                    ⇩
                  </span>
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                  {{
                    new Date(invoice.created_at).toLocaleDateString("ru", {
                      timeZone: "Europe/Moscow"
                    })
                  }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                  {{ invoice.payer }}
                </td>
                <td
                  class="flex items-center px-6 py-4 whitespace-nowrap text-sm leading-5 text-gray-500 text-right"
                >
                  <InertiaLink
                    class="font-medium text-indigo-600 hover:text-indigo-900 focus:outline-none focus:underline"
                    :href="$routes.ticket(invoice.ticket_id)"
                  >
                    {{ invoice.ticket_hashid || invoice.ticket_id }}
                  </InertiaLink>
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm leading-5 text-gray-900 text-right">
                  {{ Number(invoice.total).toLocaleString("ru") }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm leading-5 text-gray-500 text-right">
                  {{
                    new Date(invoice.due_date).toLocaleDateString("ru", {
                      timeZone: "Europe/Moscow"
                    })
                  }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm leading-5 text-gray-500 text-center">
                  <span
                    class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium leading-4 bg-yellow-100 text-yellow-900"
                  >
                    {{ invoice.state }}
                  </span>
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm leading-5 text-gray-500 text-right">
                  {{ invoice.user_name }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <pagination :meta="invoices.meta" />
  </div>
</template>

<script>
import Icon from "@/Shared/Icon.vue";
import Layout from "@/Layouts/Full.vue";
import mapValues from "lodash/mapValues";
import Pagination from "@/Shared/NewPagination.vue";
import pickBy from "lodash/pickBy";
import SearchFilter from "@/Shared/SearchFilter.vue";
import throttle from "lodash/throttle";
import TableHeader from "@/Shared/TableHeader.vue";
import axios from "axios";

export default {
  metaInfo: { title: "Счета" },
  layout: Layout,
  components: {
    Icon,
    Pagination,
    SearchFilter,
    TableHeader
  },
  props: {
    invoices: {
      type: Object,
      required: true
    },
    filters: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      form: {
        search: this.filters.search,
        sort_by: this.filters.sort_by
      },
      invoiceDownloading: false
    };
  },
  watch: {
    form: {
      handler: throttle(function() {
        let query = pickBy(this.form);
        this.$inertia.replace(
          this.$routes.invoices(Object.keys(query).length ? query : { remember: "forget" }),
          {
            preserveState: true,
            preserveScroll: true,
            only: ["invoices"]
          }
        );
      }, 150),
      deep: true
    }
  },
  methods: {
    reset() {
      this.form = mapValues(this.form, () => null);
    },
    inertiaVisit(pageNumber) {
      console.log(`Go to page ${pageNumber}`);
      const newUrl = this.invoices.meta.scaffold_url.replace(/__pagy_page__/, pageNumber);
      this.$inertia.visit(newUrl);
    },
    async downloadInvoice(invoiceId, e) {
      if (this.invoiceDownloading) return;
      if (e.metaKey || e.ctrlKey) {
        window.open(`${this.$routes.print_invoice(invoiceId)}.html`);
        return;
      }

      try {
        this.invoiceDownloading = invoiceId;
        const { data: pdfData } = await axios({
          url: `${this.$routes.print_invoice(invoiceId)}.json`,
          method: "get",
          responseType: "blob",
          headers: {
            Accept: "application/json"
          }
        });

        const invoice = new File([pdfData], `Счет Д-${this.invoiceDownloading}.pdf`, {
          type: "application/pdf"
        });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(invoice);
        link.download = invoice.name;
        link.click();
      } catch (error) {
        throw error;
      } finally {
        this.invoiceDownloading = null;
      }
    }
  }
};
</script>
