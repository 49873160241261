<template>
  <div>
    <h1 class="mb-8 font-bold text-3xl">
      Данные из таблицы
    </h1>

    <div v-if="repair_acts.length" class="flex flex-col">
      <div class="-my-2 py-4 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
        <div
          class="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200"
        >
          <table class="min-w-full">
            <TableHeader :columns="tableColumns" firstIsSlot>
              <CheckboxInput
                class="mb-0 -mr-6"
                :native-value="allRowsSelected"
                @input="selectAllRow"
              />
            </TableHeader>
            <tbody class="bg-white">
              <tr
                v-for="(row, idx) in repair_acts"
                :key="idx"
                class="hover:bg-gray-50 focus-within:bg-gray-50 border-gray-200"
                :class="{ 'border-b': idx != repair_acts.length - 1 }"
              >
                <td class="px-6 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                  <CheckboxInput class="mb-0 -mr-6" v-model="selectedRows[idx]" />
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                  {{ row["фио"] }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                  {{ row["датаОбращения"] }}
                </td>
                <td
                  class="px-6 py-4 whitespace-nowrap text-sm truncate max-w-xs leading-5 text-gray-500"
                  :title="row['Устройство']"
                >
                  {{ row["Устройство"] }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                  {{ row["№ карты"] }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                  {{ row["Дата продажи карты"] }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                  {{ row["imei/serial"] }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                  {{ row["SN"] }}
                </td>
                <td
                  class="px-6 py-4 whitespace-nowrap text-sm truncate max-w-xs leading-5 text-gray-500"
                  :title="row['Устройство после замены']"
                >
                  {{ row["Устройство после замены"] }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                  {{ row["imeisnПослеЗамены"] }}
                </td>
                <td
                  class="px-6 py-4 whitespace-nowrap text-sm truncate max-w-xs leading-5 text-gray-500"
                  :title="row['комментарий']"
                >
                  {{ row["комментарий"] }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="mt-8 flex justify-end">
        <SelectInput class="w-60" v-model="selectedAct">
          <option :value="null">{{ selectedAct ? "Отменить выбор" : "Выберите тип Акта" }}</option>
          <option value="repair">Ремонт</option>
          <option value="repair_change_sn">Ремонт с заменой SN</option>
          <option value="replace">Замена</option>
          <option value="replace_to_alt">Замена на альтернативу</option>
          <option value="replace_to_bu">Замена на БУ</option>
        </SelectInput>

        <LoadingButton
          class="btn-indigo tabular-nums ml-4"
          :loading="loading"
          :disabled="!canPrint"
          @click="printActs"
        >
          Распечатать
          {{ allRowsSelected ? "все" : `выбранное (${selectedRows.filter(r => r).length})` }}
        </LoadingButton>
      </div>
    </div>
    <h2 v-else class="font-medium text-xl text-gray-500">
      Доступных актов для печати нет! Заполните соответствующую таблицу.
    </h2>
  </div>
</template>

<script>
import Icon from "@/Shared/Icon.vue";
import Layout from "@/Layouts/Full.vue";
import SearchFilter from "@/Shared/SearchFilter.vue";
import TableHeader from "@/Shared/TableHeader.vue";
import CheckboxInput from "@/Shared/CheckboxInput.vue";
import SelectInput from "@/Shared/SelectInput.vue";
import LoadingButton from "@/Shared/LoadingButton.vue";
import axios from "axios";

export default {
  metaInfo: { title: "Обращения" },
  layout: Layout,
  components: { Icon, SearchFilter, TableHeader, CheckboxInput, SelectInput, LoadingButton },
  props: {
    repair_acts: Array
  },
  data() {
    return {
      selectedRows: Array(this.repair_acts.length).fill(true),
      selectedAct: null,
      loading: false
    };
  },
  computed: {
    allRowsSelected() {
      return !this.selectedRows.some(row => !row);
    },
    tableColumns() {
      if (!this.repair_acts?.length) return [];
      return Object.keys(this.repair_acts[0]).map(key => ({ field: key, label: key }));
    },
    canPrint() {
      return this.selectedAct && this.selectedRows.some(row => row);
    }
  },
  methods: {
    selectAllRow() {
      const newValue = !this.allRowsSelected;
      this.selectedRows = this.selectedRows.map(() => newValue);
    },
    async printActs() {
      try {
        if (!this.canPrint) return;
        this.loading = true;

        const acts = await Promise.all(
          this.repair_acts.filter((_, idx) => this.selectedRows[idx]).map(row => this.loadAct(row))
        );

        acts.forEach((act, idx) => {
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(act);
          link.download = act.name;
          setTimeout(
            () => {
              link.click();
            },
            idx ? 500 + 50 * (idx - 1) : 0
          );
        });
      } catch (error) {
        console.error(error);
        this.$page.props.flash = {
          error: error.message || "Произошла непредвиденная ошибка."
        };
      } finally {
        this.loading = false;
      }
    },
    async loadAct(data) {
      try {
        const { data: pdfData } = await axios({
          url: this.$routes.download_filled_repair_act_pdf_docs(),
          method: "POST",
          responseType: "blob",
          headers: {
            Accept: "application/json"
          },
          data: {
            act_type: this.selectedAct,
            act_data: data
          }
        });

        return new File([pdfData], `Акт # ${data["№ карты"]}.pdf`, { type: "application/pdf" });
      } catch (error) {
        throw error;
      }
    }
  }
};
</script>
