<template>
  <div>
    <InertiaHead title="Обращения" />
    <h1 class="mb-8 font-bold text-3xl">
      Черновики обращений
    </h1>
    <div class="mb-6 flex justify-between items-center">
      <SearchFilter v-model="form.search" class="w-full max-w-md mr-4" @reset="reset">
        <SelectInput label="Программа" v-model="form.form">
          <option :value="null">Любой</option>
          <option value="basic">Стандарт</option>
          <option value="premier">Премьер</option>
          <option value="premium">Премиум</option>
          <option value="leasing">Лизинг</option>
          <option value="poscredit">РиК</option>
        </SelectInput>

        <SelectInput class="mt-4" label="Шаг" v-model="form.step">
          <option :value="null">Любой</option>
          <option v-for="step of 6" :key="step" :value="step"> Шаг {{ step }} </option>
        </SelectInput>
      </SearchFilter>
    </div>

    <div class="flex flex-col">
      <div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
        <div
          class="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200"
        >
          <table class="min-w-full">
            <TableHeader
              :columns="[
                {
                  field: 'id',
                  label: '#',
                  sortable: true
                },
                {
                  field: 'form',
                  label: 'Программа',
                  sortable: true
                },
                {
                  field: 'step',
                  label: 'Шаг',
                  sortable: true
                },
                {
                  field: 'email',
                  label: 'Клиент'
                },
                {
                  field: 'card_number',
                  label: 'Карта'
                },
                {
                  field: 'created_at',
                  label: 'Создано',
                  sortable: true
                },
                {
                  field: 'updated_at',
                  label: 'Обновлено',
                  sortable: true
                }
              ]"
            />
            <tbody class="bg-white">
              <tr
                v-for="(appeal, idx) in appeals.data"
                :key="appeal.id"
                class="hover:bg-gray-50 focus-within:bg-gray-50 border-gray-200"
                :class="{ 'border-b': idx != appeals.data.length - 1 }"
              >
                <td
                  class="flex items-center px-6 py-4 whitespace-nowrap text-sm leading-5 text-gray-500 text-right"
                >
                  <InertiaLink
                    class="font-medium text-indigo-600 hover:text-indigo-900 focus:outline-none focus:underline"
                    :href="$routes.draft_appeal(appeal.id)"
                    aria-label="Show"
                  >
                    {{ appeal.hashid || appeal.id }}
                  </InertiaLink>
                  <Icon
                    v-if="appeal.form == 'premium'"
                    name="badge"
                    class="flex-shrink-0 w-3 h-3 fill-gray-500 ml-2 mt-0.5"
                  />
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm leading-5 text-gray-500 text-center">
                  <span
                    class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium leading-4 bg-gray-100 text-gray-800"
                  >
                    {{ appeal.form }}
                  </span>
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm leading-5 text-gray-700">
                  {{ appeal.step }} / {{ appeal.last_step }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                  {{ appeal.email }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm leading-5 text-gray-700">
                  {{ appeal.card_number }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                  {{
                    new Date(appeal.created_at).toLocaleString("ru", { timeZone: "Europe/Moscow" })
                  }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                  {{
                    new Date(appeal.updated_at).toLocaleString("ru", { timeZone: "Europe/Moscow" })
                  }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <Pagination :meta="appeals.meta" />
  </div>
</template>

<script>
import Icon from "@/Shared/Icon.vue";
import Layout from "@/Layouts/Full.vue";
import Pagination from "@/Shared/NewPagination.vue";
import SearchFilter from "@/Shared/SearchFilter.vue";
import SelectInput from "@/Shared/SelectInput.vue";
import TableHeader from "@/Shared/TableHeader.vue";
import mapValues from "lodash/mapValues";
import pickBy from "lodash/pickBy";
import throttle from "lodash/throttle";

export default {
  layout: Layout,
  components: {
    Icon,
    Pagination,
    SearchFilter,
    SelectInput,
    TableHeader
  },
  props: {
    appeals: {
      type: Object,
      required: true
    },
    filters: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      form: {
        search: this.filters.search,
        form: this.filters.form,
        step: this.filters.step,
        sort_by: this.filters.sort_by
      }
    };
  },
  watch: {
    form: {
      handler: throttle(function() {
        let query = pickBy(this.form);
        this.$inertia.replace(
          this.$routes.draft_appeals(Object.keys(query).length ? query : { remember: "forget" }),
          {
            preserveState: true,
            preserveScroll: true,
            only: ["appeals"]
          }
        );
      }, 150),
      deep: true
    }
  },
  methods: {
    reset() {
      this.form = mapValues(this.form, () => null);
    },
    inertiaVisit(pageNumber) {
      console.log(`Go to page ${pageNumber}`);
      const newUrl = this.draft_appeals.meta.scaffold_url.replace(/__pagy_page__/, pageNumber);
      this.$inertia.visit(newUrl);
    }
  }
};
</script>
