<template>
  <div class="device-info">
    <p v-if="info.purchase_country" class="tag uppercase text-gray-500">
      {{ info.purchase_country }}
    </p>

    <p v-if="info.icloud_lock" :class="['tag uppercase', lock]">
      {{ lock }}
    </p>

    <Dropdown class="tag more" :class="{ loading }" :disabled="loading" placement="bottom-start">
      <template #default>
        <div class="dots" />
      </template>

      <template #dropdown>
        <div class="mt-2 py-2 shadow-xl bg-white rounded text-sm">
          <span
            class="block px-6 py-2 cursor-pointer hover:bg-indigo-600 hover:text-white"
            @click="getDeviceInfo"
          >
            Данные об устойстве
          </span>
          <span
            class="block px-6 py-2 cursor-pointer hover:bg-indigo-600 hover:text-white"
            @click="getActivationLock"
          >
            Статус Activation Log
          </span>
        </div>
      </template>
    </Dropdown>

    <Modal :open="showInfo" @close="showInfo = false">
      <ul class="p-4 pt-8 bg-white">
        <li class="mb-2" v-for="([key, value], idx) in Object.entries(info)" :key="idx">
          <span class="font-medium">{{ key.replace(/_/g, " ") }}</span
          >: {{ value }}
        </li>
      </ul>
    </Modal>
  </div>
</template>

<script>
import axios from "axios";
import Dropdown from "@/Shared/Dropdown.vue";
import Modal from "@/Shared/Modal.vue";
export default {
  props: {
    ticketId: Number,
    info: Object
  },
  emits: ["update:info"],
  components: { Dropdown, Modal },
  data() {
    return {
      loading: false,
      showInfo: false
    };
  },
  computed: {
    lock() {
      return /^ON/.test(this.info.icloud_lock)
        ? "on"
        : /^OFF/.test(this.info.icloud_lock)
        ? "off"
        : null;
    }
  },
  methods: {
    async getDeviceInfo() {
      if (this.info.serial_number) {
        this.showInfo = true;
        return;
      }

      try {
        this.loading = true;
        const url = this.$routes.get_device_info_ticket(this.ticketId);
        const { data: newDeviceInfo } = await axios.post(url);
        this.$emit("update:info", newDeviceInfo);
      } catch (error) {
        console.error(error);
        this.$page.props.flash = error.response.data;
      } finally {
        this.loading = false;
      }
    },
    async getActivationLock() {
      try {
        this.loading = true;
        const url = this.$routes.get_activation_lock_ticket(this.ticketId);
        const { data: newDeviceInfo } = await axios.post(url);
        this.$emit("update:info", newDeviceInfo);
      } catch (error) {
        console.error(error);
        this.$page.props.flash = error.response.data;
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style scoped>
.device-info {
  display: flex;
  font-size: 0.625rem;
  line-height: 0.75rem;
}
.device-info > *:not(:first-child) {
  margin-left: 0.5rem;
}
.device-info .tag {
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  font-weight: 600;
  user-select: none;
  @apply bg-gray-200;
}

.device-info .tag.on {
  @apply bg-red-300 text-red-800;
}
.device-info .tag.off {
  @apply bg-green-300 text-green-800;
}

.device-info .dropdown {
  margin-top: 0.5rem;
  padding: 0.5rem 0;
  background: white;
  border-radius: 0.25rem;
  font-size: 0.875rem;
}

.device-info .dropdown .item {
  display: block;
  padding: 0.5rem 1.5rem;
  cursor: pointer;
  @apply hover:bg-indigo-600 hover:text-white;
}

.device-info .more {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 0.25rem 1rem;
  height: 1.25rem;
  overflow: hidden;
}

.dots,
.dots::before,
.dots::after {
  --dots-size: 0.25rem;
  width: var(--dots-size);
  height: var(--dots-size);
  border-radius: 1rem;
  @apply bg-indigo-500;
}
.dots {
  position: relative;
}
.dots::before,
.dots::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}
.dots::before {
  left: -0.5rem;
}
.dots::after {
  left: 0.5rem;
}
.more.loading .dots {
  animation: dot-flashing 1s infinite linear alternate;
  animation-delay: 0.5s;
}
.more.loading .dots::before {
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 0s;
}
.more.loading .dots::after {
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dot-flashing {
  0% {
    @apply bg-indigo-500;
  }
  50%,
  100% {
    @apply bg-indigo-500/20;
  }
}
</style>
