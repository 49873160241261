<template>
  <Transition name="fade-right" duration="300">
    <div
      v-if="showed"
      class="fixed z-10 inset-6 top-auto sm:inset-auto sm:top-24 sm:right-8 sm:w-full sm:max-w-sm flex items-center p-4 shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden"
      :class="[color ? `bg-${color}-50 text-${color}-600` : 'bg-white text-black']"
    >
      <div v-if="flash.success" class="flex-shrink-0">
        <svg
          class="h-5 w-5 text-green-400"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle stroke="currentColor" stroke-width="2" cx="12" cy="12" r="11" />
          <line
            stroke="currentColor"
            stroke-width="2.5"
            stroke-linecap="round"
            x1="8.26777"
            y1="11.625"
            x2="10.875"
            y2="14.2322"
          />
          <line
            stroke="currentColor"
            stroke-width="2.5"
            stroke-linecap="round"
            x1="10.875"
            y1="14.2322"
            x2="16.1072"
            y2="9"
          />
        </svg>
      </div>
      <div v-else-if="flash.alert" class="flex-shrink-0">
        <svg
          class="h-5 w-5 text-yellow-500"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke="currentColor"
            stroke-width="2"
            d="M13.732 3.25L22.6088 18.625C23.3786 19.9583 22.4164 21.625 20.8768 21.625H3.12324C1.58364 21.625 0.621388 19.9583 1.39119 18.625L10.2679 3.25C11.0377 1.91667 12.9623 1.91667 13.732 3.25Z"
          />
          <line
            stroke="currentColor"
            stroke-width="2.5"
            stroke-linecap="round"
            x1="12"
            y1="7.75"
            x2="12"
            y2="13.25"
          />
          <line
            stroke="currentColor"
            stroke-width="2.5"
            stroke-linecap="round"
            x1="12"
            y1="17.75"
            x2="12"
            y2="17.25"
          />
        </svg>
      </div>
      <div v-else-if="flash.error" class="flex-shrink-0">
        <svg
          class="h-5 w-5 text-red-400"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect stroke="currentColor" stroke-width="2" x="1" y="1" width="22" height="22" rx="5" />
          <line
            stroke="currentColor"
            stroke-width="2.5"
            stroke-linecap="round"
            x1="6.89716"
            y1="6.87939"
            x2="17.1294"
            y2="17.1116"
          />
          <line
            stroke="currentColor"
            stroke-width="2.5"
            stroke-linecap="round"
            x1="6.82082"
            y1="17.2114"
            x2="17.053"
            y2="6.97919"
          />
        </svg>
      </div>

      <div class="ml-3 w-0 flex-1">
        <p
          v-if="text.title"
          :class="['text-sm font-medium', color ? `text-${color}-800` : 'text-gray-800']"
        >
          {{ text.title }}
        </p>

        <p v-if="text.message" class="mt-1 text-sm">
          {{ text.message }}
        </p>
      </div>

      <button
        class="flex-shrink-0 self-start ml-4 -mt-4 -mr-4 p-3"
        type="button"
        @click="showed = false"
      >
        <svg
          class="block w-2 h-2"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="278.046 126.846 235.908 235.908"
        >
          <path
            d="M506.784 134.017c-9.56-9.56-25.06-9.56-34.62 0L396 210.18l-76.164-76.164c-9.56-9.56-25.06-9.56-34.62 0-9.56 9.56-9.56 25.06 0 34.62L361.38 244.8l-76.164 76.165c-9.56 9.56-9.56 25.06 0 34.62 9.56 9.56 25.06 9.56 34.62 0L396 279.42l76.164 76.165c9.56 9.56 25.06 9.56 34.62 0 9.56-9.56 9.56-25.06 0-34.62L430.62 244.8l76.164-76.163c9.56-9.56 9.56-25.06 0-34.62z"
          />
        </svg>
      </button>
      <div
        class="hidden bg-green-50 bg-red-50 bg-yellow-50 text-green-400 text-red-400 text-yellow-400 text-green-600 text-red-600 text-yellow-600 text-gray-600 text-green-800 text-red-800 text-yellow-800 text-gray-800"
      ></div>
    </div>
  </Transition>
</template>

<script>
export default {
  props: {
    duration: {
      type: Number,
      default: 3000
    }
  },
  data() {
    return {
      show: false
    };
  },
  computed: {
    color() {
      return this.flash.success
        ? "green"
        : this.flash.alert
        ? "yellow"
        : this.flash.error
        ? "red"
        : null;
    },
    flash() {
      return this.$page.props.flash || {};
    },
    text() {
      const text = Object.values(this.flash)
        .find(status => status)
        ?.split("|");
      return {
        title: text?.shift() || "Произошла непредвиденная ошибка",
        message: text?.shift()
      };
    },
    showed: {
      get() {
        return this.show && (this.text.title || this.text.message);
      },
      set(val) {
        this.show = val;
      }
    }
  },
  watch: {
    flash: {
      handler(newVal) {
        if (Object.values(newVal).some(status => status)) {
          this.showed = true;
          if (this.duration) {
            setTimeout(() => {
              this.showed = false;
            }, this.duration);
            setTimeout(() => {
              this.$page.props.flash = null;
            }, this.duration + 1000);
          }
        }
      },
      immediate: true
    }
  }
};
</script>

<style>
.fade-right-enter-active,
.fade-right-leave-active {
  transition: all 0.3s;
}
.fade-right-enter-active {
  transition-timing-function: ease-in;
}
.fade-right-leave-active {
  transition-timing-function: ease-out;
}

.fade-right-enter-active,
.fade-right-leave-to {
  transform: translateY(30px);
  opacity: 0;
}
.fade-right-enter-to {
  transform: translateX(0) translateY(0);
  opacity: 1;
}

@media screen and (min-width: 640px) {
  .fade-right-enter-active,
  .fade-right-leave-to {
    transform: translateX(30px) translateY(0);
  }
  .fade-right-enter-to {
    transform: translateX(0) translateY(0);
    opacity: 1;
  }
}
</style>
