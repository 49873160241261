<template>
  <div class="bg-white shadow overflow-hidden sm:rounded-lg">
    <div class="px-4 py-5 border-b border-gray-200 sm:px-6 flex justify-between">
      <div>
        <h3 class="text-lg leading-6 font-medium text-gray-900"># {{ contract.id }}</h3>

        <p class="mt-1 max-w-2xl text-sm leading-5 text-gray-500">
          {{ contract.description }}<br />
          c
          <span
            v-html="
              match(
                new Date(contract.sold_at).toLocaleDateString('ru', {
                  timeZone: 'Europe/Moscow',
                }),
                new Date(ticket.card_sold_date).toLocaleDateString('ru')
              )
            "
          ></span
          >,

          {{
            new Date(contract.sold_at).toLocaleTimeString("ru", {
              timeZone: "Europe/Moscow",
            })
          }}
          по
          {{
            new Date(contract.valid_before).toLocaleString("ru", {
              timeZone: "Europe/Moscow",
            })
          }}
        </p>
      </div>

      <InertiaLink v-if="contract.revocation_id" :href="$routes.revocation(contract.revocation_id)">
        <span
          class="bg-red-200 text-red-800 text-xs px-3 py-1 rounded-full uppercase font-semibold tracking-wide"
        >
          Аннулирован
        </span>
      </InertiaLink>
    </div>
    <div>
      <dl class="list">
        <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dd class="text-sm leading-5 font-medium text-gray-500">
            Карта
          </dd>
          <dt
            class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"
            v-html="match(contract.number, ticket.card_number)"
          ></dt>
        </div>
        <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dd class="text-sm leading-5 font-medium text-gray-500">
            Серийный номер / IMEI
          </dd>
          <dt class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
            <span v-html="match(contract.device_sn, ticket.sn)"></span> /
            <span v-html="match(contract.device_imei, ticket.imei)"></span>
          </dt>
        </div>
        <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dd class="text-sm leading-5 font-medium text-gray-500">
            Устройство
          </dd>
          <dt class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
            {{ contract.device_description }}
          </dt>
        </div>
        <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dd class="text-sm leading-5 font-medium text-gray-500">
            Партномер
          </dd>
          <dt class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
            {{ contract.device_pn }}
          </dt>
        </div>
        <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dd class="text-sm leading-5 font-medium text-gray-500">
            Стоимость
          </dd>
          <dt class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
            {{ formatNumber(contract.price) }} /
            <span
              v-html="
                match(formatNumber(contract.device_price), formatNumber(ticket.product_price))
              "
            ></span>
            / {{ ((contract.price * 100) / contract.device_price).toFixed(2) }}%
          </dt>
        </div>
        <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dd class="text-sm leading-5 font-medium text-gray-500">
            Документ
          </dd>
          <dt
            class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"
            v-html="match(contract.document, ticket.receipt_number)"
          ></dt>
        </div>
        <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dd class="text-sm leading-5 font-medium text-gray-500">
            Продавец
          </dd>
          <dt class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
            <span>{{ contract.salesman }}</span> / {{ contract.store }}
          </dt>
        </div>
      </dl>
    </div>
    <slot></slot>
  </div>
</template>

<script>
// var Diff = require('/../nodemodules/diff/lib/diff/base');
import { diffChars } from "diff";

export default {
  props: ["contract", "ticket"],
  methods: {
    match(a, b) {
      let color = "";
      let span = null;
      const result = document.createElement("span");
      if (!a) return "";
      diffChars(String(a), String(b)).forEach(function(part) {
        // green for additions, red for deletions
        // grey for common parts
        if (!part.added) {
          color = part.added ? "blue" : part.removed ? "red" : "green";
          span = document.createElement("span");
          span.style.color = color;
          span.appendChild(document.createTextNode(part.value));
          result.appendChild(span);
        }
      });
      return result.innerHTML;
    },
    formatNumber(number) {
      if (!number) {
        return "";
      }
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },
  },
};
</script>

<style scoped>
.list div:nth-of-type(2n + 1) {
  @apply bg-gray-50;
}
</style>
