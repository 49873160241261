<template>
  <div>
    <h1 class="mb-8 font-bold text-3xl">
      <InertiaLink class="text-indigo-400 hover:text-indigo-600" :href="$routes.contracts()">
        Контракты
      </InertiaLink>
      <span class="text-indigo-400 font-medium">/</span> Загрузка
    </h1>

    <LoadForm
      v-model:form="form"
      :brands="brands"
      :channels="channels"
      :disabled="!formFilled"
      @submit="submitForm"
    />
  </div>
</template>

<script>
import Layout from "@/Layouts/Full.vue";
import LoadForm from "./LoadForm.vue";

export default {
  metaInfo: { title: "Импорт контрактов" },
  layout: Layout,
  components: {
    LoadForm
  },
  remember: "form",
  props: {
    brands: {
      type: Array,
      required: true
    },
    channels: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      form: this.$inertia.form({
        import_data: {
          brand_id: null,
          sales_channel_id: null,
          csv_file: null
        }
      })
    };
  },
  methods: {
    submitForm() {
      if (!this.formFilled) return;
      this.form.post(this.$routes.contracts(), {
        preserveState: true,
        preserveScroll: true,
        only: ["ticket", "errors", "flash"]
      });
    }
  },
  computed: {
    formFilled() {
      return !Object.values(this.form.import_data).some(c => !c);
    }
  }
};
</script>
