<template>
  <div>
    Акт передачи в сервис #{{ document.id }} от {{ new Date(document.created_at).toLocaleDateString("ru", {timeZone: "Europe/Moscow"}) }}
    <pre>
{{ JSON.stringify(document.data, null, '  ') }}
    </pre>
  </div>
</template>


<script>
  import Layout from "@/Layouts/Full.vue";

  export default {
    metaInfo: { title: "Акт" },
    layout: Layout,
    props: {
        document: {
            type: Object,
            required: true,
        }
    }
  }
</script>
