<template>
  <div>
    <h1 class="mb-8 font-medium text-2xl">
      <InertiaLink
        class="text-indigo-500 hover:text-indigo-800"
        :href="$routes.service_providers()"
      >
        Партнёры
      </InertiaLink>
      /
      <InertiaLink
        class="text-indigo-500 hover:text-indigo-800"
        :href="$routes.service_provider(location.provider.id)"
        >{{ location.provider.name }}</InertiaLink
      >
      / {{ location.address }}
    </h1>
    <div class="flex flex-col mt-2">
      <div class="align-middle min-w-full overflow-x-auto shadow overflow-hidden sm:rounded-lg">
        <table class="min-w-full divide-y divide-cool-gray-200">
          <thead>
            <tr>
              <th
                class="px-6 py-3 bg-cool-gray-50 text-left text-xs leading-4 font-medium text-cool-gray-500 uppercase tracking-wider"
              >
                #
              </th>
              <th
                class="px-6 py-3 bg-cool-gray-50 text-left text-xs leading-4 font-medium text-cool-gray-500 uppercase tracking-wider"
              >
                sn
              </th>
              <th
                class="hidden px-6 py-3 bg-cool-gray-50 text-left text-xs leading-4 font-medium text-cool-gray-500 uppercase tracking-wider md:block"
              >
                imei
              </th>
            </tr>
          </thead>
          <tbody class="bg-white divide-y divide-cool-gray-200">
            <tr class="bg-white" v-for="device in location.routed_devices" :key="device.id">
              <td class="max-w-0 px-4 py-4 whitespace-nowrap text-sm text-cool-gray-900">
                <CheckboxInput :native-value="device.id" v-model="selectedDeviceIds" />
              </td>
              <td class="px-6 py-4 text-left whitespace-nowrap text-sm  text-cool-gray-500">
                {{ device.sn }}
              </td>
              <td class="hidden px-6 py-4 whitespace-nowrap text-sm text-cool-gray-500 md:block">
                {{ device.name }}
              </td>
            </tr>
          </tbody>
        </table>
        <div class="px-4 py-4 sm:px-6 bg-gray-100 flex items-start">
          <span class="py-2 text-red-700">{{
            $page.errors &&
              $page.errors.transfer_to_service &&
              $page.errors.transfer_to_service.base[0]
          }}</span>
          <button
            type="button"
            @click="sendToService"
            class="btn-indigo px-3 py-2 h-8 ml-auto mr-3"
          >
            Отправить в сервис
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Layout from "@/Layouts/Full.vue";
import CheckboxInput from "@/Shared/CheckboxInput.vue";

export default {
  metaInfo: { title: "Сервис" },
  components: { CheckboxInput },
  layout: Layout,
  data() {
    return {
      selectedDeviceIds: []
    };
  },
  props: {
    location: {
      type: Object,
      required: true
    }
  },
  methods: {
    sendToService() {
      this.$inertia.post(
        this.$routes.documents(),
        {
          document: {
            type: "TransferToService",
            service_location_id: this.location.id,
            device_ids: this.selectedDeviceIds
          }
        },
        {
          preserveScroll: true
        }
      );
    }
  }
};
</script>
