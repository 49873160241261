<template>
  <div class="max-w-4xl">
    <div class="mb-8 flex flex-col justify-start">
      <h2 class="font-bold text-2xl">
        Направить устройство в сервис
      </h2>
    </div>

    <StyledForm
      :class="{ error: !!Object.keys(formErrors).length }"
      :errors="formErrors.base"
      :loading="form.processing"
      @submit="submit"
    >
      <template v-slot:formBody>
        <TextareaInput
          label="Неисправность"
          v-model="form.request.symptom"
          placeholder="Укажите неисправность для сервисного центра"
          :errors="formErrors.symptom"
        />

        <div v-if="!providers.length">Сервисные центры не найдены.</div>
        <ul v-else>
          <li v-for="(p, idx) in providers" :key="p">
            <div class="mt-5 font-medium text-gray-500">{{ p }}</div>
            <RadioInput
              v-for="loc in providerLocations(p)"
              v-model="form.request.service_location_id"
              name="location"
              :key="loc.id"
              :native-value="loc.id"
              :errors="this.form?.errors"
              @input="() => form.clearErrors('base')"
            >
              {{ loc.city }}, {{ loc.address }}
            </RadioInput>
          </li>
        </ul>
      </template>
      <template v-if="!providers.length" v-slot:submitButton>
        <button class="btn-indigo px-6 py-3 leading-4" type="button" @click="$emit('close')">
          Закрыть
        </button>
      </template>
    </StyledForm>
  </div>
</template>

<script type="text/javascript">
import TextareaInput from "@/Shared/TextareaInput.vue";
import RadioInput from "@/Shared/RadioInput.vue";
import StyledForm from "@/Shared/StyledForm.vue";
import axios from "axios";

export default {
  components: { TextareaInput, RadioInput, StyledForm },
  props: ["ticketId"],
  emits: ["close"],
  data() {
    return {
      locations: [],
      form: this.$inertia.form({
        request: {
          symptom: this.$attrs.ticket.issue,
          service_location_id: null
        }
      })
    };
  },
  computed: {
    providers() {
      return Array.from(new Set(this.locations.map(x => x.name)));
    },
    formErrors() {
      return this.form?.errors || {};
    }
  },
  methods: {
    providerLocations(p) {
      return this.locations.filter(loc => loc.name === p);
    },
    submit() {
      console.log(this.form.request);
      this.form.post(this.$routes.route_device_to_service_ticket(this.ticketId), {
        preserveState: true,
        preserveScroll: true,
        onSuccess: () => this.$emit("close")
      });
    }
  },
  mounted() {
    this.$el.scrollIntoView({ behavior: "smooth" });
  },
  async beforeMount() {
    const { data } = await axios.get(`/tickets/${this.ticketId}/service_locations.json`);
    this.locations = data.service_locations;
  }
};
</script>
