<template>
  <div>
    <div class="mb-4">
      <InertiaLink
        class="flex items-center group py-3"
        :href="$routes.root()"
        role="navigation"
        aria-label="Dashboard"
      >
        <Icon
          name="dashboard"
          class="w-4 h-4 mr-2"
          :class="isUrl('') ? 'fill-white' : 'fill-indigo-400 group-hover:fill-white'"
        />
        <div :class="isUrl('') ? 'text-white' : 'text-indigo-300 group-hover:text-white'">
          Dashboard
        </div>
      </InertiaLink>
    </div>
    <!-- <div class="mb-4">
      <InertiaLink
        class="flex items-center group py-3"
        :href="$routes.organizations()"
        role="navigation"
        aria-label="Organizations"
      >
        <Icon
          name="office"
          class="w-4 h-4 mr-2"
          :class="isUrl('organizations') ? 'fill-white' : 'fill-indigo-400 group-hover:fill-white'"
        />
        <div :class="isUrl('organizations') ? 'text-white' : 'text-indigo-300 group-hover:text-white'">
          Organizations
        </div>
      </InertiaLink>
    </div> -->

    <div class="mb-4">
      <InertiaLink
        class="flex items-center group py-3"
        :href="$routes.tickets()"
        role="navigation"
        aria-label="Tickets"
      >
        <Icon
          name="folder"
          class="w-4 h-4 mr-2"
          :class="isUrl('tickets') ? 'fill-white' : 'fill-indigo-400 group-hover:fill-white'"
        />
        <div :class="isUrl('tickets') ? 'text-white' : 'text-indigo-300 group-hover:text-white'">
          Обращения
        </div>
      </InertiaLink>
    </div>
    <div class="mb-4">
      <InertiaLink
        class="flex items-center group py-3"
        :href="$routes.draft_appeals()"
        role="navigation"
        aria-label="Draft appeals"
      >
        <Icon
          name="folder-outlined"
          class="w-4 h-4 mr-2"
          :class="isUrl('draft_appeals') ? 'fill-white' : 'fill-indigo-400 group-hover:fill-white'"
        />
        <div
          :class="isUrl('draft_appeals') ? 'text-white' : 'text-indigo-300 group-hover:text-white'"
        >
          Черновики
        </div>
      </InertiaLink>
    </div>
    <div class="mb-4">
      <InertiaLink
        class="flex items-center group py-3"
        :href="$routes.invoices()"
        role="navigation"
        aria-label="Invoices"
      >
        <Icon
          name="invoice"
          class="w-4 h-4 mr-2"
          :class="isUrl('invoices') ? 'fill-white' : 'fill-indigo-400 group-hover:fill-white'"
        />
        <div :class="isUrl('invoices') ? 'text-white' : 'text-indigo-300 group-hover:text-white'">
          Счета
        </div>
      </InertiaLink>
    </div>
    <div class="mb-4">
      <InertiaLink
        class="flex items-center group py-3"
        :href="$routes.contracts()"
        role="navigation"
        aria-label="Reports"
      >
        <Icon
          name="cards"
          class="w-4 h-4 mr-2"
          :class="isUrl('contracts') ? 'fill-white' : 'fill-indigo-400 group-hover:fill-white'"
        />
        <div :class="isUrl('contracts') ? 'text-white' : 'text-indigo-300 group-hover:text-white'">
          Контракты
        </div>
      </InertiaLink>
    </div>
    <div class="mb-4">
      <InertiaLink
        class="flex items-center group py-3"
        :href="$routes.aftersales()"
        role="navigation"
        aria-label="Reports"
      >
        <Icon
          name="folder-plus"
          class="w-4 h-4 mr-2"
          :class="isUrl('aftersales') ? 'fill-white' : 'fill-indigo-400 group-hover:fill-white'"
        />
        <div :class="isUrl('aftersales') ? 'text-white' : 'text-indigo-300 group-hover:text-white'">
          Допродажи
        </div>
      </InertiaLink>
    </div>
    <div class="mb-4">
      <InertiaLink
        class="flex items-center group py-3"
        :href="$routes.revocations()"
        role="navigation"
        aria-label="Reports"
      >
        <Icon
          name="backspace"
          class="w-4 h-4 mr-2"
          :class="isUrl('revocations') ? 'fill-white' : 'fill-indigo-400 group-hover:fill-white'"
        />
        <div
          :class="isUrl('revocations') ? 'text-white' : 'text-indigo-300 group-hover:text-white'"
        >
          Отмены
        </div>
      </InertiaLink>
    </div>
    <!--  <div class="mb-4">
      <InertiaLink
        class="flex items-center group py-3"
        :href="$routes.cards()"
        role="navigation"
        aria-label="Reports"
      >
        <Icon
          name="cards"
          class="w-4 h-4 mr-2"
          :class="isUrl('cards') ? 'fill-white' : 'fill-indigo-400 group-hover:fill-white'"
        />
        <div :class="isUrl('cards') ? 'text-white' : 'text-indigo-300 group-hover:text-white'">
          Карты
        </div>
      </InertiaLink>
    </div> -->
    <div class="mb-4">
      <InertiaLink
        class="flex items-center group py-3"
        :href="$routes.devices()"
        role="navigation"
        aria-label="Активность"
      >
        <Icon
          name="device"
          class="w-4 h-4 mr-2"
          :class="isUrl('contacts') ? 'fill-white' : 'fill-indigo-400 group-hover:fill-white'"
        />
        <div :class="isUrl('contacts') ? 'text-white' : 'text-indigo-300 group-hover:text-white'">
          Устройства
        </div>
      </InertiaLink>
    </div>
    <div class="mb-4">
      <InertiaLink
        v-if="$page.props.auth.user.admin"
        class="flex items-center group py-3"
        :href="$routes.users()"
        role="navigation"
        aria-label="Пользователи"
      >
        <Icon
          name="users"
          class="w-4 h-4 mr-2"
          :class="isUrl('contacts') ? 'fill-white' : 'fill-indigo-400 group-hover:fill-white'"
        />
        <div :class="isUrl('contacts') ? 'text-white' : 'text-indigo-300 group-hover:text-white'">
          Пользователи
        </div>
      </InertiaLink>
    </div>

    <!-- <div class="mb-4">
      <InertiaLink
        class="flex items-center group py-3"
        :href="$routes.users()"
        role="navigation"
        aria-label="Активность"
      >
        <Icon
          name="announcement"
          class="w-4 h-4 mr-2"
          :class="
            isUrl('contacts')
              ? 'fill-white'
              : 'fill-indigo-400 group-hover:fill-white'
          "
        />
        <div
          :class="
            isUrl('contacts')
              ? 'text-white'
              : 'text-indigo-300 group-hover:text-white'
          "
        >
          Активность
        </div>
      </InertiaLink>
    </div> -->
    <!-- <div class="mb-4">
      <InertiaLink
        class="flex items-center group py-3"
        :href="$routes.reports()"
        role="navigation"
        aria-label="Contacts"
      >
        <Icon
          name="users"
          class="w-4 h-4 mr-2"
          :class="isUrl('contacts') ? 'fill-white' : 'fill-indigo-400 group-hover:fill-white'"
        />
        <div :class="isUrl('contacts') ? 'text-white' : 'text-indigo-300 group-hover:text-white'">
          Отчеты
        </div>
      </InertiaLink>
    </div> -->
  </div>
</template>

<script>
import Icon from "@/Shared/Icon.vue";

export default {
  components: {
    Icon
  },
  methods: {
    isUrl(...urls) {
      const currentUrl = this.$page.url.substr(1);
      if (urls[0] === "") {
        return currentUrl === "";
      }
      return urls.filter(url => currentUrl.startsWith(url)).length;
    }
  }
};
</script>
