<template>
  <div>
    <InertiaHead title="Dashboard" />
    <h1 class="mb-12 font-bold text-3xl">
      Dashboard
    </h1>
    <div class="grid grid-cols-1 sm:grid-cols-2 grid-flow-row gap-8 lg:gap-12">
      <SalesStatCard
        :chartData="perDay.appeals"
        :diffByPercent="diffByPercent(perDay.totalAppeals, perDay.prevAppeals)"
        chartId="perDay-appeals"
      >
        <template v-slot:title>
          <div class="text-sm">Черновики за сутки:</div>
          <span class="text-lg font-medium">
            {{ perDay.totalAppeals }}
          </span>
        </template>
      </SalesStatCard>

      <SalesStatCard
        :chartData="perDay.tickets"
        :diffByPercent="diffByPercent(perDay.totalTickets, perDay.prevTickets)"
        chartId="perDay-tickets"
      >
        <template v-slot:title>
          <div class="text-sm">Обращения за сутки:</div>
          <span class="text-lg font-medium">
            {{ perDay.totalTickets }}
          </span>
        </template>
      </SalesStatCard>

      <SalesStatCard
        :chartData="perWeek.appeals"
        :diffByPercent="diffByPercent(perWeek.totalAppeals, perWeek.prevAppeals)"
        chartId="perWeek-appeals"
      >
        <template v-slot:title>
          <div class="text-sm">Черновики за неделю:</div>
          <span class="text-lg font-medium">
            {{ perWeek.totalAppeals }}
          </span>
        </template>
      </SalesStatCard>

      <SalesStatCard
        :chartData="perWeek.tickets"
        :diffByPercent="diffByPercent(perWeek.totalTickets, perWeek.prevTickets)"
        chartId="perWeek-tickets"
      >
        <template v-slot:title>
          <div class="text-sm">Обращения за неделю:</div>
          <span class="text-lg font-medium">
            {{ perWeek.totalTickets }}
          </span>
        </template>
      </SalesStatCard>

      <SalesStatCard :chartData="perMonth.appeals" chartId="perMonth-appeals">
        <template v-slot:title>
          <div class="text-sm">Черновики за месяц:</div>
          <span class="text-lg font-medium">
            {{ perMonth.totalAppeals }}
          </span>
        </template>
      </SalesStatCard>

      <SalesStatCard :chartData="perMonth.tickets" chartId="perMonth-tickets">
        <template v-slot:title>
          <div class="text-sm">Обращения за месяц:</div>
          <span class="text-lg font-medium">
            {{ perMonth.totalTickets }}
          </span>
        </template>
      </SalesStatCard>

      <SalesStatCard :chartData="perMonth.cards" chartId="perMonth-tickets">
        <template v-slot:title>
          <div class="text-sm">Карт сгенерировано за месяц:</div>
          <span class="text-lg font-medium">
            {{ perMonth.totalCards }}
          </span>
        </template>
      </SalesStatCard>

      <SalesStatCard :chartData="perMonth.charges" chartId="perMonth-charges">
        <template v-slot:title>
          <div class="text-sm">Продаж через API:</div>
          <span class="text-lg font-medium">
            {{ perMonth.totalCharges }}
          </span>
        </template>
      </SalesStatCard>
    </div>

    <div v-if="codes.length" class="flex flex-col mt-12">
      <div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
        <div
          class="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200"
        >
          <table class="table min-w-full">
            <TableHeader
              :columns="[
                {
                  field: 'email',
                  label: 'Email',
                },
                {
                  field: 'code',
                  label: 'Код подтверждения',
                },
              ]"
            />
            <tbody class="bg-white">
              <tr
                v-for="(data, idx) in codes"
                :key="idx"
                class="hover:bg-gray-50 focus-within:bg-gray-50 border-gray-200"
                :class="{ 'border-b': idx != codes.length - 1 }"
              >
                <td>
                  {{ data.email }}
                </td>
                <td>
                  {{ data.code }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <p v-if="git.commit_url" class="my-10 leading-normal">
      Version
      <a :href="git.commit_url" class="hover:underline">
        {{ git.commit_sha }}
      </a>
      <br />
      Built at
      <span class="ml-1 text-gray-700">
        {{ git.commit_time }}
      </span>
    </p>

    <div>
      <InertiaLink class="btn-indigo" :href="$routes.error_500()">
        500 error
      </InertiaLink>
      <InertiaLink class="ml-2 btn-indigo" href="/error-404">
        404 error
      </InertiaLink>
    </div>
  </div>
</template>

<script>
import Layout from "@/Layouts/Full.vue";
import SalesStatCard from "@/Shared/SalesStatCard.vue";

export default {
  layout: Layout,
  components: { SalesStatCard, Layout },
  props: {
    git: {
      type: Object,
      required: true,
    },
    dashdata: {
      type: Object,
      required: true,
    },
    codes: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    perDay() {
      return {
        appeals: this.getPerDay(this.dashdata.appeal_series),
        tickets: this.getPerDay(this.dashdata.ticket_series),
        totalAppeals: this.countTotal(this.getPerDay(this.dashdata.appeal_series)),
        totalTickets: this.countTotal(this.getPerDay(this.dashdata.ticket_series)),
        prevAppeals: this.countTotal(this.getPerDay(this.dashdata.appeal_series, 1)),
        prevTickets: this.countTotal(this.getPerDay(this.dashdata.ticket_series, 1)),
      };
    },
    perWeek() {
      return {
        appeals: this.getWeekly(this.dashdata.appeal_series),
        tickets: this.getWeekly(this.dashdata.ticket_series),
        totalAppeals: this.countTotal(this.getWeekly(this.dashdata.appeal_series)),
        totalTickets: this.countTotal(this.getWeekly(this.dashdata.ticket_series)),
        prevAppeals: this.countTotal(this.getWeekly(this.dashdata.appeal_series, 1)),
        prevTickets: this.countTotal(this.getWeekly(this.dashdata.ticket_series, 1)),
      };
    },
    perMonth() {
      return {
        appeals: this.getMonthly(this.dashdata.appeal_series),
        tickets: this.getMonthly(this.dashdata.ticket_series),
        cards: this.getMonthly(this.dashdata.cards_series),
        charges: this.getMonthly(this.dashdata.charges_series),
        totalAppeals: this.countTotal(this.getMonthly(this.dashdata.appeal_series)),
        totalTickets: this.countTotal(this.getMonthly(this.dashdata.ticket_series)),
        totalCards: this.countTotal(this.getMonthly(this.dashdata.cards_series)),
        totalCharges: this.countTotal(this.getMonthly(this.dashdata.charges_series)),
      };
    },
  },
  methods: {
    getPerDay(data, offset) {
      const generateName = (dateString, nextDateString) => {
        const currentHour = this.getHoursLocaleRuMoscow(dateString);
        const nextHour = this.getHoursLocaleRuMoscow(nextDateString);
        return `С ${currentHour}:00 - до ${nextHour}:00`;
      };

      const HOURS_IN_DAY = 24;
      const day = offset
        ? data.slice(-HOURS_IN_DAY * (offset + 1), -HOURS_IN_DAY * offset)
        : data.slice(-HOURS_IN_DAY);

      return day.map((d, idx) => ({
        value: d.count,
        name:
          idx === day.length - 1
            ? "Текущий час"
            : generateName(d.time_from, day[idx + 1].time_from),
      }));
    },
    getWeekly(data, offset) {
      const DAYS_IN_WEEK = 7;
      const HOURS_IN_DAY = 24;

      const currentHour = Number(this.getHoursLocaleRuMoscow(data[data.length - 1].time_from));
      const countHours = HOURS_IN_DAY * (DAYS_IN_WEEK - 1) + currentHour + 1;
      const week = offset
        ? data.slice(
            -DAYS_IN_WEEK * HOURS_IN_DAY * (offset + 1),
            -DAYS_IN_WEEK * HOURS_IN_DAY * offset
          )
        : data.slice(-countHours);

      const newData = [];
      for (let i = 0; i < DAYS_IN_WEEK; i++) {
        let endDate = new Date();
        endDate.setDate(endDate.getDate() - (DAYS_IN_WEEK - 1 - i));
        const dayName = endDate.toLocaleString("ru", { weekday: "long" });

        let day = [];
        if (i + 1 === DAYS_IN_WEEK) {
          day = week.slice(HOURS_IN_DAY * i, HOURS_IN_DAY * i + (currentHour + 1));
        } else {
          day = week.slice(HOURS_IN_DAY * i, HOURS_IN_DAY * (i + 1));
        }

        newData.push({
          name: dayName.slice(0, 1).toUpperCase() + dayName.slice(1, dayName.length),
          value: day.reduce((count, hour) => count + hour.count, 0),
        });
      }

      return newData;
    },
    getMonthly(data) {
      const DAYS_IN_MONTH = 30;
      const HOURS_IN_DAY = 24;

      const currentHour = Number(this.getHoursLocaleRuMoscow(data[data.length - 1].time_from));
      const countHours = HOURS_IN_DAY * (DAYS_IN_MONTH - 1) + currentHour + 1;
      const month = data.slice(-countHours);

      const newData = [];
      for (let i = 0; i < DAYS_IN_MONTH; i++) {
        let endDate = new Date();
        endDate.setDate(endDate.getDate() - (DAYS_IN_MONTH - 1 - i));
        const dateName = endDate.toLocaleString("ru", { month: "long", day: "2-digit" });

        let day = [];
        if (i + 1 === DAYS_IN_MONTH) {
          day = month.slice(HOURS_IN_DAY * i, HOURS_IN_DAY * i + (currentHour + 1));
        } else {
          day = month.slice(HOURS_IN_DAY * i, HOURS_IN_DAY * (i + 1));
        }

        newData.push({
          name: dateName,
          value: day.reduce((count, hour) => count + hour.count, 0),
        });
      }

      return newData;
    },
    getHoursLocaleRuMoscow(dateString) {
      const newDate = new Date(dateString);
      return newDate.toLocaleTimeString("ru", { timeZone: "Europe/Moscow" }).slice(0, 2);
    },
    countTotal(ticketsOrApeals) {
      return ticketsOrApeals.reduce((acc, item) => acc + item.value, 0);
    },
    diffByPercent(x, y) {
      return Math.ceil((x / y) * 100) - 100;
    },
  },
};
</script>

<style scoped>
.table td {
  @apply px-6 py-4 whitespace-nowrap text-sm leading-5 text-gray-500;
}
</style>
