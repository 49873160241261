<template>
  <StyledForm
    :class="{ error: !!Object.keys(formErrors).length }"
    :errors="formErrors.base"
    :loading="formData.processing"
    :disabled="disabled"
    @submit="$emit('submit')"
  >
    <template v-slot:formBody>
      <div class="flex">
        <SelectInput
          v-model="formData.import_data.brand_id"
          :errors="formErrors.brand_id"
          class="pr-6 pb-8 w-full lg:w-1/2"
          label="Brand"
        >
          <option :value="null">Не выбрано</option>
          <option v-for="b in brands" :key="b.id" :value="b.id">
            {{ b.name }}
          </option>
        </SelectInput>

        <SelectInput
          v-model="formData.import_data.sales_channel_id"
          :errors="formErrors.sales_channel_id"
          class="pr-6 pb-8 w-full lg:w-1/2"
          label="Channel"
        >
          <option :value="null">Не выбрано</option>
          <option v-for="c in channels" :key="c.id" :value="c.id">
            {{ c.name }}
          </option>
        </SelectInput>
      </div>
      <FileInput
        v-model:file="formData.import_data.csv_file"
        :errors="formErrors.csv_file"
        class="pr-6 pb-8 w-full"
        type="file"
        accept="text/csv"
        label="CSV файл"
      />
    </template>
  </StyledForm>
</template>

<script>
import StyledForm from "@/Shared/StyledForm.vue";
import SelectInput from "@/Shared/SelectInput.vue";
import TextInput from "@/Shared/TextInput.vue";
import FileInput from "@/Shared/FileInput.vue";

export default {
  components: {
    StyledForm,
    SelectInput,
    TextInput,
    FileInput,
  },
  props: {
    brands: {
      type: Array,
      required: true,
    },
    channels: {
      type: Array,
      required: true,
    },
    form: {
      type: Object,
      required: true,
    },
    disabled: Boolean,
  },
  emits: ["submit", "update:form"],
  computed: {
    formData: {
      get() {
        return this.form;
      },
      set(val) {
        this.$emit("update:form", val);
      },
    },
    formErrors() {
      return this.formData?.errors || {};
    },
  },
};
</script>
