<template>
  <div>
    <h1 class="mb-8 font-bold text-3xl">
      Документы
    </h1>

        <div class="flex flex-col mt-2">
          <div class="align-middle min-w-full overflow-x-auto shadow overflow-hidden sm:rounded-lg">
            <table class="min-w-full divide-y divide-cool-gray-200">
              <thead>
                <tr>
                  <th class="px-6 py-3 bg-cool-gray-50 text-left text-xs leading-4 font-medium text-cool-gray-500 uppercase tracking-wider">
                    #
                  </th>
                  <th class="px-6 py-3 bg-cool-gray-50 text-left text-xs leading-4 font-medium text-cool-gray-500 uppercase tracking-wider">
                    Название
                  </th>
                  <th class="hidden px-6 py-3 bg-cool-gray-50 text-left text-xs leading-4 font-medium text-cool-gray-500 uppercase tracking-wider md:block">
                    Автор
                  </th>
                  <th class="px-6 py-3 bg-cool-gray-50 text-right text-xs leading-4 font-medium text-cool-gray-500 uppercase tracking-wider">
                    Дата
                  </th>
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-cool-gray-200">
                <tr class="bg-white" v-for="doc in documents.data" :key="doc.id">
                  <td class="max-w-0 px-6 py-4 whitespace-nowrap text-sm leading-5 text-cool-gray-900">
                    <div class="flex">
                      <a :href="$routes.document(doc.id)" class="group inline-flex space-x-2 truncate text-sm leading-5">
                        <p class="text-cool-gray-500 truncate group-hover:text-cool-gray-900 transition ease-in-out duration-150">
                          {{ doc.id }}
                        </p>
                      </a>
                    </div>
                  </td>
                  <td class="px-6 py-4 text-left whitespace-nowrap text-sm leading-5 text-cool-gray-500">
                    {{ doc.type }}
                  </td>
                  <td class="hidden px-6 py-4 whitespace-nowrap text-sm leading-5 text-cool-gray-500 md:block">
                    {{ doc.user.first_name }}  {{ doc.user.last_name }}
                  </td>
                  <td class="px-6 py-4 text-right whitespace-nowrap text-sm leading-5 text-cool-gray-500">
                    {{ new Date(doc.created_at).toLocaleString("ru",  {timeZone: "Europe/Moscow"}) }}
                  </td>
                </tr>

                <!-- More rows... -->
              </tbody>
            </table>
          </div>

      </div>

  </div>
</template>

<script>
  import Layout from "@/Layouts/Full.vue";

  export default {
    metaInfo: { title: "Документы" },
    layout: Layout,
    props: {
        documents: {
            type: Object,
            required: true,
        }
    }
  }
</script>
