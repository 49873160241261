<template>
  <div v-if="present" class="px-4 py-4 sm:px-6 bg-white border-t border-gray-200 flex">
    <svg
      class="w-6 h-6 text-red-600"
      fill="none"
      stroke="currentColor"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z"
      ></path>
    </svg>
    <ul class="px-3 leading-6 text-sm">
      <li v-if="traces.card_number.length">
        Номер карты встречается в:
        <InertiaLink
          class="font-medium text-indigo-600 hover:text-indigo-900 focus:outline-none focus:underline"
          v-for="t in traces.card_number"
          :key="t"
          :href="`/tickets/${t}`"
          >{{ t }}
        </InertiaLink>
      </li>
      <li v-if="traces.sn.length">
        Серийный номер встречается в:
        <InertiaLink
          class="font-medium text-indigo-600 hover:text-indigo-900 focus:outline-none focus:underline"
          v-for="t in traces.sn"
          :key="t"
          :href="`/tickets/${t}`"
          >{{ t }}
        </InertiaLink>
      </li>
      <li v-if="traces.imei.length">
        IMEI номер встречается в:
        <InertiaLink
          class="font-medium text-indigo-600 hover:text-indigo-900 focus:outline-none focus:underline"
          v-for="t in traces.imei"
          :key="t"
          :href="`/tickets/${t}`"
          >{{ t }}
        </InertiaLink>
      </li>
      <li v-if="traces.email.length">
        Email встречается в:
        <InertiaLink
          class="font-medium text-indigo-600 hover:text-indigo-900 focus:outline-none focus:underline"
          v-for="t in traces.email"
          :key="t"
          :href="`/tickets/${t}`"
          >{{ t }}
        </InertiaLink>
      </li>
      <li v-if="traces.phone.length">
        Телефоны встречаются в:
        <InertiaLink
          class="font-medium text-indigo-600 hover:text-indigo-900 focus:outline-none focus:underline"
          v-for="t in traces.phone"
          :key="t"
          :href="`/tickets/${t}`"
          >{{ t }}
        </InertiaLink>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  computed: {
    traces() {
      return this.$page.ticket?.traces || [];
    },
    present() {
      return Object.values(this.traces).some(x => x.length > 0);
    }
  }
};
</script>
