<template>
  <div>
    <h1 class="mb-8 font-bold text-3xl">
      <InertiaLink class="text-indigo-400 hover:text-indigo-600" :href="$routes.tickets()">
        Обращения
      </InertiaLink>
      <span class="text-indigo-400 font-medium">/</span> Загрузка
    </h1>
    <div class="bg-white rounded shadow overflow-hidden max-w-3xl">
      <ImportForm :form="form" @submit="submit">
        <div class="px-8 py-4 bg-gray-100 border-t border-gray-200 flex justify-end items-center">
          <LoadingButton :loading="sending" class="btn-indigo" type="submit">
            Сохранить
          </LoadingButton>
        </div>
      </ImportForm>
    </div>
  </div>
</template>

<script>
import Layout from "@/Layouts/Full.vue";
import LoadingButton from "@/Shared/LoadingButton.vue";
import ImportForm from "./ImportForm.vue";

export default {
  metaInfo: { title: "Create User" },
  layout: Layout,
  components: {
    LoadingButton,
    ImportForm
  },
  remember: "form",
  data() {
    return {
      sending: false,
      form: {}
    };
  },
  methods: {
    submit() {
      this.sending = true;

      let data = new FormData();
      // data.append('user[first_name]', this.form.first_name || '')
      // data.append('user[last_name]', this.form.last_name || '')
      // data.append('user[email]', this.form.email || '')
      // data.append('user[password]', this.form.password || '')
      // data.append('user[owner]', this.form.owner ? '1' : '0')
      data.append("file", this.form.excel_file || "");

      this.$inertia.post(this.$routes.import_tickets(), data).then(() => (this.sending = false));
    }
  }
};
</script>
