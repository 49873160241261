<template>
  <div>
    <InertiaHead title="Обращения" />
    <h1 class="mb-8 font-bold text-3xl">
      Обращения
    </h1>
    <div class="mb-6 flex justify-between items-center">
      <SearchFilter v-model="form.search" class="w-full max-w-md mr-4" @reset="reset">
        <SelectInput label="Тип" v-model="form.form">
          <option :value="null">Любой</option>
          <option value="basic">Стандарт</option>
          <option value="premier">Премьер</option>
          <option value="premium">Премиум</option>
          <option value="leasing">Лизинг</option>
          <option value="rik">ЛиК</option>
        </SelectInput>

        <SelectInput class="mt-4" label="Статус" v-model="form.state">
          <option :value="null">Любой</option>
          <option v-for="state in statuses" :key="state" :value="state">{{ state }}</option>
        </SelectInput>
      </SearchFilter>

      <InertiaLink class="btn-indigo" :href="$routes.new_ticket()">
        <span>Загрузить</span>
        <span class="hidden md:inline">&nbsp;обращения</span>
      </InertiaLink>
    </div>

    <div class="flex flex-col">
      <div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
        <div
          class="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200"
        >
          <table class="min-w-full">
            <TableHeader
              :columns="[
                {
                  field: 'id',
                  label: '#',
                  sortable: true
                },
                {
                  field: 'form',
                  label: 'Программа',
                  sortable: true
                },
                {
                  field: 'aasm_state',
                  label: 'Статус',
                  sortable: true
                },
                {
                  field: 'created_at',
                  label: 'Дата',
                  sortable: true
                },
                {
                  field: 'card_number',
                  label: 'Карта'
                },
                {
                  field: 'product_price',
                  label: 'Стоимость',
                  sortable: true
                },
                {
                  field: 'device_id',
                  label: 'SN or IMEI'
                },
                {
                  field: 'receipt_number',
                  label: 'Документ',
                  sortable: true
                },
                {
                  field: 'fio',
                  label: 'Клиент'
                },
                {
                  field: 'updated_at',
                  label: 'Обновлено',
                  sortable: true
                }
              ]"
            />
            <tbody class="bg-white">
              <tr
                v-for="(ticket, idx) in tickets.data"
                :key="ticket.id"
                class="hover:bg-gray-50 focus-within:bg-gray-50 border-gray-200"
                :class="{ 'border-b': idx != tickets.data.length - 1 }"
              >
                <td
                  class="flex items-center px-6 py-4 whitespace-nowrap text-sm leading-5 text-gray-500 text-right"
                >
                  <InertiaLink
                    class="font-medium text-indigo-600 hover:text-indigo-900 focus:outline-none focus:underline"
                    :href="$routes.ticket(ticket.id)"
                    aria-label="Show"
                  >
                    {{ ticket.hashid || ticket.id }}
                  </InertiaLink>
                  <Icon
                    v-if="ticket.form == 'premium'"
                    name="badge"
                    class="flex-shrink-0 w-3 h-3 fill-gray-500 ml-2 mt-0.5"
                  />
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm leading-5 text-gray-500 text-center">
                  <span
                    class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium leading-4 bg-gray-100 text-gray-800"
                  >
                    {{ ticket.form }}
                  </span>
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm leading-5 text-gray-500 text-center">
                  <span
                    class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium leading-4 bg-gray-100 text-gray-800"
                  >
                    {{ ticket.aasm_state }}
                  </span>
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                  {{
                    new Date(ticket.created_at).toLocaleString("ru", { timeZone: "Europe/Moscow" })
                  }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm leading-5 text-gray-700">
                  {{ ticket.card_number }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm leading-5 text-gray-500 text-right">
                  {{ (ticket.product_price || 0).toLocaleString("ru") }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                  {{ ticket.sn || ticket.imei }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                  {{ ticket.receipt_number }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                  {{ ticket.fio }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                  {{
                    new Date(ticket.updated_at).toLocaleString("ru", { timeZone: "Europe/Moscow" })
                  }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <Pagination :meta="tickets.meta" />

    <!--     <div class="bg-white rounded shadow overflow-x-auto">
      <table class="w-full whitespace-nowrap">
        <thead>
          <tr class="text-left font-bold">
            <th class="px-4 pt-6 pb-4">

            </th>
            <th class="px-4 pt-6 pb-4">
              ID
            </th>
            <th class="px-4 pt-6 pb-4">
              Дата
            </th>
            <th class="px-4 pt-6 pb-4">
              Статус
            </th>
            <th class="px-4 pt-6 pb-4">
              Клиент
            </th>
            <th class="px-4 pt-6 pb-4">
              IMEI / SN
            </th>
            <th class="px-4 pt-6 pb-4">
              Устройство
            </th>

            <th
              class="px-4 pt-6 pb-4"
              colspan="2"
            >
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="ticket in tickets.data"
            :key="ticket.id"
            class="hover:bg-gray-100 focus-within:bg-gray-100"
          >
            <td class="border-t pl-4">
              <svg v-if="ticket.premium" width="20" height="20" class="fill-gray-500"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20">
    <g id="Page-1" stroke="none" stroke-width="1" fill-rule="evenodd">
      <g id="icon-shape">
        <path d="M10,12 C13.3137085,12 16,9.3137085 16,6 C16,2.6862915 13.3137085,0 10,0 C6.6862915,0 4,2.6862915 4,6 C4,9.3137085 6.6862915,12 10,12 Z M10,9 C11.6568542,9 13,7.65685425 13,6 C13,4.34314575 11.6568542,3 10,3 C8.34314575,3 7,4.34314575 7,6 C7,7.65685425 8.34314575,9 10,9 Z M14,11.7453107 L14,20 L10,16 L6,20 L6,11.7453107 C7.13383348,12.5361852 8.51275186,13 10,13 C11.4872481,13 12.8661665,12.5361852 14,11.7453107 L14,11.7453107 Z" id="Combined-Shape"></path>
      </g>
    </g>
  </svg>
            </td>
            <td class="border-t">
              <InertiaLink
                class="px-4 py-3 flex items-center focus:text-indigo-500"
                :href="$routes.edit_ticket(ticket.id)"
                aria-label="Edit"
              >
                {{ ticket.id }}
              </InertiaLink>
            </td>
             <td class="border-t text-right">
              <InertiaLink
                class="px-4 py-3 flex items-right"
                :href="$routes.edit_ticket(ticket.id)"
                aria-label="Edit"
                numberic
              >
                {{ ticket.created_at.substring(0,10) }}
              </InertiaLink>
            </td>
             <td class="border-t">
              <InertiaLink
                class="px-4 py-3 flex items-center"
                :href="$routes.edit_ticket(ticket.id)"
                tabindex="-1"
                aria-label="Edit"
              >
                {{ ticket.aasm_state }}
              </InertiaLink>
            </td>
            <td class="border-t">
              <InertiaLink
                class="px-4 py-3 flex items-center"
                :href="$routes.edit_ticket(ticket.id)"
                tabindex="-1"
                aria-label="Edit"
              >
                {{ ticket.fio }}
              </InertiaLink>
            </td>
            <td class="border-t">
              <InertiaLink
                class="px-4 py-3 flex items-center"
                :href="$routes.edit_ticket(ticket.id)"
                tabindex="-1"
                aria-label="Edit"
              >
                {{ ticket.imei || ticket.sn }}
              </InertiaLink>
            </td>
            <td class="border-t">
              <InertiaLink
                class="px-4 py-3 flex items-center"
                :href="$routes.edit_ticket(ticket.id)"
                tabindex="-1"
                aria-label="Edit"
              >
                {{ ticket.product }}
              </InertiaLink>
            </td>

            <td class="border-t w-px">
              <InertiaLink
                class="px-4 flex items-center"
                :href="$routes.edit_ticket(ticket.id)"
                tabindex="-1"
                aria-label="Edit"
              >
                <Icon
                  name="cheveron-right"
                  class="block w-6 h-6 fill-gray-500"
                />
              </InertiaLink>
            </td>
          </tr>
          <tr v-if="tickets.data.length === 0">
            <td
              class="border-t px-6 py-4"
              colspan="4"
            >
              Таких обращений нет.
            </td>
          </tr>
        </tbody>
      </table>
    </div> -->
  </div>
</template>

<script>
import Icon from "@/Shared/Icon.vue";
import Layout from "@/Layouts/Full.vue";
import Pagination from "@/Shared/NewPagination.vue";
import SearchFilter from "@/Shared/SearchFilter.vue";
import SelectInput from "@/Shared/SelectInput.vue";
import TableHeader from "@/Shared/TableHeader.vue";
import mapValues from "lodash/mapValues";
import pickBy from "lodash/pickBy";
import throttle from "lodash/throttle";

export default {
  layout: Layout,
  components: {
    Icon,
    Pagination,
    SearchFilter,
    SelectInput,
    TableHeader
  },
  props: {
    tickets: {
      type: Object,
      required: true
    },
    filters: {
      type: Object,
      required: true
    },
    statuses: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      form: {
        search: this.filters.search,
        form: this.filters.form,
        state: this.filters.state,
        sort_by: this.filters.sort_by
      }
    };
  },
  watch: {
    form: {
      handler: throttle(function() {
        let query = pickBy(this.form);
        this.$inertia.replace(
          this.$routes.tickets(Object.keys(query).length ? query : { remember: "forget" }),
          {
            preserveState: true,
            preserveScroll: true,
            only: ["tickets"]
          }
        );
      }, 150),
      deep: true
    }
  },
  methods: {
    reset() {
      this.form = mapValues(this.form, () => null);
    },
    inertiaVisit(pageNumber) {
      console.log(`Go to page ${pageNumber}`);
      const newUrl = this.tickets.meta.scaffold_url.replace(/__pagy_page__/, pageNumber);
      this.$inertia.visit(newUrl);
    }
  }
};
</script>
