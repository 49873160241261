<template>
  <div class="max-w-3xl">
    <div class="mb-8 flex flex-col justify-start">
      <h2 class="font-bold text-2xl">
        Отмена курьера для доставки устройства в офис.
      </h2>
    </div>
    <StyledForm
      :class="{ error: formErrors.base }"
      :errors="formErrors.base"
      :loading="form.processing"
      @submit="submit"
    >
      <template v-slot:formBody>
        <TextareaInput
          :errors="formErrors.reason"
          label="Причина отмены"
          v-model="form.request.reason"
        />
      </template>
    </StyledForm>
  </div>
</template>

<script type="text/javascript">
import TextareaInput from "@/Shared/TextareaInput.vue";
import StyledForm from "@/Shared/StyledForm.vue";

export default {
  components: { TextareaInput, StyledForm },
  props: ["ticketId"],
  emits: ["close"],
  data() {
    return {
      form: this.$inertia.form({
        request: {
          reason: '',
        }
      })
    };
  },
  computed: {
    formErrors() {
      return this.form?.errors || { };
    },
  },
  methods: {
    submit() {
      this.form.post(this.$routes.cancel_pickup_ticket(this.ticketId), {
        preserveState: true,
        preserveScroll: true,
        onSuccess: () => this.$emit("close"),
      });
    }
  },
  mounted() {
    this.$el.scrollIntoView({ behavior: "smooth" });
  },
};
</script>
