<template>
  <div class="card bg-white rounded-lg shadow-md pt-3">
    <div class="card-header px-6 py-3">
      <slot name="title" />
      <span
        v-if="diffByPersent || diffByPersent === 0"
        class="text-xs"
        :class="diffByPersent >= 0 ? 'text-green-600' : 'text-red-700'"
      >
        <span v-if="diffByPersent > 0">
          ↑
        </span>
        <span v-else-if="diffByPersent < 0">
          ↓
        </span>
        <span v-else-if="diffByPersent === 0">
          =
        </span>
        {{ diffByPersent }}%
      </span>
    </div>
    <div class="card-content pt-2">
      <div
        class="ml-6 mb-6"
        :class="{ 'opacity-0': !pointInfo.name || typeof pointInfo.value === 'undefined' }"
      >
        <span class="text-sm">{{ pointInfo.name }}:</span>
        <span class="text-lg font-bold">&nbsp;{{ pointInfo.value }}</span>
      </div>
      <SalesChart
        v-if="chartData"
        :data="chartData"
        :chartId="chartId"
        smooth
        fill
        interactive
        showPoints
        v-model:point="pointInfo"
      />
    </div>
  </div>
</template>

<script>
import SalesChart from "@/Shared/SalesChart.vue";
export default {
  components: { SalesChart },
  props: {
    chartData: Array,
    chartId: {
      type: String,
      default: "chart-id"
    },
    diffByPersent: Number
  },
  data() {
    return {
      pointInfo: {
        name: null,
        value: null
      }
    };
  }
};
</script>
