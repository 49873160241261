<template>
  <div>
    <h1 class="mb-8 font-bold text-3xl">
      Пользователи
    </h1>
    <div class="mb-6 flex justify-between items-center">
      <SearchFilter v-model="form.search" class="w-full max-w-md mr-4" @reset="reset">
        <SelectInput label="Роль" v-model="form.role">
          <option :value="undefined">Все</option>
          <option value="admin">Админ</option>
        </SelectInput>

        <SelectInput class="mt-4" label="Заблокированные" v-model="form.trashed">
          <option :value="undefined">Все</option>
          <option value="active">Активные</option>
          <option value="blocked">Заблокированные</option>
        </SelectInput>
      </SearchFilter>
      <InertiaLink v-if="$page.props.auth.user.admin" class="btn-indigo" :href="$routes.new_user()">
        <span>Добавить</span>
        <span class="hidden md:inline">пользователя</span>
      </InertiaLink>
    </div>
    <div class="bg-white rounded shadow overflow-x-auto">
      <table class="w-full whitespace-nowrap">
        <thead>
          <tr class="text-left font-bold">
            <th class="px-6 pt-6 pb-4">
              Имя
            </th>
            <th class="px-6 pt-6 pb-4">
              Email
            </th>
            <th class="px-6 pt-6 pb-4" colspan="2">
              Роль
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="user in users"
            :key="user.id"
            class="hover:bg-gray-100 focus-within:bg-gray-100"
          >
            <td class="border-t">
              <InertiaLink
                class="px-6 py-4 flex items-center focus:text-indigo-500"
                :href="$routes.edit_user(user.id)"
                aria-label="Edit"
              >
                <!-- <img
                  v-if="user.photo"
                  class="block w-5 h-5 rounded-full mr-2 -my-2"
                  :src="user.photo"
                  alt="Photo"
                > -->
                {{ user.name }}
                <Icon
                  v-if="user.deleted_at"
                  name="trash"
                  class="flex-shrink-0 w-3 h-3 fill-gray-500 ml-2"
                />
              </InertiaLink>
            </td>
            <td class="border-t">
              <InertiaLink
                class="px-6 py-4 flex items-center"
                :href="$routes.edit_user(user.id)"
                tabindex="-1"
                aria-label="Edit"
              >
                {{ user.email }}
              </InertiaLink>
            </td>
            <td class="border-t">
              <InertiaLink
                class="px-6 py-4 flex items-center"
                :href="$routes.edit_user(user.id)"
                tabindex="-1"
                aria-label="Edit"
              >
                {{ user.admin ? "Admin" : "User" }}
              </InertiaLink>
            </td>
            <td class="border-t w-px">
              <InertiaLink
                class="px-4 flex items-center"
                :href="$routes.edit_user(user.id)"
                tabindex="-1"
                aria-label="Edit"
              >
                <Icon name="cheveron-right" class="block w-6 h-6 fill-gray-500" />
              </InertiaLink>
            </td>
          </tr>
          <tr v-if="users.length === 0">
            <td class="border-t px-6 py-4" colspan="4">
              No users found.
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import Icon from "@/Shared/Icon.vue";
import Layout from "@/Layouts/Full.vue";
import SearchFilter from "@/Shared/SearchFilter.vue";
import SelectInput from "@/Shared/SelectInput.vue";
import mapValues from "lodash/mapValues";
import pickBy from "lodash/pickBy";
import throttle from "lodash/throttle";

export default {
  metaInfo: { title: "Пользователи" },
  layout: Layout,
  components: {
    Icon,
    SearchFilter,
    SelectInput
  },
  props: {
    users: {
      type: Array,
      required: true
    },
    filters: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      form: {
        search: this.filters.search,
        role: this.filters.role,
        trashed: this.filters.trashed
      }
    };
  },
  watch: {
    form: {
      handler: throttle(function() {
        let query = pickBy(this.form);
        this.$inertia.get(
          this.$routes.users(),
          Object.keys(query).length ? query : { remember: "forget" },
          {
            preserveState: true,
            preserveScroll: true,
            replace: true,
            only: ["users"]
          }
        );
      }, 150),
      deep: true
    }
  },
  methods: {
    reset() {
      this.form = mapValues(this.form, () => null);
    }
  }
};
</script>
