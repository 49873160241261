<template>
  <form @submit.prevent="$emit('submit')">
    <div class="p-8 -mr-6 -mb-8 flex flex-wrap">
      <TextInput
        v-model="form.ticket.fio"
        :errors="formErrors.fio"
        class="pr-6 pb-8 w-full lg"
        label="ФИО"
      />

      <TextInput
        v-model="form.ticket.phone1"
        :errors="formErrors.phone1"
        class="pr-6 pb-8 w-full lg:w-1/2"
        label="Телефон"
      />
      <TextInput
        v-model="form.ticket.email"
        :errors="formErrors.email"
        class="pr-6 pb-8 w-full lg:w-1/2"
        label="Email"
      />
      <template v-if="!form.ticket.card_id">
        <TextInput
          v-model="form.ticket.card_number"
          :errors="formErrors.card_number"
          class="pr-6 pb-8 w-full lg:w-1/2"
          label="Номер карты"
        />
        <TextInput
          v-model="form.ticket.card_sold_date"
          :errors="formErrors.card_sold_date"
          class="pr-6 pb-8 w-full lg:w-1/2"
          label="Дата продажи"
        />
        <TextInput
          v-model="form.ticket.receipt_number"
          :errors="formErrors.receipt_number"
          class="pr-6 pb-8 w-full lg:w-1/2"
          label="Номер чека (документа)"
        />
        <SelectInput
          v-model="form.ticket.brand_id"
          :errors="formErrors.brand_id"
          class="pr-6 pb-8 w-full lg:w-1/2"
          label="Brand"
          disabled
        >
          <option v-for="b in brands" :key="b.id" :value="b.id">
            {{ b.name }}
          </option>
        </SelectInput>
      </template>
      <hr />
      <TextareaInput
        v-model="form.ticket.issue"
        :errors="formErrors.issue"
        class="pr-6 pb-8 w-full"
        label="Причина обращения"
      />
    </div>
    <slot />
  </form>
</template>

<script>
import SelectInput from "@/Shared/SelectInput.vue";
import TextInput from "@/Shared/TextInput.vue";
import TextareaInput from "@/Shared/TextareaInput.vue";

export default {
  components: {
    SelectInput,
    TextInput,
    TextareaInput
  },
  props: {
    formData: {
      type: Object,
      required: true
    },
    brands: {
      type: Array,
      required: true
    }
  },
  emits: ["submit", "update:formData"],
  computed: {
    form: {
      get() {
        return this.formData;
      },
      set(val) {
        this.$emit("update:formData", val);
      }
    },
    formErrors() {
      return this.form?.errors || {};
    }
  }
};
</script>
