<template>
  <form @submit.prevent="$emit('submit')">
    <div class="p-8 -mr-6 -mb-8 flex flex-wrap">
      <FileInput
        v-model:file="form.excel_file"
        :errors="$page.errors"
        class="pr-6 pb-8 w-full"
        type="file"
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        label="Excel файл"
      />
    </div>
    <slot />
  </form>
</template>

<script>
import TextInput from "@/Shared/TextInput.vue";
import FileInput from "@/Shared/FileInput.vue";

export default {
  components: {
    TextInput,
    FileInput
  },
  props: {
    form: {
      type: Object,
      required: true
    }
  },
  emits: ["submit"]
};
</script>
