<template>
  <form class="bg-white rounded shadow overflow-hidden mb-8" @submit.prevent="$emit('submit')">
    <div class="p-6">
      <slot name="formBody" />
    </div>

    <div
      v-if="!hideButton"
      class="px-8 py-4 bg-gray-100 border-t border-gray-200 flex items-center"
    >
      <slot name="submitButton">
        <LoadingButton
          class="btn-indigo px-6 py-3 leading-4 self-start"
          :loading="loading"
          :disabled="disabled"
          type="submit"
        >
          <slot name="submitButtonText">
            Отправить
          </slot>
        </LoadingButton>
      </slot>

      <div class="form-base-error ml-6" v-if="errors">
        {{ errors[0] }}
      </div>
    </div>
  </form>
</template>

<script>
import LoadingButton from "@/Shared/LoadingButton.vue";

export default {
  components: { LoadingButton },
  props: {
    errors: Array,
    loading: Boolean,
    disabled: Boolean,
    hideButton: Boolean
  },
  emits: ["submit"]
};
</script>
