<template>
  <div>
    <div class="mb-8 flex justify-start max-w-3xl">
      <h1 class="font-bold text-3xl">
        <InertiaLink class="text-indigo-500 hover:text-indigo-600" :href="$routes.users()">
          Пользователи
        </InertiaLink>
        <span class="text-indigo-400 font-medium">/</span>
        {{ form.user.first_name }} {{ form.user.last_name }}
      </h1>
    </div>
    <TrashedMessage v-if="user.deleted_at" class="mb-6" @restore="restore">
      Этот пользователь был заблокирован.
    </TrashedMessage>
    <div class="bg-white rounded shadow overflow-hidden max-w-3xl">
      <UserForm v-model="form" @submit="submit">
        <div
          v-if="can.edit_user"
          class="px-8 py-4 bg-gray-100 border-t border-gray-200 flex items-center"
        >
          <button
            v-if="canBlock"
            class="text-red-800 hover:underline"
            tabindex="-1"
            type="button"
            @click="destroy"
          >
            Заблокировать
          </button>
          <LoadingButton class="btn-indigo ml-auto" :loading="form.processing" type="submit">
            Сохранить
          </LoadingButton>
        </div>
      </UserForm>
    </div>
  </div>
</template>

<script>
import Layout from "@/Layouts/Full.vue";
import LoadingButton from "@/Shared/LoadingButton.vue";
import UserForm from "@/Shared/UserForm.vue";
import TrashedMessage from "@/Shared/TrashedMessage.vue";

export default {
  metaInfo() {
    return {
      title: `${this.form.user.first_name} ${this.form.user.last_name}`
    };
  },
  layout: Layout,
  components: {
    LoadingButton,
    UserForm,
    TrashedMessage
  },
  props: {
    user: {
      type: Object,
      required: true
    },
    can: {
      type: Object,
      required: true
    }
  },
  remember: "form",
  data() {
    return {
      form: this.$inertia.form({ user: this.user })
    };
  },
  computed: {
    canBlock() {
      return !this.user.deleted_at && this.$page.props.auth.user.id !== this.user.id;
    }
  },
  methods: {
    submit() {
      this.form.put(this.$routes.user(this.user.id), {
        onSuccess: () => this.form.reset("password")
      });
    },
    destroy() {
      if (confirm("Вы уверены, что хотите заблокировать этого пользователя?")) {
        this.$inertia.delete(this.$routes.user(this.user.id));
      }
    },
    restore() {
      if (confirm("Вы уверены, что хотите восстановить этого пользователя?")) {
        this.$inertia.put(this.$routes.restore_user(this.user.id));
      }
    }
  }
};
</script>
