<template>
  <div class="max-w-4xl">
    <div class="mb-8 flex flex-col justify-start">
      <h2 class="font-bold text-2xl">
        Отказ в обслуживании
      </h2>
    </div>

    <StyledForm
      :class="{ error: formErrors.base }"
      :errors="formErrors.base"
      :loading="form.processing"
      @submit="submit"
    >
      <template v-slot:formBody>
        <RadioInput
          v-for="(reason, idx) in reasons"
          :key="idx"
          v-model="form.request.reason_type"
          name="reason"
          @input="() => form.clearErrors('base')"
          :native-value="reason.key"
          :errors="this.form?.errors"
        >
          {{ reason.label }}
        </RadioInput>
      </template>
    </StyledForm>
  </div>
</template>

<script type="text/javascript">
import StyledForm from "@/Shared/StyledForm.vue";
import RadioInput from "@/Shared/RadioInput.vue";

export default {
  components: { StyledForm, RadioInput },
  props: ["ticketId"],
  emits: ["close"],
  data() {
    return {
      form: this.$inertia.form({
        request: {
          reason_type: this.reason_type
        }
      }),
      reasons: [],
      reason_type: null
    };
  },
  computed: {
    formErrors() {
      return this.form?.errors || {};
    }
  },
  methods: {
    submit() {
      this.form.post(this.$routes.decline_ticket(this.ticketId), {
        preserveState: true,
        preserveScroll: true,
        onSuccess: () => this.$emit("close")
      });
    }
  },
  mounted() {
    this.$el.scrollIntoView({ behavior: "smooth" });
    fetch(this.$routes.decline_new_ticket(this.ticketId)).then(async res => {
      this.reasons = (await res.json()) || [];
    });
  }
};
</script>
