<template>
  <label>
    <div
      class="upload-draggable"
      v-if="dragDrop"
      @dragover.prevent="updateDragDropFocus(true)"
      @dragleave.prevent="updateDragDropFocus(false)"
      @dragenter.prevent="updateDragDropFocus(true)"
      @drop.prevent="onFileChange"
    >
      <slot />
    </div>

    <template v-else>
      <slot>
        <LoadingButton class="btn-indigo p-0 ml-auto" :loading="loading">
          <label class="block leading-4">
            <slot name="uploadButtonText">
              Upload
            </slot>

            <input
              class="hidden"
              ref="input"
              type="file"
              v-bind="$attrs"
              :multiple="multiple"
              :accept="accept"
              :disabled="disabled"
              @change="onFileChange"
            />
          </label>
        </LoadingButton>
      </slot>
    </template>
  </label>
</template>

<script>
import LoadingButton from "@/Shared/LoadingButton.vue";

export default {
  components: { LoadingButton },
  inheritAttrs: false,
  props: {
    file: [File, Array],
    multiple: Boolean,
    loading: Boolean,
    disabled: Boolean,
    accept: String,
    dragDrop: Boolean,
    native: {
      type: Boolean,
      default: false
    }
  },
  emits: ["update:loading", "update:file", "upload", "invalid"],
  data() {
    return {
      newValue: this.file,
      dragDropFocus: false,
      _elementRef: "input"
    };
  },
  watch: {
    /**
     *   When v-model is changed:
     *   1. Set internal value.
     *   2. Reset interna input file value
     *   3. If it's invalid, validate again.
     */
    file(value) {
      this.newValue = value;
      if (!value || (Array.isArray(value) && value.length === 0)) {
        this.$refs.input.value = null;
      }
      // !this.isValid && !this.dragDrop && this.checkHtml5Validity()
    }
  },
  methods: {
    async onFileChange(event) {
      if (this.disabled || this.loading) return;
      this.$emit("update:loading", true);
      if (this.dragDrop) this.updateDragDropFocus(false);

      const value = event.target.files || event.dataTransfer.files;
      if (value.length === 0) {
        if (!this.newValue) return;
        if (this.native) this.newValue = null;
      } else if (!this.multiple) {
        // only one element in case drag drop mode and isn't multiple
        if (this.dragDrop && value.length !== 1) return;
        else {
          const file = value[0];
          if (this.checkType(file)) {
            this.newValue = file;
          } else if (this.newValue) this.newValue = null;
          else return;
        }
      } else {
        // always new values if native or undefined local
        let newValues = false;
        if (this.native || !this.newValue) {
          this.newValue = [];
          newValues = true;
        }
        for (let i = 0; i < value.length; i++) {
          const file = value[i];
          if (this.checkType(file)) {
            this.newValue.push(file);
            newValues = true;
          }
        }
        if (!newValues) return;
      }
      this.$emit("update:file", this.newValue);
      this.$emit("upload", this.newValue);
      // !this.dragDrop && this.checkHtml5Validity()
    },

    /**
     * Listen drag-drop to update internal variable
     */
    updateDragDropFocus(focus) {
      if (!this.disabled && !this.loading) {
        this.dragDropFocus = focus;
      }
    },

    /**
     * Check mime type of file
     */
    checkType(file) {
      if (!this.accept) return true;

      const types = this.accept.split(",");
      if (types.length === 0) return true;

      let valid = false;
      types.some(t => {
        const type = t.trim();
        if (!type) return false;

        if (type.substring(0, 1) === ".") {
          // check extension
          const extIndex = file.name.lastIndexOf(".");
          const extension = extIndex >= 0 ? file.name.substring(extIndex) : "";
          valid = extension.toLowerCase() === type.toLowerCase();
        } else {
          // check mime type
          valid = !!file.type.match(type);
        }
        return valid;
      });

      if (!valid) this.$emit("invalid");
      return valid;
    }
  }
};
</script>
