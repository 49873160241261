<template>
  <div class="max-w-4xl">
    <div class="mb-8 flex flex-col justify-start">
      <h2 class="font-bold text-2xl">
        Направить клиента в сервис
      </h2>
    </div>

    <StyledForm
      :class="{ error: !!Object.keys(formErrors).length }"
      :errors="formErrors.base"
      :loading="form.processing"
      @submit="submit"
    >
      <template v-slot:formBody>
        <div v-if="!providers.length">Сервисные центры не найдены.</div>
        <ul v-else>
          <li v-for="(provider, idx) in providers" :key="idx">
            <div class="mt-5 font-medium text-gray-500">{{ provider }}</div>
            <ul>
              <li
                class="mt-2 text-gray-800"
                v-for="loc in providerLocations(provider)"
                :key="loc.id"
              >
                <CheckboxInput :native-value="loc.id" v-model="form.request.service_locations">
                  {{ loc.city }}, {{ loc.address }}
                </CheckboxInput>
              </li>
            </ul>
          </li>
        </ul>
      </template>
      <template v-if="!providers.length" v-slot:submitButton>
        <button class="btn-indigo px-6 py-3 leading-4" type="button" @click="$emit('close')">
          Закрыть
        </button>
      </template>
    </StyledForm>
  </div>
</template>

<script type="text/javascript">
import CheckboxInput from "@/Shared/CheckboxInput.vue";
import StyledForm from "@/Shared/StyledForm.vue";
import axios from "axios";

export default {
  components: { CheckboxInput, StyledForm },
  props: ["ticketId"],
  emits: ["close"],
  data() {
    return {
      locations: [],
      form: this.$inertia.form({
        request: {
          service_locations: []
        }
      })
    };
  },
  computed: {
    providers() {
      return this.locations?.length ? Array.from(new Set(this.locations.map(x => x.name))) : [];
    },
    formErrors() {
      return this.form?.errors || {};
    }
  },
  methods: {
    providerLocations(p) {
      return this.locations.filter(loc => loc.name === p);
    },
    submit() {
      console.log(this.form.request.service_locations);
      this.form.post(this.$routes.forward_to_service_ticket(this.ticketId), {
        preserveState: true,
        preserveScroll: true,
        onSuccess: () => this.$emit("close")
      });
    }
  },
  async beforeMount() {
    const { data } = await axios.get(`/tickets/${this.ticketId}/service_locations.json`);
    this.locations = data.service_locations;
    this.form.request.service_locations = data.service_locations.map(x => x.id);
  },
  mounted() {
    this.$el.scrollIntoView({ behavior: "smooth" });
  }
};
</script>
