<template>
  <div :class="$attrs.class">
    <label class="form-label">
      <span v-if="label">{{ label }}:</span>
      <select
        ref="input"
        v-model="selected"
        v-bind="{ ...$attrs, class: null }"
        class="form-select"
        :class="{ error: errors.length, 'mt-2': label }"
        @input="input"
      >
        <slot />
      </select>
    </label>
    <div v-if="errors.length" class="form-error">
      {{ errors[0] }}
    </div>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    modelValue: [String, Number, Boolean],
    label: String,
    errors: {
      type: Array,
      default: () => []
    }
  },
  emits: ["update:modelValue", "input"],
  data() {
    return {
      selected: this.modelValue
    };
  },
  watch: {
    selected(selected) {
      this.$emit("update:modelValue", selected);
    }
  },
  methods: {
    focus() {
      this.$refs.input.focus();
    },
    select() {
      this.$refs.input.select();
    },
    input(event) {
      this.$emit("input", event);
    }
  }
};
</script>
