<template>
  <div>
    <h1 class="mb-8 font-bold text-3xl">
      Контракты
    </h1>
    <div class="mb-6 flex justify-between items-center">
      <SearchFilter v-model="form.search" class="w-full max-w-md mr-4" @reset="reset">
        <SelectInput label="Статус" v-model="form.state">
          <option :value="null">Любой</option>
          <option value="active">Действует</option>
          <option value="terminated">Завершен</option>
          <option value="revoked">Отменен</option>
        </SelectInput>

        <SelectInput class="mt-4" label="Программа" v-model="form.program">
          <option :value="null">Любая</option>
          <option value="basic">Basic</option>
          <option value="leasing">Leasing</option>
          <option value="premier">Premier</option>
          <option value="premium">Premium</option>
          <option value="special">Special</option>
          <option value="poscredit">РиК</option>
        </SelectInput>

        <SelectInput class="mt-4" label="Бренд" v-model="form.brand">
          <option :value="null">Любой</option>
          <option v-for="brand in brands" :key="brand.id" :value="brand.id">{{
            brand.name
          }}</option>
        </SelectInput>
      </SearchFilter>
      <InertiaLink
        v-if="$page.props.auth.user.id === 1"
        class="btn-indigo"
        :href="$routes.new_contract()"
      >
        <span>Загрузить</span>
        <span class="hidden md:inline">&nbsp;контракты</span>
      </InertiaLink>
    </div>

    <div class="flex flex-col">
      <div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
        <div
          class="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200"
        >
          <table class="table min-w-full">
            <TableHeader
              :columns="[
                {
                  field: 'id',
                  label: '#',
                  sortable: true,
                },
                {
                  field: 'program',
                  label: 'Тип',
                  sortable: true,
                },
                {
                  field: 'extended',
                  label: 'НПГ',
                },
                {
                  field: 'sold_at',
                  label: 'Дата',
                  sortable: true,
                },
                {
                  field: 'number',
                  label: 'Карта',
                  sortable: true,
                },
                {
                  field: 'price',
                  label: 'Стоимость',
                  sortable: true,
                },
                {
                  field: 'discount',
                  label: 'Скид %',
                  sortable: true,
                },
                {
                  field: 'device_id',
                  label: 'SN or IMEI',
                },
                {
                  field: 'document',
                  label: 'Документ',
                  sortable: true,
                },
              ]"
            />
            <tbody class="bg-white">
              <tr
                v-for="(card, idx) in contracts.data"
                :key="card.id"
                class="hover:bg-gray-50 focus-within:bg-gray-50 border-gray-200"
                :class="{ 'border-b': idx != contracts.data.length - 1 }"
              >
                <td class="text-right">
                  <InertiaLink
                    class="font-medium text-indigo-600 hover:text-indigo-900 focus:outline-none focus:underline"
                    :href="$routes.contract(card.id)"
                    aria-label="Show"
                  >
                    {{ card.id }}
                  </InertiaLink>
                  <InertiaLink
                    v-if="card.contract_id"
                    class="font-medium text-indigo-600 hover:text-indigo-900 focus:outline-none focus:underline pl-2"
                    :href="$routes.contract(card.contract_id)"
                    aria-label="Show"
                  >
                  </InertiaLink>
                </td>

                <td>
                  <div class="flex items-center">
                    <Icon
                      name="badge"
                      class="mr-1 flex-shrink-0 w-4 h-4"
                      :class="[active(card.valid_before) ? 'fill-green-500' : 'fill-gray-500']"
                    />
                    <span v-if="card.program">
                      {{ card.program[0].toUpperCase() + card.program.slice(1) }}
                    </span>
                  </div>
                </td>

                <td>
                  <div v-if="card.extended_before" class="flex items-center">
                    <Icon
                      name="clock"
                      class="mr-1 flex-shrink-0 w-4 h-4"
                      :class="[
                        extended(card.valid_before, card.extended_before)
                          ? 'fill-green-500'
                          : 'fill-gray-500',
                      ]"
                    />
                    <span>
                      {{
                        new Date(card.extended_before).getFullYear() -
                          new Date(card.valid_before).getFullYear()
                      }}
                      года
                    </span>
                  </div>
                </td>

                <!-- <td class="text-center">
                  <span
                    v-if="card.revocation_id"
                    class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium leading-4 bg-red-100 text-red-800"
                  >
                    Отменен
                  </span>
                  <span
                    v-else-if="new Date(card.valid_before) < new Date()"
                    class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium leading-4 bg-gray-100 text-gray-800"
                  >
                    Завершен
                  </span>
                  <span
                    v-else
                    class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium leading-4 bg-green-100 text-green-800"
                  >
                    Действует
                  </span>
                </td> -->

                <td>
                  {{ new Date(card.sold_at).toLocaleString("ru", { timeZone: "Europe/Moscow" }) }}
                </td>

                <td class="text-gray-700">
                  {{ card.number }}
                </td>

                <td class="text-right">
                  {{ (card.price || 0).toLocaleString("ru") }}
                </td>

                <td class="text-right">
                  <span
                    :class="{
                      'text-red-500 underline decoration-wavy': card.discount > 10.0,
                    }"
                  >
                    {{ card.discount.toLocaleString("ru") }}
                  </span>
                </td>

                <td>
                  {{ card.device_sn || card.device_imei }}
                </td>

                <td>
                  {{ card.document }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <pagination :meta="contracts.meta" />
  </div>
</template>

<script>
import Icon from "@/Shared/Icon.vue";
import Layout from "@/Layouts/Full.vue";
import mapValues from "lodash/mapValues";
import Pagination from "@/Shared/NewPagination.vue";
import pickBy from "lodash/pickBy";
import SearchFilter from "@/Shared/SearchFilter.vue";
import SelectInput from "@/Shared/SelectInput.vue";
import throttle from "lodash/throttle";
import TableHeader from "@/Shared/TableHeader.vue";

export default {
  metaInfo: { title: "Контракты" },
  layout: Layout,
  components: {
    Icon,
    Pagination,
    SearchFilter,
    SelectInput,
    TableHeader,
  },
  props: {
    brands: {
      type: Array,
      required: true,
    },
    contracts: {
      type: Object,
      required: true,
    },
    filters: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      form: {
        search: this.filters.search,
        state: this.filters.state || null,
        brand: this.filters.brand || null,
        program: this.filters.program || null,
        sort_by: this.filters.sort_by,
      },
    };
  },
  computed: {
    active() {
      return (valid_before) => new Date(valid_before) > new Date();
    },
    extended() {
      return (valid_before, extended_before) =>
        this.active(valid_before) ? false : new Date(extended_before) > new Date();
    },
  },
  watch: {
    form: {
      handler: throttle(function() {
        let query = pickBy(this.form);
        this.$inertia.replace(
          this.$routes.contracts(Object.keys(query).length ? query : { remember: "forget" }),
          {
            preserveState: true,
            preserveScroll: true,
            only: ["contracts"],
          }
        );
      }, 150),
      deep: true,
    },
  },
  methods: {
    reset() {
      this.form = mapValues(this.form, () => null);
    },
  },
};
</script>

<style scoped>
.table td {
  @apply px-6 py-4 whitespace-nowrap text-sm leading-5 text-gray-500;
}
</style>
