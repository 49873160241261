<template>
  <div :class="$attrs.class">
    <label class="form-label">
      <span v-if="label">{{ label }}:</span>
      <input
        ref="input"
        v-bind="{ ...$attrs, class: null }"
        class="form-input"
        :class="{ error: errors.length, 'mt-2': label }"
        :type="type"
        :value="modelValue"
        @input="input"
      />
    </label>
    <div v-if="errors.length" class="form-error">
      {{ errors[0] }}
    </div>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    type: {
      type: String,
      default: "text"
    },
    modelValue: String,
    label: String,
    errors: {
      type: Array,
      default: () => []
    }
  },
  emits: ["update:modelValue", "input"],
  methods: {
    focus() {
      this.$refs.input.focus();
    },
    select() {
      this.$refs.input.select();
    },
    setSelectionRange(start, end) {
      this.$refs.input.setSelectionRange(start, end);
    },
    input(event) {
      this.$emit("update:modelValue", event.target.value);
      this.$emit("input", event);
    }
  }
};
</script>
