<template>
  <div class="mb-8">
    <div class="mb-8 flex justify-start">
      <h2 class="font-bold text-2xl">
        Подходящие контракты
      </h2>
    </div>

    <div v-if="contracts.length === 0" class="bg-white rounded shadow overflow-hidden mb-8 p-4">
      <p class="mb-2 font-medium">Подходящих договоров не найдено.</p>
      <p class="text-gray-500">
        Проверьте дату продажи. Загрузите данные о продажах на это число.
      </p>
    </div>

    <div v-else>
      <div v-for="contract in contracts" :key="contract.id" class="mb-6">
        <Contract :contract="contract" :ticket="ticket">
          <div class="px-4 py-4 sm:px-6 bg-gray-100 border-t border-gray-200 flex items-start">
            <LoadingButton
              class="btn-indigo px-6 py-3 leading-4 ml-auto mr-3"
              :loading="form.processing"
              @click="assignContract(contract.id)"
            >
              Привязать
            </LoadingButton>
          </div>
        </Contract>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
import LoadingButton from "@/Shared/LoadingButton.vue";
import Contract from "@/Shared/Contract.vue";
import axios from "axios";

export default {
  components: { LoadingButton, Contract },
  props: ["ticket"],
  emits: ["close"],
  data() {
    return {
      contracts: [],
      form: this.$inertia.form({
        request: {
          contract_id: ""
        }
      })
    };
  },
  methods: {
    assignContract(contractId) {
      this.form.request.contract_id = contractId;
      this.form.post(this.$routes.assign_contract_ticket(this.ticket.id), {
        preserveState: true,
        preserveScroll: true,
        onSuccess: () => this.$emit("close")
      });
    }
  },
  async beforeMount() {
    const { data } = await axios.get(`/tickets/${this.ticket.id}/contracts`);
    this.contracts = data;
  },
  mounted() {
    this.$el.scrollIntoView({ behavior: "smooth" });
  }
};
</script>
